import { FC, useMemo } from 'react'
import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import { useParams } from 'react-router-dom'
import { Box, Typography, Link, useTheme } from '@mui/material'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { useBoardsList, useBoardsListPosts } from 'services/boards/boards.hooks'
import BoardsPostActions from './BoardsPostActions'
import { useIntl } from 'react-intl'

const BoardsPost: FC = () => {
  const { formatMessage } = useIntl()
  const { boardId, id } = useParams()
  const theme = useTheme()
  const { data = [] } = useBoardsList()
  const { boardsListPosts } = useBoardsListPosts(data.map(({ id }) => id))

  const post = useMemo(
    () =>
      boardsListPosts.find((post) => post.id === Number(id) && post.board.id === Number(boardId)),
    [boardId, id, boardsListPosts]
  )

  if (post) {
    const coverImage = post.multimedia[0]
    return (
      <>
        <BackNavigationHeader
          navigateTo='/board'
          desktopNavigateTo='/'
          label={
            post.title ??
            formatMessage(
              {
                id: 'boardsPost.event',
                defaultMessage: 'Wydarzenie {id}'
              },
              {
                id
              }
            )
          }
          sx={{
            backgroundColor: 'rgba(29, 34, 110, 0.7)',
            color: '#fff',
            '.MuiIconButton-root': {
              color: '#fff'
            },
            zIndex: 1000
          }}
        />
        <BackNavigationContent sx={{ position: 'relative' }}>
          <Box width='100%' px={2} mb={1}>
            {!!coverImage && (
              <Box
                component='img'
                src={coverImage.multimedia}
                alt=''
                sx={{
                  aspectRatio: '1 / 1',
                  width: 'calc(100% + 64px)',
                  objectFit: 'cover',
                  mx: '-32px',
                  mt: '-70px',
                  [theme.breakpoints.up('md')]: {
                    aspectRatio: '16 / 9',
                    mt: 0
                  }
                }}
              />
            )}
          </Box>
          <Typography
            component='div'
            mt={2}
            pt={8}
            dangerouslySetInnerHTML={{ __html: post.text }}
          />
          <Box my={2}>
            {post.multimedia.map((file: any) => (
              <Link
                key={file.id}
                href={file.multimedia}
                underline='none'
                display='flex'
                alignItems='center'
                target='_blank'>
                <AttachFileIcon sx={{ mr: 2 }} />
                <Typography sx={{ wordBreak: 'break-all' }} variant='body2'>
                  {file.multimedia.split('?')[0].substring(file.multimedia.lastIndexOf('/') + 1)}
                </Typography>
              </Link>
            ))}
          </Box>
          <BoardsPostActions />
        </BackNavigationContent>
      </>
    )
  }
  return null
}

export default BoardsPost
