import { FC, ReactNode } from 'react'
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Avatar,
  Badge,
  Tooltip
} from '@mui/material'
import { ArrowBackIosNew, SupervisorAccount } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useUsersProfile } from 'services/profile/profile.hooks'
import { NotificationIndicator } from 'components/Notifications'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'

interface BackNavigationHeaderProps {
  label: string | ReactNode
  onBack?: () => void
  desktopNavigateTo?: string
  additionalAction?: ReactNode
  isMainRoute?: boolean
  backTo?: any
}

const DesktopNavigation: FC<BackNavigationHeaderProps> = ({
  label,
  onBack,
  desktopNavigateTo,
  isMainRoute,
  backTo = -1
}) => {
  const { isMasquarade } = useMasquaradeToken()
  const { userInitials } = useUsersProfile()
  const navigate = useNavigate()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const onBackClick = () => {
    if (!!onBack) {
      return onBack()
    }

    if (isDesktop && !!desktopNavigateTo) {
      return navigate(desktopNavigateTo)
    }

    return navigate(backTo)
  }

  if (isDesktop) {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 'calc(90px + env(safe-area-inset-top))',
            position: 'fixed',
            top: 0,
            left: 0,
            px: 2,
            paddingLeft: '120px',
            paddingTop: 'env(safe-area-inset-top)',
            backgroundColor: theme.palette.background.default,
            zIndex: 100,
            width: '100%'
          }}
        >
          {!isMainRoute && (
            <IconButton onClick={onBackClick}>
              <ArrowBackIosNew />
            </IconButton>
          )}
          <Typography sx={{ mx: 1, flex: 1, fontWeight: 500 }}>{label}</Typography>
          <Box>
            <IconButton onClick={() => navigate('/user')} sx={{ mr: 2 }}>
              {isMasquarade ? (
                <Badge
                  overlap='circular'
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    <Tooltip title='Maskarada'>
                      <Avatar
                        sx={{
                          width: 20,
                          height: 20,
                          bgcolor: 'primary.main'
                        }}
                      >
                        <SupervisorAccount sx={{ width: 15, height: 15 }} />
                      </Avatar>
                    </Tooltip>
                  }
                >
                  <Avatar>{userInitials}</Avatar>
                </Badge>
              ) : (
                <Avatar>{userInitials}</Avatar>
              )}
            </IconButton>
            <NotificationIndicator />
          </Box>
        </Box>
      </>
    )
  }

  return null
}

export default DesktopNavigation
