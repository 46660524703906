import { FC } from 'react'
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
// import { useIntl } from 'react-intl'

const ProtocolKeys: FC<{
  keys: {
    id: number
    keyType: string
    quantity: number
  }[]
}> = ({ keys }) => {
  // const { formatMessage } = useIntl()

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>Nazwa</TableCell>
            <TableCell sx={{ fontWeight: 700 }} align='right'>
              Ilość
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody></TableBody>
        {keys.map((key) => (
          <TableRow key={key.id}>
            <TableCell component='th' scope='row'>
              {key.keyType}
            </TableCell>
            <TableCell align='right'>{key.quantity}</TableCell>
          </TableRow>
        ))}
      </Table>
    </TableContainer>
  )
}

export default ProtocolKeys
