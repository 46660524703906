import axios, {
  AxiosRequestTransformer,
  AxiosResponseTransformer,
  ParamsSerializerOptions
} from 'axios'
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import queryString from 'query-string'
import { env } from 'consts'

const apiInstance = axios.create({
  baseURL: env.API_BASE_URL,
  transformRequest: [
    function (data) {
      return !!data ? snakecaseKeys(data, { deep: true }) : data
    },
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[])
  ],
  transformResponse: [
    ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
    function (data) {
      return !!data ? camelcaseKeys(data, { deep: true }) : data
    }
  ],
  paramsSerializer: {
    ...(axios.defaults.paramsSerializer as ParamsSerializerOptions),
    serialize: (param) => {
      return queryString.stringify(snakecaseKeys(param, { deep: true }))
    }
  },
  
})

export default apiInstance
