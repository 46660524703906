import { ArrowForward } from '@mui/icons-material'
import { Box, BoxProps, IconButton, Typography } from '@mui/material'
import { FC } from 'react'

export const OfferButton: FC<BoxProps> = ({ children, sx, onClick, ...props }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
        color: '#527EF7',
        width: '100%',
        ...sx
      }}
      onClick={onClick as any}
      {...props}>
      <Typography variant='body2' fontWeight={700} fontSize={15} textTransform='uppercase'>
        {children}
      </Typography>
      <IconButton size='small'>
        <ArrowForward color='primary' />
      </IconButton>
    </Box>
  )
}
