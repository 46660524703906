import { FC, useEffect } from 'react'
import { List, ListItem, ListItemButton, ListItemText, Switch, Box } from '@mui/material'

import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import { useUserConsents } from 'services/profile/profile.hooks'
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'

const UserDetailsConsents: FC = () => {
  const { formatMessage } = useIntl()
  const {
    usersProfileMarketing = {
      marketingConsent: false,
      newsletterConsent: false
    },
    usersProfileMarketingUpdate
  } = useUserConsents()

  const userConsentsForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      marketingConsent: usersProfileMarketing.marketingConsent,
      newsletterConsent: usersProfileMarketing.newsletterConsent
    },
    onSubmit: (values) => {
      usersProfileMarketingUpdate.mutate(values)
    }
  })

  useEffect(() => {
    if (userConsentsForm.dirty) {
      userConsentsForm.submitForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userConsentsForm.dirty])

  return (
    <>
      <BackNavigationHeader
        label={formatMessage({
          id: 'userDetailsConsents.label',
          defaultMessage: 'Zgody i powiadomienia'
        })}
      />
      <BackNavigationContent>
        <Box pt='env(safe-area-inset-top)'>
          <List>
            <ListItem
              onClick={() => {
                userConsentsForm.setFieldValue(
                  'newsletterConsent',
                  !userConsentsForm.values.newsletterConsent
                )
              }}
              disablePadding
              sx={{
                borderBottom: '1px solid #CDCDCD',
                borderTop: '1px solid #CDCDCD'
              }}
              secondaryAction={<Switch checked={userConsentsForm.values.newsletterConsent} />}
            >
              <ListItemButton disableRipple sx={{ py: 2 }}>
                <ListItemText
                  primary={formatMessage({
                    id: 'userDetailsConsents.newsletterConsent',
                    defaultMessage: 'Notyfikacje z tablicy'
                  })}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              onClick={() => {
                userConsentsForm.setFieldValue(
                  'marketingConsent',
                  !userConsentsForm.values.marketingConsent
                )
              }}
              disablePadding
              sx={{ borderBottom: '1px solid #CDCDCD' }}
              secondaryAction={<Switch checked={userConsentsForm.values.marketingConsent} />}
            >
              <ListItemButton disableRipple sx={{ py: 2 }}>
                <ListItemText
                  primary={formatMessage({
                    id: 'userDetailsConsents.marketingConsent',
                    defaultMessage: 'Zgody marketingowe'
                  })}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </BackNavigationContent>
    </>
  )
}

export default UserDetailsConsents
