import { FC, useState, useMemo } from 'react'
import { Tabs, Tab, Box, Typography } from '@mui/material'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'

import { useActiveLeaseContractId } from 'services/contracts/hooks/useActiveLeaseContractId'
import { useSchedulesWithDocumnets } from 'services/contracts/hooks/useSchedulesWithDocumnets'

import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import AdditionalServicesPaymentStatus from './AdditionalServicesPaymentStatus'
import PaymentScheduleDocumnet from './PaymentScheduleDocumnet'
import { ReceiptOutlined } from '@mui/icons-material'
import InfoIndicator from 'components/InfoIndicator'
import { useIntl } from 'react-intl'
import { useLeaseContractsList } from 'services/contracts/hooks/useLeaseContracts'

dayjs.extend(isBetween)

const AdditionalServicesPaymentSchedule: FC = () => {
  const { formatMessage } = useIntl()
  const [isPaid, setIsPaid] = useState<boolean>(false)
  const { activeLeaseContractId } = useActiveLeaseContractId()

  const { schedulesWithDocumnets, isLoading } = useSchedulesWithDocumnets({
    activeLeaseContractId,
    isPaid
  })

  const { getActiveContract } = useLeaseContractsList()

  const activeContract = useMemo(
    () => getActiveContract(activeLeaseContractId),
    [activeLeaseContractId, getActiveContract]
  )

  return (
    <>
      <BackNavigationHeader
        label={formatMessage({
          id: 'additionalServicesPaymentSchedule.schedule',
          defaultMessage: 'Harmonogram płatności'
        })}
      />
      <BackNavigationContent>
        <Box pt='env(safe-area-inset-top)'>
          <AdditionalServicesPaymentStatus />
        </Box>
        <Tabs
          value={isPaid}
          onChange={(_, newValue) => setIsPaid(newValue)}
          centered
          sx={{ mb: 3 }}>
          <Tab
            label={formatMessage({
              id: 'additionalServicesPaymentSchedule.toPay',
              defaultMessage: 'Do zapłaty'
            })}
            value={false}
          />
          <Tab
            label={formatMessage({
              id: 'additionalServicesPaymentSchedule.paid',
              defaultMessage: 'Opłacone'
            })}
            value={true}
          />
        </Tabs>

        <InfoIndicator
          helperText={formatMessage({
            id: 'additionalServicesPaymentSchedule.noDocuments',
            defaultMessage: 'Brak dokumentów.'
          })}
          Icon={ReceiptOutlined}
          isVisible={!Object.keys(schedulesWithDocumnets).length && !isLoading}
        />
        {Object.keys(schedulesWithDocumnets).map((key) => (
          <Box sx={{ mb: 3 }} key={key}>
            <Typography textTransform='capitalize' variant='body2' fontWeight={500} mb={2}>
              {key}
            </Typography>
            {schedulesWithDocumnets[key].map((document) => (
              <PaymentScheduleDocumnet
                key={document.id}
                data={document}
                documentId={document.id}
                type={document.type}
                amount={document.amount || document.finalGrossAmount || document.totalAmountGross}
                number={document.number}
                file={document.file}
                items={document.items || document.positions}
                currency={activeContract?.paymentCurrency}
                isPaid={document.isPaid || isPaid}
                paidAmount={document.paidAmount || document.paymentAmount}
              />
            ))}
          </Box>
        ))}
      </BackNavigationContent>
    </>
  )
}

export default AdditionalServicesPaymentSchedule
