import { FC } from 'react'
import { List, ListItem, ListItemText } from '@mui/material'
import { useParams } from 'react-router-dom'

import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import { useLeaseContractsTenants } from 'services/contracts/hooks/useLeaseContractsTenants'
import { PeopleAltOutlined } from '@mui/icons-material'
import InfoIndicator from 'components/InfoIndicator'
import { useIntl } from 'react-intl'

const AdditionalServicesTenants: FC = () => {
  const { formatMessage } = useIntl()
  const { id } = useParams()
  const { leaseContractsTenants = [], isFetching, isLoading } = useLeaseContractsTenants(Number(id))

  return (
    <>
      <BackNavigationHeader
        label={formatMessage({
          id: 'additionalServicesTenants.tenants',
          defaultMessage: 'Lokatorzy'
        })}
      />
      <BackNavigationContent>
        <InfoIndicator
          helperText={formatMessage({
            id: 'additionalServicesTenants.noTenants',
            defaultMessage: 'Brak lokatorów.'
          })}
          Icon={PeopleAltOutlined}
          isVisible={!leaseContractsTenants.length && !isFetching && !isLoading}
        />
        <List>
          {leaseContractsTenants.map(({ id, firstName, lastName }) => (
            <ListItem
              disablePadding
              sx={{
                borderBottom: '1px solid #CDCDCD',
                borderTop: '1px solid #CDCDCD'
              }}
            >
              <ListItemText
                key={`${id}_${lastName}`}
                sx={{ py: 2 }}
                primary={`${firstName} ${lastName}`}
              />
            </ListItem>
          ))}
        </List>
      </BackNavigationContent>
    </>
  )
}

export default AdditionalServicesTenants
