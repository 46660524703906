import { FC, useEffect } from 'react'
import { useFormik } from 'formik'
import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import { Box, TextField, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import {
  useBoardsList,
  useBoardsListPosts,
  useBoardsPostsCreate
} from 'services/boards/boards.hooks'
import MobileBottomFooter from 'components/MobileBottomFooter'
import { MobileDatePicker } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import { useIntl } from 'react-intl'
import InfoIndicator from 'components/InfoIndicator'
import { EventAvailableOutlined, EventBusyOutlined } from '@mui/icons-material'

const BoardsNewPost: FC = () => {
  const { data = [] } = useBoardsList()
  const { refetch } = useBoardsListPosts(data.map(({ id }) => id))
  const { createPost, isSuccess, isError, reset } = useBoardsPostsCreate()
  const { formatMessage } = useIntl()

  const formik = useFormik<{
    boardId: string
    title: string
    text: ''
    eventDate: Dayjs
  }>({
    initialValues: {
      boardId: '',
      title: '',
      text: '',
      eventDate: dayjs()
    },
    onSubmit: async ({ boardId, text, title, eventDate }, { setSubmitting }) => {
      setSubmitting(true)
      await createPost({
        boardId: Number(boardId),
        text,
        title,
        eventDate: eventDate.toISOString()
      })
      refetch()
      setSubmitting(false)
    }
  })

  const resetForm = () => {
    reset()
    formik.resetForm()
  }

  useEffect(() => {
    if (!!data.length) {
      formik.setFieldValue('boardId', `${data[0].id}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isError) {
    return (
      <>
        <BackNavigationHeader
          label={formatMessage({
            id: 'boardsNewPost.addToBoard',
            defaultMessage: 'Dodaj do tablicy'
          })}
        />
        <Box
          px={2}
          mt={1}
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
        >
          <InfoIndicator
            helperText={formatMessage({
              id: 'boardsNewPost.error',
              defaultMessage: 'Nie można dodać wydarzenia spróbuj ponownie później.'
            })}
            Icon={EventBusyOutlined}
            isVisible
          />
          <Button sx={{ mt: 2 }} variant='contained' onClick={resetForm}>
            {formatMessage({
              id: 'boardsNewPost.errorBtn',
              defaultMessage: 'Wróć'
            })}
          </Button>
        </Box>
      </>
    )
  }

  if (isSuccess) {
    return (
      <>
        <BackNavigationHeader
          label={formatMessage({
            id: 'boardsNewPost.addToBoard',
            defaultMessage: 'Dodaj do tablicy'
          })}
        />
        <Box
          px={2}
          mt={1}
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
        >
          <InfoIndicator
            helperText={formatMessage({
              id: 'boardsNewPost.succes',
              defaultMessage:
                'Wydarzenie zostało wysłane i oczekuję na akceptację przez moderatora.'
            })}
            Icon={EventAvailableOutlined}
            isVisible
          />
          <Button sx={{ mt: 2 }} variant='contained' onClick={resetForm}>
            {formatMessage({
              id: 'boardsNewPost.succesBtn',
              defaultMessage: 'Dodaj nowe'
            })}
          </Button>
        </Box>
      </>
    )
  }

  return (
    <>
      <BackNavigationHeader
        label={formatMessage({
          id: 'boardsNewPost.addToBoard',
          defaultMessage: 'Dodaj do tablicy'
        })}
      />
      <BackNavigationContent>
        <Box px={2} mt={1}>
          <TextField
            name='title'
            onChange={formik.handleChange}
            value={formik.values.title}
            label={formatMessage({
              id: 'boardsNewPost.form.title',
              defaultMessage: 'Tytuł'
            })}
            sx={{ mb: 4 }}
            fullWidth
            rows={2}
            multiline
            disabled={formik.isSubmitting}
          />
          <TextField
            name='text'
            onChange={formik.handleChange}
            value={formik.values.text}
            label={formatMessage({
              id: 'boardsNewPost.form.text',
              defaultMessage: 'Opis'
            })}
            sx={{ mb: 4 }}
            fullWidth
            rows={5}
            multiline
            disabled={formik.isSubmitting}
          />
          <FormControl fullWidth sx={{ mb: 4 }}>
            <InputLabel>
              {formatMessage({
                id: 'boardsNewPost.board',
                defaultMessage: 'Tablica'
              })}
            </InputLabel>
            <Select
              disabled={data.length <= 1}
              value={formik.values.boardId}
              label={formatMessage({
                id: 'boardsNewPost.form.boardId',
                defaultMessage: 'Tablica'
              })}
              onChange={(event) => formik.setFieldValue('boardId', event.target.value)}
            >
              {data.map(({ id, name, investment }) => (
                <MenuItem key={id} value={id.toString()}>
                  {name} - {investment.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <MobileDatePicker
            sx={{ mb: 3 }}
            disablePast
            disableHighlightToday
            value={formik.values.eventDate}
            onChange={(value) => {
              formik.setFieldValue('eventDate', value)
            }}
            slotProps={{
              textField: {
                fullWidth: true
              }
            }}
            label={formatMessage({
              id: 'boardsNewPost.form.eventDate',
              defaultMessage: 'Data wydarzenia'
            })}
          />
        </Box>
        <MobileBottomFooter>
          <LoadingButton
            onClick={formik.submitForm}
            color='primary'
            fullWidth
            variant='contained'
            loading={formik.isSubmitting}
          >
            {formatMessage({
              id: 'boardsNewPost.form.submit',
              defaultMessage: 'WYŚLIJ'
            })}
          </LoadingButton>
        </MobileBottomFooter>
      </BackNavigationContent>
    </>
  )
}

export default BoardsNewPost
