import {
  TextField,
  Autocomplete,
  Button,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  useMediaQuery
} from '@mui/material'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { useFormik } from 'formik'
import { FC, useEffect, useState } from 'react'
import { removeEmptyProperties } from 'utils'
import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import dayjs from 'dayjs'
import countries from 'consts/countries'
import { useIntl } from 'react-intl'
import {
  personalDetailsConfirmationSchemaStepOne,
  personalDetailsConfirmationSchemaStepTwo
} from './personalDetailsConfirmationSchema'
import { IUserDetailsConfirmation } from './types'
import { useCountriesList } from 'services/metadata/metadata.hooks'
import theme from 'styles/theme'
import {
  useUsersProfilAddressOfResidence,
  useUsersProfilBillingAddress,
  useUsersProfilDeliverAddressAfter,
  useUsersProfilDeliverAddressDuring,
  useUsersProfile
} from 'services/profile/profile.hooks'
import { useUserBankAccount } from 'services/profile/hooks/useUserBankAccount'

type AddressField =
  | 'billingAddress'
  | 'deliveryAddressDuringContract'
  | 'deliveryAddressAfterContract'
interface IUserPersonalDetailsConfirmationForm {
  handleSuccess: () => void
}

const MAX_DATE = dayjs().subtract(18, 'year')
const MIN_DATE = dayjs().subtract(100, 'year')

const UserPersonalDetailsConfirmationForm: FC<IUserPersonalDetailsConfirmationForm> = ({
  handleSuccess
}) => {
  const { formatMessage } = useIntl()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const { countries: apiCountries, getCountryById } = useCountriesList()
  const [isSecondStep, setIsSecondStep] = useState(false)
  const [isDifferentBillingAddress, setIsDifferentBillingAddress] = useState(false)
  const [isDifferentDeliveryDuringAddress, setIsDifferentDeliveryDuringAddress] = useState(false)
  const [isDifferentDeliveryAfterAddress, setIsDifferentDeliveryAfterAddress] = useState(false)

  const {
    usersProfilePartialUpdate,
    data = {
      firstName: '',
      lastName: '',
      pesel: '',
      idCard: '',
      passportId: '',
      nationality: '',
      dateOfBirth: '',
      placeOfBirth: '',
      mobile: '',
      email: '',
      residenceCardNumber: ''
    }
  } = useUsersProfile()
  const { userBankAccount, usersBankAccountUpdate } = useUserBankAccount()
  const { usersProfilAddressOfResidence, usersProfilAddressOfResidenceUpdate } =
    useUsersProfilAddressOfResidence()
  const { usersProfilBillingAddress, usersProfilBillingAddressUpdate } =
    useUsersProfilBillingAddress()
  const { usersProfilDeliverAddressDuring, usersProfilDeliverAddressDuringUpdate } =
    useUsersProfilDeliverAddressDuring()
  const { usersProfilDeliverAddressAfter, usersProfilDeliverAddressAfterUpdate } =
    useUsersProfilDeliverAddressAfter()

  const userDetailsForm = useFormik<IUserDetailsConfirmation>({
    enableReinitialize: true,
    initialValues: {
      firstName: data.firstName,
      lastName: data.lastName,
      mobile: data.mobile,
      email: data.email,
      nationality:
        countries.find((country) =>
          data.nationality
            ? country.alpha2.toUpperCase() === data.nationality
            : country.alpha2.toUpperCase() === 'PL'
        ) || {},
      pesel: data.pesel,
      idCard: data.idCard,
      passportId: data.passportId,
      residenceCardNumber: data.residenceCardNumber,
      dateOfBirth: data.dateOfBirth,
      bankNationality: userBankAccount?.hasForeignBankAccount
        ? {
            id: 'OTHER',
            name: formatMessage({
              id: 'userDataConfirmationForm.form.nationalityOthers',
              defaultMessage: 'Inne'
            })
          }
        : {
            id: 'PL',
            name: formatMessage({
              id: 'userDataConfirmationForm.form.nationalityPoland',
              defaultMessage: 'Polska'
            })
          },
      accountNumber:
        (userBankAccount?.hasForeignBankAccount
          ? userBankAccount?.bankAccountData
          : userBankAccount?.bankAccountIban) || '',
      bankName: userBankAccount?.bankName || '',
      address: {
        street: usersProfilAddressOfResidence.street || '',
        city: usersProfilAddressOfResidence.city || '',
        zipcode: usersProfilAddressOfResidence.zipcode || '',
        country: getCountryById(usersProfilAddressOfResidence.countryId || 1)
      },
      billingAddress: {
        street: usersProfilBillingAddress.street || '',
        city: usersProfilBillingAddress.city || '',
        zipcode: usersProfilBillingAddress.zipcode || '',
        country: getCountryById(usersProfilBillingAddress.countryId || 1)
      },
      deliveryAddressDuringContract: {
        street:
          usersProfilDeliverAddressDuring.street || usersProfilAddressOfResidence.street || '',
        city: usersProfilDeliverAddressDuring.city || usersProfilAddressOfResidence.city || '',
        zipcode:
          usersProfilDeliverAddressDuring.zipcode || usersProfilAddressOfResidence.zipcode || '',
        country: getCountryById(
          usersProfilDeliverAddressDuring.countryId || usersProfilAddressOfResidence.countryId || 1
        )
      },
      deliveryAddressAfterContract: {
        street: usersProfilDeliverAddressAfter.street || usersProfilAddressOfResidence.street || '',
        city: usersProfilDeliverAddressAfter.city || usersProfilAddressOfResidence.city || '',
        zipcode:
          usersProfilDeliverAddressAfter.zipcode || usersProfilAddressOfResidence.zipcode || '',
        country: getCountryById(
          usersProfilDeliverAddressAfter.countryId || usersProfilAddressOfResidence.countryId || 1
        )
      }
    },
    validationSchema: isSecondStep
      ? personalDetailsConfirmationSchemaStepTwo(formatMessage)
      : personalDetailsConfirmationSchemaStepOne(formatMessage),
    onSubmit: async (values, { setSubmitting }) => {
      if (isSecondStep) {
        const userData = await usersProfilePartialUpdate.mutateAsync(
          removeEmptyProperties({
            firstName: data.firstName ? '' : values.firstName,
            lastName: data.lastName ? '' : values.lastName,
            mobile: data.mobile ? '' : values.mobile,
            email: data.email ? '' : values.email,
            pesel: data.pesel ? '' : values.pesel,
            idCard: data.idCard ? '' : values.idCard,
            residenceCardNumber: data.residenceCardNumber ? '' : values.residenceCardNumber,
            passportId: data.passportId ? '' : values.passportId,
            dateOfBirth: data.dateOfBirth ? '' : dayjs(values.dateOfBirth).format('YYYY-MM-DD'),
            nationality: data.nationality ? '' : (values.nationality as any).alpha2.toUpperCase()
          })
        )

        const bankAccountData = await usersBankAccountUpdate.mutateAsync(
          removeEmptyProperties({
            hasForeignBankAccount:
              userBankAccount?.hasForeignBankAccount || values.bankNationality.id === 'OTHER',
            bankAccountIban: userBankAccount?.bankAccountIban
              ? ''
              : values.bankNationality.id === 'OTHER'
                ? ''
                : values.accountNumber,
            bankName: userBankAccount?.bankName ? '' : values.bankName,
            bankAccountData: userBankAccount?.bankAccountData
              ? ''
              : values.bankNationality.id === 'OTHER'
                ? values.accountNumber
                : ''
          })
        )

        const residenceAddress = await usersProfilAddressOfResidenceUpdate.mutateAsync(
          removeEmptyProperties({
            city: usersProfilAddressOfResidence.city || values.address.city,
            street: usersProfilAddressOfResidence.street || values.address.street,
            zipcode: usersProfilAddressOfResidence.zipcode || values.address.zipcode,
            countryId: usersProfilAddressOfResidence.countryId || values.address.country.id
          })
        )

        const billingAddress = await usersProfilBillingAddressUpdate.mutateAsync(
          removeEmptyProperties({
            city: usersProfilBillingAddress.city || values.billingAddress.city,
            street: usersProfilBillingAddress.street || values.billingAddress.street,
            zipcode: usersProfilBillingAddress.zipcode || values.billingAddress.zipcode,
            countryId: usersProfilBillingAddress.countryId || values.billingAddress.country.id
          })
        )

        const duringContractAddress = await usersProfilDeliverAddressDuringUpdate.mutateAsync(
          removeEmptyProperties({
            city: usersProfilDeliverAddressDuring.city || values.deliveryAddressDuringContract.city,
            street:
              usersProfilDeliverAddressDuring.street || values.deliveryAddressDuringContract.street,
            zipcode:
              usersProfilDeliverAddressDuring.zipcode ||
              values.deliveryAddressDuringContract.zipcode,
            countryId:
              usersProfilDeliverAddressDuring.countryId ||
              values.deliveryAddressDuringContract.country.id
          })
        )
        const afterContractAddress = await usersProfilDeliverAddressAfterUpdate.mutateAsync(
          removeEmptyProperties({
            city: usersProfilDeliverAddressAfter.city || values.deliveryAddressAfterContract.city,
            street:
              usersProfilDeliverAddressAfter.street || values.deliveryAddressAfterContract.street,
            zipcode:
              usersProfilDeliverAddressAfter.zipcode || values.deliveryAddressAfterContract.zipcode,
            countryId:
              usersProfilDeliverAddressAfter.countryId ||
              values.deliveryAddressAfterContract.country.id
          })
        )

        if (
          userData &&
          bankAccountData &&
          residenceAddress &&
          billingAddress &&
          duringContractAddress &&
          afterContractAddress
        ) {
          handleSuccess()
        }
      } else {
        setIsSecondStep(true)
        window.scrollTo(0, 0)
      }

      setSubmitting(false)
    }
  })

  useEffect(() => {
    if (!isDifferentBillingAddress) {
      userDetailsForm.setFieldValue('billingAddress', userDetailsForm.values.address)
    }
    if (!isDifferentDeliveryDuringAddress) {
      userDetailsForm.setFieldValue('deliveryAddressDuringContract', userDetailsForm.values.address)
    }
    if (!isDifferentDeliveryAfterAddress) {
      userDetailsForm.setFieldValue('deliveryAddressAfterContract', userDetailsForm.values.address)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userDetailsForm.values.address,
    isDifferentBillingAddress,
    isDifferentDeliveryDuringAddress,
    isDifferentDeliveryAfterAddress
  ])

  useEffect(() => {
    //billing address  - load already saved values
    if (
      usersProfilBillingAddress.street &&
      usersProfilAddressOfResidence.street !== usersProfilBillingAddress.street &&
      usersProfilBillingAddress.zipcode &&
      usersProfilAddressOfResidence.zipcode !== usersProfilBillingAddress.zipcode
    ) {
      setIsDifferentBillingAddress(true)
      userDetailsForm.setFieldValue('billingAddress', {
        ...usersProfilBillingAddress,
        country: getCountryById(usersProfilBillingAddress.countryId || 1)
      })
    }
    //address during contract - load already saved values
    if (
      usersProfilDeliverAddressDuring.street &&
      usersProfilAddressOfResidence.street !== usersProfilDeliverAddressDuring.street &&
      usersProfilDeliverAddressDuring.zipcode &&
      usersProfilAddressOfResidence.zipcode !== usersProfilDeliverAddressDuring.zipcode
    ) {
      setIsDifferentDeliveryDuringAddress(true)
      userDetailsForm.setFieldValue('deliveryAddressDuringContract', {
        ...usersProfilDeliverAddressDuring,
        country: getCountryById(usersProfilDeliverAddressDuring.countryId || 1)
      })
    }
    //address after contract - load already saved values
    if (
      usersProfilDeliverAddressAfter.street &&
      usersProfilAddressOfResidence.street !== usersProfilDeliverAddressAfter.street &&
      usersProfilDeliverAddressAfter.zipcode &&
      usersProfilAddressOfResidence.zipcode !== usersProfilDeliverAddressAfter.zipcode
    ) {
      setIsDifferentDeliveryAfterAddress(true)
      userDetailsForm.setFieldValue('deliveryAddressAfterContract', {
        ...usersProfilDeliverAddressAfter,
        country: getCountryById(usersProfilDeliverAddressAfter.countryId || 1)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    usersProfilBillingAddress.street,
    usersProfilBillingAddress.zipcode,
    usersProfilDeliverAddressDuring.street,
    usersProfilDeliverAddressDuring.zipcode,
    usersProfilDeliverAddressAfter.street,
    usersProfilDeliverAddressAfter.zipcode,
    usersProfilAddressOfResidence.street,
    usersProfilAddressOfResidence.zipcode
  ])

  useEffect(() => {
    if (userDetailsForm.values.bankNationality.id === 'PL' && !userBankAccount?.bankAccountIban) {
      userDetailsForm.setFieldValue('accountNumber', 'PL')
    }

    if (
      userDetailsForm.values.bankNationality.id === 'OTHER' &&
      !userBankAccount?.bankAccountData
    ) {
      userDetailsForm.setFieldValue('accountNumber', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userDetailsForm.values.bankNationality.id,
    userBankAccount?.bankAccountIban,
    userBankAccount?.bankAccountData
  ])

  const handleToggleIsDifferentBillingAddress = (
    value: boolean,
    setFunc: React.Dispatch<React.SetStateAction<boolean>>,
    field: AddressField
  ) => {
    setFunc(!value)
    if (!value) {
      userDetailsForm.setFieldValue(field, {
        street: '',
        city: '',
        zipcode: '',
        country: getCountryById(usersProfilAddressOfResidence.countryId || 1)
      })
    }
  }

  return (
    <form onSubmit={userDetailsForm.handleSubmit}>
      <BackNavigationHeader
        label={formatMessage({
          id: 'userDataConfirmationForm.label',
          defaultMessage: 'Dane osobowe'
        })}
        navigateTo={isSecondStep ? undefined : '/'}
        desktopNavigateTo={isSecondStep ? undefined : '/'}
        hasDesktop
        onBack={
          isSecondStep
            ? () => {
                setIsSecondStep(false)
                window.scrollTo(0, 0)
              }
            : undefined
        }
      />
      {isDesktop && (
        <>
          <Box
            sx={{
              mb: 2,
              position: 'fixed',
              top: '70px',
              width: '100%',
              height: '4px',
              bgcolor: 'rgba(82, 126, 247, 0.6)'
            }}>
            <Box
              sx={{
                mb: 2,

                width: isSecondStep ? '100%' : '50%',
                height: '4px',
                bgcolor: 'primary.main'
              }}
            />
          </Box>
        </>
      )}
      <BackNavigationContent>
        <Box display='flex' justifyContent='center' flexWrap='wrap'>
          <Box width={isDesktop ? '315px' : '100%'}>
            {!isDesktop && (
              <>
                <Box
                  sx={{
                    mb: 2,
                    mt: 1,
                    width: '100%',
                    height: '4px',
                    bgcolor: 'rgba(82, 126, 247, 0.6)'
                  }}>
                  <Box
                    sx={{
                      mb: 2,
                      mt: 1,
                      width: isSecondStep ? '100%' : '50%',
                      height: '4px',
                      bgcolor: 'primary.main'
                    }}
                  />
                </Box>
              </>
            )}
            {isSecondStep ? (
              <>
                <Box
                  sx={{
                    mb: 1,
                    mt: `calc(env(safe-area-inset-top) + ${isDesktop ? '6' : '1'}rem)`
                  }}>
                  <Typography fontWeight={500}>
                    {formatMessage({
                      id: 'userDataConfirmationForm.form.bankData',
                      defaultMessage: 'Dane bankowe'
                    })}
                  </Typography>
                </Box>
                <Autocomplete
                  disabled={
                    !!(userBankAccount?.bankAccountIban || userBankAccount?.bankAccountData)
                  }
                  id='bankNationality'
                  disablePortal
                  sx={{ mb: 3 }}
                  value={userDetailsForm.values.bankNationality}
                  getOptionLabel={(option) => option.name}
                  onChange={(_, value) => userDetailsForm.setFieldValue('bankNationality', value)}
                  options={[
                    {
                      id: 'OTHER',
                      name: formatMessage({
                        id: 'userDataConfirmationForm.form.nationalityOthers',
                        defaultMessage: 'Inny'
                      })
                    },
                    {
                      id: 'PL',
                      name: formatMessage({
                        id: 'userDataConfirmationForm.form.nationalityPoland',
                        defaultMessage: 'Polska'
                      })
                    }
                  ]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={formatMessage({
                        id: 'userDataConfirmationForm.form.bankNationality',
                        defaultMessage: 'Kraj banku'
                      })}
                      fullWidth
                    />
                  )}
                />
                <TextField
                  disabled={
                    !!(userBankAccount?.bankAccountIban || userBankAccount?.bankAccountData)
                  }
                  fullWidth
                  sx={{ mb: 3 }}
                  name='accountNumber'
                  label={formatMessage({
                    id: 'userDataConfirmationForm.form.accountNumber',
                    defaultMessage: 'Numer konta do zwrotu kaucji'
                  })}
                  value={userDetailsForm.values.accountNumber}
                  onChange={userDetailsForm.handleChange}
                  autoFocus
                  error={
                    !!userDetailsForm.errors.accountNumber &&
                    !!userDetailsForm.touched.accountNumber
                  }
                  helperText={
                    !!userDetailsForm.errors.accountNumber &&
                    !!userDetailsForm.touched.accountNumber &&
                    userDetailsForm.errors.accountNumber
                  }
                />
                <TextField
                  disabled={!!userBankAccount?.bankName}
                  fullWidth
                  sx={{ mb: 3 }}
                  label={formatMessage({
                    id: 'userDataConfirmationForm.form.bankName',
                    defaultMessage: 'Nazwa banku'
                  })}
                  name='bankName'
                  value={userDetailsForm.values.bankName}
                  onChange={userDetailsForm.handleChange}
                  error={!!userDetailsForm.errors.bankName && !!userDetailsForm.touched.bankName}
                  helperText={
                    !!userDetailsForm.errors.bankName &&
                    !!userDetailsForm.touched.bankName &&
                    userDetailsForm.errors.bankName
                  }
                />
                {/* Address */}
                <Box
                  sx={{
                    mb: 1
                  }}>
                  <Typography fontWeight={500}>
                    {formatMessage({
                      id: 'userDataConfirmationForm.form.address',
                      defaultMessage: 'Adres zamieszkania'
                    })}
                  </Typography>
                </Box>
                <TextField
                  disabled={!!usersProfilAddressOfResidence.street}
                  fullWidth
                  sx={{ mb: 3 }}
                  name='address.street'
                  label={formatMessage({
                    id: 'userDataConfirmationForm.form.street',
                    defaultMessage: 'Ulica'
                  })}
                  value={userDetailsForm.values.address.street}
                  onChange={userDetailsForm.handleChange}
                  error={
                    !!userDetailsForm.errors.address?.street &&
                    !!userDetailsForm.touched.address?.street
                  }
                  helperText={
                    !!userDetailsForm.errors.address?.street &&
                    !!userDetailsForm.touched.address?.street &&
                    userDetailsForm.errors.address?.street
                  }
                />
                <TextField
                  disabled={!!usersProfilAddressOfResidence.zipcode}
                  fullWidth
                  sx={{ mb: 3 }}
                  label={formatMessage({
                    id: 'userDataConfirmationForm.form.zipcode',
                    defaultMessage: 'Kod pocztowy'
                  })}
                  name='address.zipcode'
                  value={userDetailsForm.values.address.zipcode}
                  onChange={userDetailsForm.handleChange}
                  error={
                    !!userDetailsForm.errors.address?.zipcode &&
                    !!userDetailsForm.touched.address?.zipcode
                  }
                  helperText={
                    !!userDetailsForm.errors.address?.zipcode &&
                    !!userDetailsForm.touched.address?.zipcode &&
                    userDetailsForm.errors.address?.zipcode
                  }
                />
                <TextField
                  disabled={!!usersProfilAddressOfResidence.city}
                  fullWidth
                  sx={{ mb: 3 }}
                  label={formatMessage({
                    id: 'userDataConfirmationForm.form.city',
                    defaultMessage: 'Miasto'
                  })}
                  name='address.city'
                  value={userDetailsForm.values.address.city}
                  onChange={userDetailsForm.handleChange}
                  error={
                    !!userDetailsForm.errors.address?.city &&
                    !!userDetailsForm.touched.address?.city
                  }
                  helperText={
                    !!userDetailsForm.errors.address?.city &&
                    !!userDetailsForm.touched.address?.city &&
                    userDetailsForm.errors.address?.city
                  }
                />
                <Autocomplete
                  disabled={!!usersProfilAddressOfResidence.countryId}
                  disablePortal
                  sx={{ mb: 3 }}
                  value={userDetailsForm.values.address.country}
                  getOptionLabel={(option) => option.name}
                  onChange={(_, value) => userDetailsForm.setFieldValue('address.country', value)}
                  options={apiCountries}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={formatMessage({
                        id: 'userDataConfirmationForm.form.country',
                        defaultMessage: 'Kraj'
                      })}
                      fullWidth
                      name='address.country'
                    />
                  )}
                />
                {/* Billing address*/}
                <Box mb={2}>
                  <Typography fontWeight={500}>
                    {formatMessage({
                      id: 'userDataConfirmationForm.form.billingAddress',
                      defaultMessage: 'Adres do płatności'
                    })}
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={
                          !!(usersProfilBillingAddress.street && usersProfilBillingAddress.zipcode)
                        }
                        name='billingAddress'
                        checked={isDifferentBillingAddress}
                        onChange={() =>
                          handleToggleIsDifferentBillingAddress(
                            isDifferentBillingAddress,
                            setIsDifferentBillingAddress,
                            'billingAddress'
                          )
                        }
                      />
                    }
                    label={
                      <Typography variant='caption'>
                        {formatMessage({
                          id: 'userDataConfirmationForm.form.addressDifferent',
                          defaultMessage: 'Inny niż zamieszkania'
                        })}
                      </Typography>
                    }
                  />
                </Box>
                {isDifferentBillingAddress && (
                  <>
                    <TextField
                      disabled={!!usersProfilBillingAddress.street}
                      fullWidth
                      sx={{
                        mb: 3,
                        mt: 'calc(env(safe-area-inset-top) + 1rem)'
                      }}
                      name='billingAddress.street'
                      label={formatMessage({
                        id: 'userDataConfirmationForm.form.street',
                        defaultMessage: 'Ulica'
                      })}
                      value={userDetailsForm.values.billingAddress.street}
                      onChange={userDetailsForm.handleChange}
                      error={
                        !!userDetailsForm.errors.billingAddress?.street &&
                        !!userDetailsForm.touched.billingAddress?.street
                      }
                      helperText={
                        !!userDetailsForm.errors.billingAddress?.street &&
                        !!userDetailsForm.touched.billingAddress?.street &&
                        userDetailsForm.errors.billingAddress?.street
                      }
                    />
                    <TextField
                      disabled={!!usersProfilBillingAddress.zipcode}
                      fullWidth
                      sx={{ mb: 3 }}
                      label={formatMessage({
                        id: 'userDataConfirmationForm.form.zipcode',
                        defaultMessage: 'Kod pocztowy'
                      })}
                      name='billingAddress.zipcode'
                      value={userDetailsForm.values.billingAddress.zipcode}
                      onChange={userDetailsForm.handleChange}
                      error={
                        !!userDetailsForm.errors.billingAddress?.city &&
                        !!userDetailsForm.touched.billingAddress?.city
                      }
                      helperText={
                        !!userDetailsForm.errors.billingAddress?.city &&
                        !!userDetailsForm.touched.billingAddress?.city &&
                        userDetailsForm.errors.billingAddress?.city
                      }
                    />
                    <TextField
                      disabled={!!usersProfilBillingAddress.city}
                      fullWidth
                      sx={{ mb: 3 }}
                      label={formatMessage({
                        id: 'userDataConfirmationForm.form.city',
                        defaultMessage: 'Miasto'
                      })}
                      name='billingAddress.city'
                      value={userDetailsForm.values.billingAddress.city}
                      onChange={userDetailsForm.handleChange}
                      error={
                        !!userDetailsForm.errors.billingAddress?.city &&
                        !!userDetailsForm.touched.billingAddress?.city
                      }
                      helperText={
                        !!userDetailsForm.errors.billingAddress?.city &&
                        !!userDetailsForm.touched.billingAddress?.city &&
                        userDetailsForm.errors.billingAddress?.city
                      }
                    />
                    <Autocomplete
                      disabled={!!usersProfilBillingAddress.countryId}
                      disablePortal
                      sx={{ mb: 3 }}
                      value={userDetailsForm.values.billingAddress.country}
                      getOptionLabel={(option) => option.name}
                      onChange={(_, value) =>
                        userDetailsForm.setFieldValue('billingAddress.country', value)
                      }
                      options={apiCountries}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={formatMessage({
                            id: 'userDataConfirmationForm.form.country',
                            defaultMessage: 'Kraj'
                          })}
                          fullWidth
                          name='billingAddress.country'
                        />
                      )}
                    />
                  </>
                )}
                {/* Delivery address during contract */}
                <Box mb={2}>
                  <Typography fontWeight={500}>
                    {formatMessage({
                      id: 'userDataConfirmationForm.form.deliveryAddressDuring',
                      defaultMessage: 'Adres do doręczeń w trakcie obowiązywania umowy'
                    })}
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={
                          !!(
                            usersProfilDeliverAddressDuring.street &&
                            usersProfilDeliverAddressDuring.zipcode
                          )
                        }
                        name='deliveryAddressDuring'
                        checked={isDifferentDeliveryDuringAddress}
                        onChange={() =>
                          handleToggleIsDifferentBillingAddress(
                            isDifferentDeliveryDuringAddress,
                            setIsDifferentDeliveryDuringAddress,
                            'deliveryAddressDuringContract'
                          )
                        }
                      />
                    }
                    label={
                      <Typography variant='caption'>
                        {formatMessage({
                          id: 'userDataConfirmationForm.form.addressDifferent',
                          defaultMessage: 'Inny niż zamieszkania'
                        })}
                      </Typography>
                    }
                  />
                </Box>
                {isDifferentDeliveryDuringAddress && (
                  <>
                    <TextField
                      disabled={!!usersProfilDeliverAddressDuring.street}
                      fullWidth
                      sx={{
                        mb: 3,
                        mt: 'calc(env(safe-area-inset-top) + 1rem)'
                      }}
                      name='deliveryAddressDuringContract.street'
                      label={formatMessage({
                        id: 'userDataConfirmationForm.form.street',
                        defaultMessage: 'Ulica'
                      })}
                      value={userDetailsForm.values.deliveryAddressDuringContract.street}
                      onChange={userDetailsForm.handleChange}
                      error={
                        !!userDetailsForm.errors.deliveryAddressDuringContract?.street &&
                        !!userDetailsForm.touched.deliveryAddressDuringContract?.street
                      }
                      helperText={
                        !!userDetailsForm.errors.deliveryAddressDuringContract?.street &&
                        !!userDetailsForm.touched.deliveryAddressDuringContract?.street &&
                        userDetailsForm.errors.deliveryAddressDuringContract?.street
                      }
                    />
                    <TextField
                      disabled={!!usersProfilDeliverAddressDuring.zipcode}
                      fullWidth
                      sx={{ mb: 3 }}
                      label={formatMessage({
                        id: 'userDataConfirmationForm.form.zipcode',
                        defaultMessage: 'Kod pocztowy'
                      })}
                      name='deliveryAddressDuringContract.zipcode'
                      value={userDetailsForm.values.deliveryAddressDuringContract.zipcode}
                      onChange={userDetailsForm.handleChange}
                      error={
                        !!userDetailsForm.errors.deliveryAddressDuringContract?.zipcode &&
                        !!userDetailsForm.touched.deliveryAddressDuringContract?.zipcode
                      }
                      helperText={
                        !!userDetailsForm.errors.deliveryAddressDuringContract?.zipcode &&
                        !!userDetailsForm.touched.deliveryAddressDuringContract?.zipcode &&
                        userDetailsForm.errors.deliveryAddressDuringContract?.zipcode
                      }
                    />
                    <TextField
                      disabled={!!usersProfilDeliverAddressDuring.city}
                      fullWidth
                      sx={{ mb: 3 }}
                      label={formatMessage({
                        id: 'userDataConfirmationForm.form.city',
                        defaultMessage: 'Miasto'
                      })}
                      name='deliveryAddressDuringContract.city'
                      value={userDetailsForm.values.deliveryAddressDuringContract.city}
                      onChange={userDetailsForm.handleChange}
                      error={
                        !!userDetailsForm.errors.deliveryAddressDuringContract?.city &&
                        !!userDetailsForm.touched.deliveryAddressDuringContract?.city
                      }
                      helperText={
                        !!userDetailsForm.errors.deliveryAddressDuringContract?.city &&
                        !!userDetailsForm.touched.deliveryAddressDuringContract?.city &&
                        userDetailsForm.errors.deliveryAddressDuringContract?.city
                      }
                    />
                    <Autocomplete
                      disabled={!!usersProfilDeliverAddressDuring.countryId}
                      disablePortal
                      sx={{ mb: 3 }}
                      value={userDetailsForm.values.deliveryAddressDuringContract.country}
                      getOptionLabel={(option) => option.name}
                      onChange={(_, value) =>
                        userDetailsForm.setFieldValue('address.country', value)
                      }
                      options={apiCountries}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={formatMessage({
                            id: 'userDataConfirmationForm.form.country',
                            defaultMessage: 'Kraj'
                          })}
                          fullWidth
                          name='deliveryAddressDuringContract.country'
                        />
                      )}
                    />
                  </>
                )}
                {/* Delivery address after contract */}
                <Box>
                  <Typography fontWeight={500}>
                    {formatMessage({
                      id: 'userDataConfirmationForm.form.deliveryAddressAfter',
                      defaultMessage: 'Adres do doręczeń po okresie obowiązywania umowy'
                    })}
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={
                          !!(
                            usersProfilDeliverAddressAfter.street &&
                            usersProfilDeliverAddressAfter.zipcode
                          )
                        }
                        name='deliveryAddressAfter'
                        checked={isDifferentDeliveryAfterAddress}
                        onChange={() =>
                          handleToggleIsDifferentBillingAddress(
                            isDifferentDeliveryAfterAddress,
                            setIsDifferentDeliveryAfterAddress,
                            'deliveryAddressAfterContract'
                          )
                        }
                      />
                    }
                    label={
                      <Typography variant='caption'>
                        {formatMessage({
                          id: 'userDataConfirmationForm.form.addressDifferent',
                          defaultMessage: 'Inny niż zamieszkania'
                        })}
                      </Typography>
                    }
                  />
                </Box>
                {isDifferentDeliveryAfterAddress && (
                  <>
                    <TextField
                      disabled={!!usersProfilDeliverAddressAfter.street}
                      fullWidth
                      sx={{
                        mb: 3,
                        mt: 'calc(env(safe-area-inset-top) + 1rem)'
                      }}
                      name='deliveryAddressAfterContract.street'
                      label={formatMessage({
                        id: 'userDataConfirmationForm.form.street',
                        defaultMessage: 'Ulica'
                      })}
                      value={userDetailsForm.values.deliveryAddressAfterContract.street}
                      onChange={userDetailsForm.handleChange}
                      error={
                        !!userDetailsForm.errors.deliveryAddressAfterContract?.street &&
                        !!userDetailsForm.touched.deliveryAddressAfterContract?.street
                      }
                      helperText={
                        !!userDetailsForm.errors.deliveryAddressAfterContract?.street &&
                        !!userDetailsForm.touched.deliveryAddressAfterContract?.street &&
                        userDetailsForm.errors.deliveryAddressAfterContract?.street
                      }
                    />
                    <TextField
                      disabled={!!usersProfilDeliverAddressAfter.zipcode}
                      fullWidth
                      sx={{ mb: 3 }}
                      label={formatMessage({
                        id: 'userDataConfirmationForm.form.zipcode',
                        defaultMessage: 'Kod pocztowy'
                      })}
                      name='deliveryAddressAfterContract.zipcode'
                      value={userDetailsForm.values.deliveryAddressAfterContract.zipcode}
                      onChange={userDetailsForm.handleChange}
                      error={
                        !!userDetailsForm.errors.deliveryAddressAfterContract?.zipcode &&
                        !!userDetailsForm.touched.deliveryAddressAfterContract?.zipcode
                      }
                      helperText={
                        !!userDetailsForm.errors.deliveryAddressAfterContract?.zipcode &&
                        !!userDetailsForm.touched.deliveryAddressAfterContract?.zipcode &&
                        userDetailsForm.errors.deliveryAddressAfterContract?.zipcode
                      }
                    />
                    <TextField
                      disabled={!!usersProfilDeliverAddressAfter.city}
                      fullWidth
                      sx={{ mb: 3 }}
                      label={formatMessage({
                        id: 'userDataConfirmationForm.form.city',
                        defaultMessage: 'Miasto'
                      })}
                      name='deliveryAddressAfterContract.city'
                      value={userDetailsForm.values.deliveryAddressAfterContract.city}
                      onChange={userDetailsForm.handleChange}
                      error={
                        !!userDetailsForm.errors.deliveryAddressAfterContract?.city &&
                        !!userDetailsForm.touched.deliveryAddressAfterContract?.city
                      }
                      helperText={
                        !!userDetailsForm.errors.deliveryAddressAfterContract?.city &&
                        !!userDetailsForm.touched.deliveryAddressAfterContract?.city &&
                        userDetailsForm.errors.deliveryAddressAfterContract?.city
                      }
                    />
                    <Autocomplete
                      disabled={!!usersProfilDeliverAddressAfter.countryId}
                      disablePortal
                      sx={{ mb: 3 }}
                      value={userDetailsForm.values.deliveryAddressAfterContract.country}
                      getOptionLabel={(option) => option.name}
                      onChange={(_, value) =>
                        userDetailsForm.setFieldValue('address.country', value)
                      }
                      options={apiCountries}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={formatMessage({
                            id: 'userDataConfirmationForm.form.country',
                            defaultMessage: 'Kraj'
                          })}
                          fullWidth
                          name='deliveryAddressAfterContract.country'
                        />
                      )}
                    />
                  </>
                )}
                <Button
                  size='large'
                  fullWidth
                  variant='contained'
                  sx={{ mb: isDesktop ? 5 : 1, mt: isDesktop ? 2 : 6 }}
                  type='submit'>
                  {formatMessage({
                    id: 'userDataConfirmationForm.form.submit',
                    defaultMessage: 'Zweryfikuj'
                  })}
                </Button>
              </>
            ) : (
              <>
                <TextField
                  disabled={!!data.firstName}
                  name='firstName'
                  value={userDetailsForm.values.firstName}
                  onChange={userDetailsForm.handleChange}
                  fullWidth
                  sx={{
                    mb: 3,
                    mt: `calc(env(safe-area-inset-top) + ${isDesktop ? '6' : '1'}rem)`
                  }}
                  label={formatMessage({
                    id: 'userDataConfirmationForm.form.firstName',
                    defaultMessage: 'Imie'
                  })}
                  autoFocus
                  error={!!userDetailsForm.errors.firstName && !!userDetailsForm.touched.firstName}
                  helperText={
                    !!userDetailsForm.errors.firstName &&
                    !!userDetailsForm.touched.firstName &&
                    userDetailsForm.errors.firstName
                  }
                />
                <TextField
                  disabled={!!data.lastName}
                  name='lastName'
                  value={userDetailsForm.values.lastName}
                  onChange={userDetailsForm.handleChange}
                  fullWidth
                  sx={{ mb: 3 }}
                  label={formatMessage({
                    id: 'userDataConfirmationForm.form.lastName',
                    defaultMessage: 'Nazwisko'
                  })}
                  error={!!userDetailsForm.errors.lastName && !!userDetailsForm.touched.lastName}
                  helperText={
                    !!userDetailsForm.errors.lastName &&
                    !!userDetailsForm.touched.lastName &&
                    userDetailsForm.errors.lastName
                  }
                />
                <TextField
                  disabled={!!data.mobile}
                  name='mobile'
                  value={userDetailsForm.values.mobile}
                  onChange={userDetailsForm.handleChange}
                  fullWidth
                  sx={{
                    mb: 3
                  }}
                  label={formatMessage({
                    id: 'userDataConfirmationForm.form.phone',
                    defaultMessage: 'Numer telefonu'
                  })}
                  error={!!userDetailsForm.errors.mobile && !!userDetailsForm.touched.mobile}
                  helperText={
                    !!userDetailsForm.errors.mobile &&
                    !!userDetailsForm.touched.mobile &&
                    userDetailsForm.errors.mobile
                  }
                />
                <TextField
                  disabled={!!data.email}
                  name='email'
                  value={userDetailsForm.values.email}
                  onChange={userDetailsForm.handleChange}
                  fullWidth
                  sx={{
                    mb: 3
                  }}
                  label={formatMessage({
                    id: 'userDataConfirmationForm.form.email',
                    defaultMessage: 'Email'
                  })}
                  error={!!userDetailsForm.errors.email && !!userDetailsForm.touched.email}
                  helperText={
                    !!userDetailsForm.errors.email &&
                    !!userDetailsForm.touched.email &&
                    userDetailsForm.errors.email
                  }
                />
                <TextField
                  disabled={!!data.pesel}
                  name='pesel'
                  value={userDetailsForm.values.pesel}
                  onChange={userDetailsForm.handleChange}
                  fullWidth
                  sx={{ mb: 3 }}
                  label={formatMessage({
                    id: 'userDataConfirmationForm.form.pesel',
                    defaultMessage: 'PESEL'
                  })}
                  error={!!userDetailsForm.errors.pesel && !!userDetailsForm.touched.pesel}
                  helperText={
                    !!userDetailsForm.errors.pesel &&
                    !!userDetailsForm.touched.pesel &&
                    userDetailsForm.errors.pesel
                  }
                />
                <TextField
                  disabled={!!data.idCard}
                  name='idCard'
                  value={userDetailsForm.values.idCard}
                  onChange={userDetailsForm.handleChange}
                  fullWidth
                  sx={{ mb: 3 }}
                  label={formatMessage({
                    id: 'userDataConfirmationForm.form.idNumber',
                    defaultMessage: 'Numer dowodu'
                  })}
                  error={!!userDetailsForm.errors.idCard && !!userDetailsForm.touched.idCard}
                  helperText={
                    !!userDetailsForm.errors.idCard &&
                    !!userDetailsForm.touched.idCard &&
                    userDetailsForm.errors.idCard
                  }
                />
                <TextField
                  disabled={!!data.passportId}
                  name='passportId'
                  value={userDetailsForm.values.passportId}
                  onChange={userDetailsForm.handleChange}
                  fullWidth
                  sx={{ mb: 3 }}
                  label={formatMessage({
                    id: 'userDataConfirmationForm.form.passportId',
                    defaultMessage: 'Numer paszportu'
                  })}
                  error={
                    !!userDetailsForm.errors.passportId && !!userDetailsForm.touched.passportId
                  }
                  helperText={
                    !!userDetailsForm.errors.passportId &&
                    !!userDetailsForm.touched.passportId &&
                    userDetailsForm.errors.passportId
                  }
                />
                <TextField
                  disabled={!!data.residenceCardNumber}
                  name='residenceCardNumber'
                  value={userDetailsForm.values.residenceCardNumber}
                  onChange={userDetailsForm.handleChange}
                  fullWidth
                  sx={{ mb: 3 }}
                  label={formatMessage({
                    id: 'userDataConfirmationForm.form.residenceCardNumber',
                    defaultMessage: 'Numer karty pobytu'
                  })}
                  error={
                    !!userDetailsForm.errors.residenceCardNumber &&
                    !!userDetailsForm.touched.residenceCardNumber
                  }
                  helperText={
                    !!userDetailsForm.errors.residenceCardNumber &&
                    !!userDetailsForm.touched.residenceCardNumber &&
                    userDetailsForm.errors.residenceCardNumber
                  }
                />
                <Autocomplete
                  disabled={!!data.nationality}
                  id='nationality'
                  disablePortal
                  sx={{ mb: 3 }}
                  value={(userDetailsForm.values.nationality as any) || {}}
                  getOptionLabel={(option) => option.pl}
                  onChange={userDetailsForm.handleChange}
                  options={countries}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={formatMessage({
                        id: 'userDataConfirmationForm.form.nationality',
                        defaultMessage: 'Narodowość'
                      })}
                      fullWidth
                    />
                  )}
                />
                <MobileDatePicker
                  disabled={!!data.dateOfBirth}
                  sx={{ mb: 3 }}
                  disableFuture
                  disableHighlightToday
                  maxDate={MAX_DATE as any}
                  minDate={MIN_DATE as any}
                  value={
                    userDetailsForm.values.dateOfBirth
                      ? dayjs(userDetailsForm.values.dateOfBirth)
                      : undefined
                  }
                  onChange={(value) => {
                    userDetailsForm.setFieldValue('dateOfBirth', value)
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error:
                        !!userDetailsForm.errors.dateOfBirth &&
                        !!userDetailsForm.touched.dateOfBirth,
                      helperText:
                        !!userDetailsForm.errors.dateOfBirth &&
                        !!userDetailsForm.touched.dateOfBirth &&
                        userDetailsForm.errors.dateOfBirth
                    }
                  }}
                  format='YYYY-MM-DD'
                  label={formatMessage({
                    id: 'userDataConfirmationForm.form.dateOfBirth',
                    defaultMessage: 'Data urodzenia'
                  })}
                  onOpen={() => {
                    if (!userDetailsForm.values.dateOfBirth) {
                      userDetailsForm.setFieldValue('dateOfBirth', MAX_DATE)
                    }
                  }}
                />
                <Button
                  size='large'
                  fullWidth
                  variant='outlined'
                  sx={{ mb: isDesktop ? 5 : 1, mt: isDesktop ? 2 : 6 }}
                  type='submit'>
                  {formatMessage({
                    id: 'userDataConfirmationForm.nextStep',
                    defaultMessage: 'Kolejny krok: ADRESY i KONTO'
                  })}
                </Button>
              </>
            )}
          </Box>
        </Box>
      </BackNavigationContent>
    </form>
  )
}

export default UserPersonalDetailsConfirmationForm
