import { FC } from 'react'
import { List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'
import { KeyboardArrowRight, AttachFileOutlined } from '@mui/icons-material'
import { useIntl } from 'react-intl'

import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import InfoIndicator from 'components/InfoIndicator'
import { useLeaseContractsAnnexes } from 'services/contracts/hooks/useLeaseContractsAnnexes'
import { useActiveLeaseContractId } from 'services/contracts/hooks/useActiveLeaseContractId'

const AdditionalServicesAnnexes: FC = () => {
  const { formatMessage } = useIntl()
  const { activeLeaseContractId } = useActiveLeaseContractId()
  const { leaseContractsAnnexes, isFetching, isLoading } =
    useLeaseContractsAnnexes(activeLeaseContractId)

  const onClick = (href: string) => {
    window.open(href, '_blank')
  }

  return (
    <>
      <BackNavigationHeader
        label={formatMessage({
          id: 'additionalServicesAttachments.annexes',
          defaultMessage: 'Aneksy'
        })}
      />
      <BackNavigationContent>
        <List
          sx={{
            '.MuiListItem-root:first-of-type': {
              borderTop: '1px solid #CDCDCD'
            }
          }}
        >
          {leaseContractsAnnexes.map(({ signedDate, startDate, id, scanFile }, index) => (
            <ListItem
              key={id}
              disablePadding
              sx={{
                borderBottom: '1px solid #CDCDCD'
              }}
              secondaryAction={<KeyboardArrowRight color='disabled' />}
              onClick={() => onClick(scanFile)}
            >
              <ListItemButton sx={{ py: 2 }}>
                <ListItemText
                  primary={`Aneks ${index + 1}`}
                  secondary={
                    <Typography
                      sx={{ width: '100px', display: 'flex', flexDirection: 'column' }}
                      variant='caption'
                    >
                      {`Wejście w życie: ${startDate}`}
                    </Typography>
                  }
                />
                <ListItemText
                  primary={
                    <Typography sx={{ visibility: 'hidden' }} variant='caption'>
                      empty
                    </Typography>
                  }
                  secondary={
                    <Typography
                      sx={{ width: '100px', display: 'flex' }}
                      variant='caption'
                    >{`Utworzone: ${signedDate}`}</Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <InfoIndicator
          helperText={formatMessage({
            id: 'additionalServicesAttachments.noAnnexes',
            defaultMessage: 'Brak aneksów.'
          })}
          Icon={AttachFileOutlined}
          isVisible={!leaseContractsAnnexes.length && !isFetching && !isLoading}
        />
      </BackNavigationContent>
    </>
  )
}

export default AdditionalServicesAnnexes
