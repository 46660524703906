import { FC, ReactNode, useEffect } from 'react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'
import { useLanguage } from 'services/i18n/i18n.hooks'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import enMessages from 'services/i18n/messages/en.json'
import plMessages from 'services/i18n/messages/pl.json'

const messages = {
  en: enMessages,
  pl: plMessages
} as { [key: string]: { [key: string]: string } }

const IntlProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { language, DEFAULT_LANGUAGE, AVAILABLE_LANGUAGES, setLanguage } = useLanguage()

  useEffect(() => {
    const browserLanguage = navigator.language.split('-')[0]

    if (AVAILABLE_LANGUAGES.map(({ value }) => value).includes(browserLanguage)) {
      setLanguage(browserLanguage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ReactIntlProvider
      defaultLocale={DEFAULT_LANGUAGE}
      messages={messages[language]}
      locale={language}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language}>
        {children}
      </LocalizationProvider>
    </ReactIntlProvider>
  )
}

export default IntlProvider
