import { useQuery } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useTermsOfUse } from 'services/terms/terms.hooks'
import { useAccessToken } from 'services/token/token.hooks'

export interface OfferMessagesListResponse {
  message: string
  offerId?: number
  createdAt: string
  updatedAt: string
  person: number
  user: number
}

export const useOfferMessagesList = (offerId?: number) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  const {
    data: offerMessagesList = [],
    refetch,
    ...rest
  } = useQuery({
    queryKey: ['offerMessagesList', offerId, masquaradeToken],
    refetchInterval: 300000,
    cacheTime: 0,
    queryFn: () =>
      apiInstance<OfferMessagesListResponse[]>({
        url: `/projects/offers/${offerId}/messages/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && hasUserConsented
  })

  return { offerMessagesList, refetchMessagesList: refetch, ...rest }
}
