import { styled } from '@mui/material/styles'
import { Button as MUIButton, Box } from '@mui/material'

export const Button = styled(MUIButton)<{ isActive: boolean }>(({ isActive }) => {
  const baseStyle = {
    width: '100px',
    height: '100px',
    marginBottom: 8,
    transition: 'all 0.4s'
  }
  if (isActive) {
    return {
      border: '1px solid #527EF7',
      backgroundColor: '#F2F6FF',
      '&:focus': {
        backgroundColor: '#F2F6FF'
      },
      ...baseStyle
    }
  }

  return {
    border: '1px solid #D3D3D3',
    ...baseStyle
  }
})

export const PaperButtonList = styled(Box)(() => ({
  margin: '-0.5rem',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  width: '100%'
}))
