import { FactCheckOutlined, RemoveRedEye } from '@mui/icons-material'
import { Box, Link, Typography } from '@mui/material'
import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import Section from 'components/Section'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { FC, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useActiveLeaseContractId } from 'services/contracts/hooks/useActiveLeaseContractId'
import { useLeaseContractsList } from 'services/contracts/hooks/useLeaseContracts'
import { useLeaseContractsBookingAgreement } from 'services/contracts/hooks/useLeaseContractsBookingAgreement'
import { useLeaseContractsDepositAccountBalance } from 'services/contracts/hooks/useLeaseContractsDepositAccountBalance'
import AdditionalServicesOnlinePaymentButton from './AdditionalServicesOnlinePaymentButton'
import AdditionalServicesPaymentDetailsButton from './AdditionalServicesPaymentDetailsButton'

dayjs.extend(isBetween)

const AdditionalServicesBookingAgreement: FC = () => {
  const { formatMessage } = useIntl()
  const { activeLeaseContractId } = useActiveLeaseContractId()
  const { leaseContractsDepositAccountBalance } =
    useLeaseContractsDepositAccountBalance(activeLeaseContractId)
  const { getActiveContract } = useLeaseContractsList()
  const { bookingAgreementPdf } = useLeaseContractsBookingAgreement(activeLeaseContractId)

  const activeContract = useMemo(
    () => getActiveContract(activeLeaseContractId),
    [activeLeaseContractId, getActiveContract]
  )

  return (
    <>
      <BackNavigationHeader
        label={formatMessage({
          id: 'additionalServicesBookingAgreement.title',
          defaultMessage: 'Umowa rezerwacyjna'
        })}
      />
      <BackNavigationContent>
        <Section>
          <Box mt={2} sx={{ width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center'
              }}
            >
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}
              >
                <FactCheckOutlined color='disabled' />
                <Typography variant='h6' fontWeight={600} ml={1}>
                  {formatMessage({
                    id: 'additionalServicesBookingAgreement.title',
                    defaultMessage: 'Umowa rezerwacyjna'
                  })}
                </Typography>
              </Box>
              {bookingAgreementPdf?.bookingAgreementPdf && (
                <Link
                  href={bookingAgreementPdf.bookingAgreementPdf}
                  target='_blank'
                  sx={{
                    textDecoration: 'none',
                    fontSize: '0.875rem',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <RemoveRedEye />
                </Link>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center',
                mt: 3,
                mb: 2
              }}
            >
              <Typography variant='overline' fontSize={14} fontWeight={700} ml={1} color='primary'>
                {formatMessage({
                  id: 'additionalServicesBookingAgreement.depositToPay',
                  defaultMessage: 'Kaucja do wpłacenia'
                })}
              </Typography>
              <Typography variant='h6' fontWeight={600} ml={1}>
                {`${leaseContractsDepositAccountBalance?.depositBalanceValue || '0'} ${activeContract?.paymentCurrency}`}
              </Typography>
            </Box>
            {activeContract?.paymentCurrency &&
              ['PLN'].includes(activeContract.paymentCurrency) && (
                <AdditionalServicesOnlinePaymentButton
                  documentType='deposit'
                  documentId={activeLeaseContractId}
                  variant='contained'
                  fullWidth
                />
              )}
            <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
              <AdditionalServicesPaymentDetailsButton
                amount={leaseContractsDepositAccountBalance?.depositBalanceValue || '0'}
                paidAmount={'0'}
                type='deposit'
              />
            </Box>
          </Box>
        </Section>
      </BackNavigationContent>
    </>
  )
}

export default AdditionalServicesBookingAgreement
