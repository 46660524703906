import { FC, useState } from 'react'
import { List, ListItem, ListItemButton, ListItemText, Box } from '@mui/material'
import { KeyboardArrowRight } from '@mui/icons-material'

import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import {
  UserDetailsAddress,
  UserDetailsIce,
  UserDetailsAddressDuringRental,
  UserDetailsAddressAfterRental,
  UserDetailsContactDetails
} from 'components/User'
import { useIntl } from 'react-intl'

const UserDetails: FC = () => {
  const { formatMessage } = useIntl()
  const [detailsView, setDetailsView] = useState<
    'menu' | 'general' | 'address' | 'addressDuring' | 'addressAfter' | 'ice'
  >('menu')

  switch (detailsView) {
    case 'general':
      return <UserDetailsContactDetails onBack={() => setDetailsView('menu')} />
    case 'address':
      return <UserDetailsAddress onBack={() => setDetailsView('menu')} />
    case 'addressDuring':
      return <UserDetailsAddressDuringRental onBack={() => setDetailsView('menu')} />
    case 'addressAfter':
      return <UserDetailsAddressAfterRental onBack={() => setDetailsView('menu')} />
    case 'ice':
      return <UserDetailsIce onBack={() => setDetailsView('menu')} />

    default:
      return (
        <>
          <BackNavigationHeader
            label={formatMessage({
              id: 'userDetails.label',
              defaultMessage: 'Dane kontaktowe'
            })}
          />
          <BackNavigationContent>
            <Box pt='env(safe-area-inset-top)'>
              <List>
                <ListItem
                  onClick={() => setDetailsView('address')}
                  disablePadding
                  sx={{
                    borderBottom: '1px solid #CDCDCD',
                    borderTop: '1px solid #CDCDCD'
                  }}
                  secondaryAction={<KeyboardArrowRight />}
                >
                  <ListItemButton sx={{ py: 2 }}>
                    <ListItemText
                      primary={formatMessage({
                        id: 'userDetails.address',
                        defaultMessage: 'Adres zamieszkania'
                      })}
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  onClick={() => setDetailsView('addressDuring')}
                  disablePadding
                  sx={{ borderBottom: '1px solid #CDCDCD' }}
                  secondaryAction={<KeyboardArrowRight />}
                >
                  <ListItemButton sx={{ py: 2 }}>
                    <ListItemText
                      primary={formatMessage({
                        id: 'userDetails.addressDuring',
                        defaultMessage: 'Adres do doreczeń'
                      })}
                      secondary={formatMessage({
                        id: 'userDetails.addressDuring2',
                        defaultMessage: 'W trakcie trwania umowy'
                      })}
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  onClick={() => setDetailsView('addressAfter')}
                  disablePadding
                  sx={{ borderBottom: '1px solid #CDCDCD' }}
                  secondaryAction={<KeyboardArrowRight />}
                >
                  <ListItemButton sx={{ py: 2 }}>
                    <ListItemText
                      primary={formatMessage({
                        id: 'userDetails.addressDuring',
                        defaultMessage: 'Adres do doreczeń'
                      })}
                      secondary={formatMessage({
                        id: 'userDetails.addressDuring3',
                        defaultMessage: 'Po zakończeniu umowy'
                      })}
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  onClick={() => setDetailsView('ice')}
                  disablePadding
                  sx={{ borderBottom: '1px solid #CDCDCD' }}
                  secondaryAction={<KeyboardArrowRight />}
                >
                  <ListItemButton sx={{ py: 2 }}>
                    <ListItemText primary='ICE' />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </BackNavigationContent>
        </>
      )
  }
}

export default UserDetails
