import { reject, isNil, isEmpty, anyPass } from 'ramda'
import { useIntl } from 'react-intl'

/**
 * Remove empty properties from object
 * @returns {Object} Returns object without Nil or Empty properties.
 */
export const removeEmptyProperties = (objectToClean: any) =>
  reject(anyPass([isEmpty, isNil]))(objectToClean)

export const getLatLng = (geoposition: string) => {
  let coords = geoposition?.split(',')

  let latitude = parseFloat(coords[0])
  let longitude = parseFloat(coords[1])

  return {
    lat: latitude,
    lng: longitude
  }
}

export const formatDate = (dateString: string) => {
  const date = new Date(dateString)

  // Get individual date and time components

  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')

  // Combine them into a formatted string
  const formattedDate = `${hours}:${minutes}`

  return formattedDate
}

export const GetPhotoLabel = (count: number) => {
  const { formatMessage } = useIntl()

  if (count === 1) return formatMessage({ id: 'widgetOffer.photos1', defaultMessage: 'Zdjęcie' })
  if (count > 1 && count < 5)
    return formatMessage({ id: 'widgetOffer.photos2to4', defaultMessage: 'Zdjęcia' })
  return formatMessage({ id: 'widgetOffer.photos', defaultMessage: 'Zdjęć' })
}
