import { FC, ReactNode } from 'react'
import { Box, IconButton, Typography, useMediaQuery, useTheme, SxProps } from '@mui/material'
import { ArrowBackIosNew } from '@mui/icons-material'
import type { BoxProps } from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'
import useSafeAreaInsets from '../../styles/useSafeAreaInsets'

interface BackNavigationHeaderProps {
  label: string | ReactNode
  onBack?: () => void
  navigateTo?: string
  desktopNavigateTo?: string
  additionalAction?: ReactNode
  sx?: SxProps
  hasDesktop?: boolean
}

const BackNavigationHeader: FC<BackNavigationHeaderProps> = ({
  label,
  onBack,
  desktopNavigateTo,
  navigateTo,
  additionalAction = null,
  sx,
  hasDesktop
}) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const { safeAreaInsetTop } = useSafeAreaInsets()

  const onBackClick = () => {
    if (!!onBack) {
      return onBack()
    }

    if (isDesktop && !!desktopNavigateTo) {
      return navigate(desktopNavigateTo)
    }

    if (!!navigateTo) {
      return navigate(navigateTo)
    }

    return navigate(-1)
  }

  if (isDesktop && !hasDesktop) {
    return null
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: `calc(70px + ${safeAreaInsetTop})`,
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        flex: '1',
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: 'background.default',
        paddingTop: safeAreaInsetTop,
        px: 2,
        zIndex: 1000,
        ...sx
      }}>
      <IconButton onClick={onBackClick}>
        <ArrowBackIosNew />
      </IconButton>
      <Typography sx={{ mx: 1, flex: 1, fontWeight: 500 }}>{label}</Typography>
      <Box>{additionalAction}</Box>
    </Box>
  )
}

export const BackNavigationContent: FC<BoxProps> = ({ sx, ...props }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const { safeAreaInsetTop } = useSafeAreaInsets()

  return (
    <Box
      {...props}
      sx={{
        paddingTop: isDesktop ? 'unset' : `calc(${safeAreaInsetTop} + 70px)`,
        px: 2,
        paddingBottom: isDesktop ? 0 : `calc(env(--safe-area-inset-bottom) + 2rem)`,
        ...sx
      }}
    />
  )
}

export default BackNavigationHeader
