import { useQuery, useMutation } from '@tanstack/react-query'
import { apiInstance } from 'services/api'

import { useAccessToken } from 'services/token/token.hooks'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'

type BoardsListResponse = {
  id: number
  name: string
  investment: {
    id: number
    name: string
    nameEn: string
    addressStreet: string
    addressCity: string
    addressZipcode: string
    description: string
    descriptionLocal: string
  }
}

type BoardsListPostsResponse = {
  results: {
    id: number
    title: string
    text: string
    multimedia: {
      id: number
      multimedia: string
    }[]
    board: {
      id: number
      name: string
      investment: BoardsListResponse['investment']
    }
    eventDate: string
    status: number
    statusDisplay: string
    createdBy: {
      id: number
      firstName: string
    }
  }[]
}

export const useBoardsList = () => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()

  return useQuery({
    queryKey: ['boardsList', masquaradeToken],
    queryFn: () =>
      apiInstance<BoardsListResponse[]>({
        url: '/boards/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken
  })
}

export const useBoardsListPosts = (boardIds: number[]) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()

  const {
    data: boardsListPosts = [],
    isFetching,
    isLoading,
    refetch
  } = useQuery({
    queryKey: ['boardsList', boardIds, masquaradeToken],
    queryFn: () =>
      Promise.all(
        boardIds.map((id) =>
          apiInstance<BoardsListPostsResponse['results']>({
            url: `/boards/${id}/posts/`,
            method: 'GET',
            headers: {
              authorization: `Bearer ${accessToken}`,
              'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
            }
          })
        )
      ).then((data) =>
        data.reduce(
          (prev: BoardsListPostsResponse['results'], crr: any) => [...prev, ...crr.data.results],
          []
        )
      ),
    enabled: !!accessToken || !boardIds.length
  })

  return { boardsListPosts, isLoading: isLoading || isFetching, refetch }
}

export const useBoardsPostsCreate = () => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()

  const { mutateAsync, ...rest } = useMutation({
    mutationFn: ({
      title,
      text,
      eventDate,
      boardId
    }: {
      title: string
      text: string
      eventDate: string
      boardId: number
    }) =>
      apiInstance<BoardsListResponse[]>({
        url: `/boards/${boardId}/posts/`,
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        },
        data: {
          title,
          text,
          eventDate
        }
      }).then(({ data }) => data)
  })

  return {
    createPost: mutateAsync,
    ...rest
  }
}
