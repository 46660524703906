import { useQuery } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useAccessToken } from 'services/token/token.hooks'
import { useTermsOfUse } from 'services/terms/terms.hooks'

export interface HelpdeskCategoriesListResponse {
  id: number
  name: string
  nameLocal: string
  iconName: string
  parentCategoryId: number
}

export const useHelpdeskCategoriesList = () => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  const { data: categoriesList = [], ...rest } = useQuery({
    queryKey: ['categoriesList', masquaradeToken],
    queryFn: () =>
      apiInstance<HelpdeskCategoriesListResponse[]>({
        url: '/helpdesk/categories/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && hasUserConsented
  })

  return { categoriesList, ...rest }
}
