import { FC } from 'react'
import { AdditionalServicesProtocolSpaces } from 'components/AdditionalServices'
import DesktopNavigation from 'components/DesktopNavigation'
import { UserProtectedRoute } from 'routes'
import { useIntl } from 'react-intl'

const AdditionalServicesProtocolSpacesPage: FC = () => {
  const { formatMessage } = useIntl()
  return (
    <UserProtectedRoute>
      <DesktopNavigation
        label={formatMessage({
          id: 'additionalServicesProtocolSpaces.comments',
          defaultMessage: 'Zgłos uwagi'
        })}
        desktopNavigateTo='/additional-services'
      />
      <AdditionalServicesProtocolSpaces />
    </UserProtectedRoute>
  )
}

export default AdditionalServicesProtocolSpacesPage
