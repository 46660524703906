import { useEffect } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTermsOfUse } from 'services/terms/terms.hooks'
import { useAccessToken } from 'services/token/token.hooks'
import { useMasquaradeToken, useMasquaradeUser } from 'services/masquarade/masquarade.hooks'
import { useIntl } from 'react-intl'
import { UserProtectedRoute } from 'routes'

const TermsOfUse = () => {
  const { formatMessage } = useIntl()
  const { termsOfUse, isLoadingTermsOfUse, mutateAsyncTermsOfUse, refetch, isLoading, error } =
    useTermsOfUse()
  const theme = useTheme()
  const { clearUserData, accessToken } = useAccessToken()
  const { clearMasquaradeToken } = useMasquaradeToken()
  const { clearMasquaradeUser } = useMasquaradeUser()

  const onExit = () => {
    clearUserData()
    clearMasquaradeToken()
    clearMasquaradeUser()
  }

  const onAccept = async () => {
    await mutateAsyncTermsOfUse(termsOfUse?.id)
    refetch()
  }

  useEffect(() => {
    if (error) {
      onExit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  if ((isLoading || error) && !!accessToken) {
    return null
  }

  return (
    <UserProtectedRoute>
      <Box sx={{ width: '100%', maxWidth: '720px', margin: '2rem auto', px: 2 }}>
        <Typography variant='h5' fontWeight='bold' mb={3}>
          {formatMessage({
            id: 'termsOfUse.terms',
            defaultMessage: 'Warunki korzystania z usługi'
          })}
        </Typography>
        <Box
          sx={{
            width: '100%',
            height: '65vh',
            border: '1px solid black',
            mb: 3,
            [theme.breakpoints.up('md')]: {
              height: '80vh'
            }
          }}
          component='iframe'
          src={termsOfUse?.file}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton sx={{ mr: 2 }} onClick={onExit} loading={isLoadingTermsOfUse}>
            {formatMessage({
              id: 'termsOfUse.exit',
              defaultMessage: 'Odrzuć'
            })}
          </LoadingButton>
          <LoadingButton
            variant='contained'
            color='primary'
            loading={isLoadingTermsOfUse}
            onClick={onAccept}
          >
            {formatMessage({
              id: 'termsOfUse.accept',
              defaultMessage: 'Akceptuje'
            })}
          </LoadingButton>
        </Box>
      </Box>
    </UserProtectedRoute>
  )
}

export default TermsOfUse
