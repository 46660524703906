import { FC } from 'react'

import Login from 'components/Login'
import { UnauthorizedRoute } from 'routes'

const LoginPage: FC = () => {
  return (
    <UnauthorizedRoute>
      <Login />
    </UnauthorizedRoute>
  )
}

export default LoginPage
