import { FC } from 'react'
import DesktopNavigation from 'components/DesktopNavigation'
import { UserProtectedRoute } from 'routes'
import { useIntl } from 'react-intl'
import { MasquaradeLogin } from 'components/Masquarade'

const UserMasquaradePage: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <UserProtectedRoute>
      <DesktopNavigation
        label={formatMessage({
          id: 'userProfile.supervisorAccount',
          defaultMessage: 'Maskarada'
        })}
        desktopNavigateTo='/'
      />
      <MasquaradeLogin />
    </UserProtectedRoute>
  )
}

export default UserMasquaradePage
