import { useQuery } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useAccessToken } from 'services/token/token.hooks'

export interface LeaseContractsBookingAgreementResponse {
  id: number
  bookingAgreementPdf: string
}

export const useLeaseContractsBookingAgreement = (id: number) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()

  const { data: bookingAgreementPdf, ...rest } = useQuery({
    queryKey: ['bookingAgreementPdf', id, masquaradeToken],
    queryFn: () =>
      apiInstance<LeaseContractsBookingAgreementResponse>({
        url: `/lease/contracts/${id}/booking-agreement`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken
  })

  return {
    bookingAgreementPdf,
    ...rest
  }
}
