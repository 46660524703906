import { useMutation } from '@tanstack/react-query'
import { apiInstance } from 'services/api'

import { useAccessToken } from 'services/token/token.hooks'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'

interface UserConsentsRequestPayload {
  currentPassword: string
  newPassword: string
  retypeNewPassword: string
}

interface UserConsentsResponse {
  info: string
}

export const useUserPassword = () => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()

  const useUserPasswordUpdate = useMutation({
    mutationFn: (passwordPayload: Partial<UserConsentsRequestPayload>) =>
      apiInstance<UserConsentsResponse>({
        url: '/users/profile/password/set/',
        method: 'POST',
        data: passwordPayload,
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data)
  })

  return { useUserPasswordUpdate }
}
