import { useQuery } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import dayjs, { Dayjs } from 'dayjs'
import { useAccessToken } from 'services/token/token.hooks'
import { CONTRACT_DOCUMENT_TYPE, ContractDocumentTypes } from 'services/contracts/contracts.consts'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'

export interface LeaseContractsDebitNotesListResponse {
  id: number
  number: string
  amount: string
  dateOfIssue: string
  file: string
  paymentAmount: string
  positions: {
    id: number
    title: string
    amount: string
  }[]
  isPaid: boolean
}

export interface LeaseContractsDebitNotesListWithDate
  extends Omit<LeaseContractsDebitNotesListResponse, 'dateOfIssue'> {
  displayDate: Dayjs
  type: ContractDocumentTypes
}

export const useLeaseContractsDebitNotesList = (
  id?: LeaseContractsDebitNotesListResponse['id'],
  isPaid: boolean = false
) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()

  const { data: leaseContractsDebitNotesLis = [], ...rest } = useQuery({
    queryKey: ['leaseContractsDebitNotesLis', id, isPaid, masquaradeToken],
    cacheTime: 3600000,
    queryFn: () =>
      apiInstance<LeaseContractsDebitNotesListResponse[]>({
        url: `/lease/contracts/${id}/debit-notes/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        },
        params: {
          isPaid
        }
      }).then(({ data }) => data),
    select: (date) =>
      date.map((note) => ({
        ...note,
        displayDate: dayjs(note.dateOfIssue).startOf('month'),
        type: CONTRACT_DOCUMENT_TYPE.debitNote
      })) as LeaseContractsDebitNotesListWithDate[],
    enabled: !!accessToken && !!id
  })

  return { leaseContractsDebitNotesLis, ...rest }
}
