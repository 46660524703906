import { FC, useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import { useIntl } from 'react-intl'
import Section from 'components/Section'
import { useLeaseContractsList } from 'services/contracts/hooks/useLeaseContracts'
import { useSchedulesWithDocumnets } from 'services/contracts/hooks/useSchedulesWithDocumnets'
import { useNavigate } from 'react-router-dom'

const WidgetBalance: FC = () => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { leaseContractsList } = useLeaseContractsList()

  const activeLeases = leaseContractsList?.filter(({ leaseStatus }) => leaseStatus === 0) || []

  const itemWithHighestId =
    activeLeases.length > 0
      ? activeLeases.reduce((max, item) => {
          return item.id > max.id ? item : max
        }, activeLeases[0])
      : null

  const [selectedLease, setSelectedLease] = useState<any>(itemWithHighestId?.id || null)

  const { accountBalance, isLoading } = useSchedulesWithDocumnets({
    activeLeaseContractId: selectedLease,
    isPaid: false
  })

  useEffect(() => {
    if (itemWithHighestId) {
      setSelectedLease(itemWithHighestId.id)
    }
  }, [itemWithHighestId])

  if (!activeLeases.length) {
    return null
  }

  return (
    <Section
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '15px'
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: '15px',
          width: '100%'
        }}>
        <Typography variant='h5' fontWeight={500}>
          {formatMessage({
            id: 'balanceWidget.balnce',
            defaultMessage: 'Saldo'
          })}
        </Typography>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Typography variant='h5' fontWeight={500}>
            {accountBalance === 0
              ? formatMessage({
                  id: 'balanceWidget.paid',
                  defaultMessage: 'Opłacone'
                })
              : '-' + accountBalance.toFixed(2) + ' PLN'}
          </Typography>
        )}
      </Box>
      <Box sx={{ width: '100%' }}>
        <InputLabel>
          {formatMessage({
            id: 'defectsReportFault.contract',
            defaultMessage: 'Umowa'
          })}
        </InputLabel>
        <Select
          fullWidth
          data-cy='select-lease'
          value={selectedLease}
          label={formatMessage({
            id: 'defectsReportFault.contract',
            defaultMessage: 'Umowa'
          })}
          defaultValue={selectedLease}
          onChange={(event) => setSelectedLease(Number(event.target.value))}>
          {activeLeases.map(({ id, contractNumber }) => (
            <MenuItem key={contractNumber} value={id}>
              {contractNumber}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {accountBalance !== 0 && (
        <Button
          variant='outlined'
          fullWidth
          onClick={() => navigate(`additional-services/${selectedLease}/payment-schedule`)}>
          {formatMessage({
            id: 'balanceWidget.pay',
            defaultMessage: 'Opłać'
          })}
        </Button>
      )}
    </Section>
  )
}

export default WidgetBalance
