import { FC } from 'react'
import { NotificationsList } from 'components/Notifications'
import RestrictedForDesktop from 'components/RestrictedForDesktop'
import DesktopNavigation from 'components/DesktopNavigation'
import { UserProtectedRoute } from 'routes'
import { useIntl } from 'react-intl'

const NotificationsPage: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <UserProtectedRoute>
      <DesktopNavigation
        label={formatMessage({
          id: 'notificationsPage.notifications',
          defaultMessage: 'Powiadomienia'
        })}
        isMainRoute
      />
      <RestrictedForDesktop>
        <NotificationsList />
      </RestrictedForDesktop>
    </UserProtectedRoute>
  )
}

export default NotificationsPage
