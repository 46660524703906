import { FC } from 'react'
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
// import { useIntl } from 'react-intl'

const ProtocolFurniture: FC<{
  furniture: {
    id: number
    furnitureType: {
      id: number
      name: string
    }
    quantity: number
  }[]
}> = ({ furniture }) => {
  // const { formatMessage } = useIntl()

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>Nazwa</TableCell>
            <TableCell sx={{ fontWeight: 700 }} align='right'>
              Ilość
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody></TableBody>
        {furniture.map((fnt) => (
          <TableRow key={fnt.id}>
            <TableCell component='th' scope='row'>
              {fnt.furnitureType.name}
            </TableCell>
            <TableCell align='right'>{fnt.quantity}</TableCell>
          </TableRow>
        ))}
      </Table>
    </TableContainer>
  )
}

export default ProtocolFurniture
