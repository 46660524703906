import { useQuery } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useAccessToken } from 'services/token/token.hooks'
import type { HelpdeskTicketsListResponse } from './useHelpdeskTicketsList'
import { useTermsOfUse } from 'services/terms/terms.hooks'

export interface HelpdeskTicketsMessagesListResponse {
  message: string
  attachment: any
  author?: HelpdeskTicketsListResponse['authorPerson']
  ticketId: HelpdeskTicketsListResponse['id']
  createdAt: string
  updatedAt: string
}

export const useHelpdeskTicketsMessagesList = (ticketId: HelpdeskTicketsListResponse['id']) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  const {
    data: ticketsMessagesList = [],
    refetch,
    ...rest
  } = useQuery({
    queryKey: ['ticketsMessagesList', ticketId, masquaradeToken],
    refetchInterval: 30000,
    cacheTime: 0,
    queryFn: () =>
      apiInstance<HelpdeskTicketsMessagesListResponse[]>({
        url: `/helpdesk/tickets/${ticketId}/messages/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && hasUserConsented
  })

  return { ticketsMessagesList, refetchMessagesList: refetch, ...rest }
}
