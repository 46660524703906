import { FC, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useLeaseContractsMediaMetersList } from 'services/contracts/hooks/useLeaseContractsMediaMetersList'
import { Box, FormControl, Select, MenuItem, Typography } from '@mui/material'
import dayjs from 'dayjs'
import PaperButton from 'components/PaperButton'
import {
  ElectricBoltOutlined,
  Brightness5Outlined,
  WaterOutlined,
  LocalFireDepartmentRounded,
  AcUnitOutlined
} from '@mui/icons-material'

import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js'
import { Bar, Doughnut } from 'react-chartjs-2'
import { useIntl } from 'react-intl'
import { useLeaseContractsList } from 'services/contracts/hooks/useLeaseContracts'
import { useLanguage } from 'services/i18n/i18n.hooks'
import useSentry from 'utils/useSentry'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement)

const METERS = {
  wattHourMeter: {
    Icon: ElectricBoltOutlined,
    i18n: 'additionalServicesUtilitiesCalculation.wattHourMeter',
    defaultMessage: 'Prąd',
    color: '#FFE205',
    suffix: 'kWh'
  },
  heatingMeter: {
    Icon: LocalFireDepartmentRounded,
    i18n: 'additionalServicesUtilitiesCalculation.heatingMeter',
    defaultMessage: 'Ogrzewanie',
    color: '#8F9BB3',
    suffix: 'Gj'
  },
  hotWaterMeter: {
    Icon: Brightness5Outlined,
    i18n: 'additionalServicesUtilitiesCalculation.hotWaterMeter',
    defaultMessage: 'Ciepła woda',
    color: '#1D226E',
    suffix: 'm3'
  },
  coldWaterMeter: {
    defaultMessage: 'Zimna woda',
    i18n: 'additionalServicesUtilitiesCalculation.coldWaterMeter',
    Icon: WaterOutlined,
    color: '#527EF7',
    suffix: 'm3'
  },
  airConditioningMeter: {
    Icon: AcUnitOutlined,
    defaultMessage: 'Klimatyzacja',
    i18n: 'additionalServicesUtilitiesCalculation.airConditioningMeter',
    color: '#00B383',
    suffix: 'kWh'
  }
}

type MetersType =
  | 'wattHourMeter'
  | 'heatingMeter'
  | 'hotWaterMeter'
  | 'coldWaterMeter'
  | 'airConditioningMeter'

const AdditionalServicesUtilitiesCalculation: FC = () => {
  const { dateAdapter } = useLanguage()
  const { formatMessage } = useIntl()
  const [activePeriod, setActivePeriod] = useState(0)
  const [activeType, setActiveType] = useState<MetersType>('wattHourMeter')
  const { captureException } = useSentry()

  const { id } = useParams()
  const { leaseContractsMediaMetersList } = useLeaseContractsMediaMetersList(Number(id))

  const allMetersData = useMemo(
    () => ({
      labels: leaseContractsMediaMetersList.map(({ dateMetersFrom }) =>
        dayjs(dateMetersFrom).locale(dateAdapter).format('MMMM YYYY')
      ),
      datasets: [
        {
          data: leaseContractsMediaMetersList.map((prop) => prop.wear[activeType]),
          backgroundColor: '#527EF7',
          barThickness: 16
        }
      ]
    }),
    [leaseContractsMediaMetersList, activeType, dateAdapter]
  )

  const grossCostData = useMemo(() => {
    if (leaseContractsMediaMetersList.length === 0) {
      return {
        labels: [],
        datasets: []
      }
    }

    try {
      return {
        labels: Object.keys(leaseContractsMediaMetersList[activePeriod]?.grossCost).map((key) =>
          formatMessage({
            id: METERS[key as MetersType].i18n,
            defaultMessage: METERS[key as MetersType].defaultMessage
          })
        ),
        datasets: [
          {
            data: Object.keys(leaseContractsMediaMetersList[activePeriod]?.grossCost).map(
              (key) => (leaseContractsMediaMetersList[activePeriod]?.grossCost as any)[key]
            ),
            backgroundColor: Object.keys(
              leaseContractsMediaMetersList[activePeriod]?.grossCost
            ).map((key) => METERS[key as MetersType].color),
            borderWidth: 0
          }
        ]
      }
    } catch (error) {
      captureException(error, { leaseContractsMediaMetersList, activePeriod })

      return {
        labels: [],
        datasets: []
      }
    }
  }, [leaseContractsMediaMetersList, activePeriod, formatMessage, captureException])

  const { getActiveContract } = useLeaseContractsList()

  const activeContract = useMemo(() => getActiveContract(id as string), [id, getActiveContract])

  if (!!leaseContractsMediaMetersList.length) {
    return (
      <>
        <BackNavigationHeader label='Media' />
        <BackNavigationContent>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <FormControl>
              <Select
                value={activePeriod}
                onChange={({ target }) => setActivePeriod(target.value as number)}
                variant='outlined'
                size='small'>
                {leaseContractsMediaMetersList.map(({ dateMetersFrom }, key) => (
                  <MenuItem key={dateMetersFrom} value={key}>
                    {dayjs(dateMetersFrom).locale(dateAdapter).format('MMMM YYYY')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              borderRadius: '8px',
              backgroundColor: '#F2F6FF',
              py: 4,
              my: 4
            }}>
            <Box
              sx={{
                maxHeight: '200px',
                display: 'flex',
                justifyContent: 'center',
                position: 'relative'
              }}>
              <Typography
                variant='h5'
                sx={{
                  position: 'absolute',
                  fontWeight: 600,
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)'
                }}>
                {leaseContractsMediaMetersList[activePeriod].totalGrossCost}
                <Typography display='block' textAlign='center' variant='caption'>
                  zł
                </Typography>
              </Typography>
              <Doughnut
                options={{
                  cutout: 80,
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false
                    }
                  }
                }}
                data={grossCostData}
              />
            </Box>
            <Box sx={{ maxWidth: '320px', margin: '1rem auto 0' }}>
              {Object.keys(leaseContractsMediaMetersList[activePeriod].grossCost).map((key) => (
                <Box key={key} display='flex' justifyContent='space-between' mb={1}>
                  <Typography
                    variant='body2'
                    display='flex'
                    justifyContent='flex-start'
                    alignItems='center'>
                    <Box
                      component='span'
                      sx={{
                        width: '9px',
                        height: '9px',
                        borderRadius: '50%',
                        backgroundColor: METERS[key as MetersType].color,
                        mr: 1,
                        display: 'block'
                      }}
                    />
                    {formatMessage({
                      id: METERS[key as MetersType].i18n,
                      defaultMessage: METERS[key as MetersType].defaultMessage
                    })}
                  </Typography>
                  <Typography variant='body2'>
                    {leaseContractsMediaMetersList[activePeriod].grossCost[key as MetersType]}{' '}
                    {activeContract?.paymentCurrency}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                display: 'fex',
                justifyContent: 'center',
                overflowX: 'auto'
              }}>
              {Object.keys(METERS).map((key) => {
                const { i18n, defaultMessage, Icon } = METERS[key as MetersType]
                return (
                  <PaperButton
                    key={key}
                    label={formatMessage({
                      id: i18n,
                      defaultMessage: defaultMessage
                    })}
                    icon={(sx) => <Icon sx={sx} />}
                    isActive={activeType === key}
                    onClick={() => setActiveType(key as MetersType)}
                  />
                )
              })}
            </Box>
          </Box>
          <Box
            sx={{
              maxHeight: '300px',
              display: 'flex',
              justifyContent: 'center'
            }}>
            <Bar
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false
                  },
                  tooltip: {
                    callbacks: {
                      label: function ({ formattedValue }) {
                        return `${formattedValue} ${METERS[activeType].suffix}`
                      }
                    }
                  }
                },
                scales: {
                  y: {
                    beginAtZero: true,
                    ticks: {
                      callback: function (value) {
                        return `${value} ${METERS[activeType].suffix}`
                      }
                    }
                  }
                }
              }}
              data={allMetersData}
            />
          </Box>
        </BackNavigationContent>
      </>
    )
  }

  return null
}

export default AdditionalServicesUtilitiesCalculation
