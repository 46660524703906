import { useMutation } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useAccessToken } from 'services/token/token.hooks'

export const useHelpdeskTicketsAvailability = () => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()

  const { mutateAsync: createAvailability, ...rest } = useMutation({
    mutationFn: ({
      ticketId,
      ...res
    }: {
      ticketId: number
      availableFrom: string
      availableTo: string
    }) => {
      return apiInstance({
        url: `/helpdesk/tickets/${ticketId}/customer-availability/`,
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        },
        data: res
      }).then(({ data }) => data)
    }
  })

  return { createAvailability, ...rest }
}
