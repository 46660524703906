import { useQuery } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useAccessToken } from 'services/token/token.hooks'
import type { LeaseContractResponse } from './useLeaseContracts'
import { useTermsOfUse } from 'services/terms/terms.hooks'

export interface LeaseContractsTenantsResponse {
  id: number
  firstName: string
  lastName: string
}

export const useLeaseContractsTenants = (id: LeaseContractResponse['id']) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  const { data: leaseContractsTenants, ...rest } = useQuery({
    queryKey: ['leaseContractsTenants', id, masquaradeToken],
    queryFn: () =>
      apiInstance<LeaseContractsTenantsResponse[]>({
        url: `/lease/contracts/${id}/tenants/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && hasUserConsented
  })

  return { leaseContractsTenants, ...rest }
}
