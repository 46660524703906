import { FC } from 'react'
import { UserProfile } from 'components/User'
import RestrictedForDesktop from 'components/RestrictedForDesktop'
import DesktopNavigation from 'components/DesktopNavigation'
import { UserProtectedRoute } from 'routes'

const UserPage: FC = () => {
  return (
    <UserProtectedRoute>
      <DesktopNavigation label='Profil' desktopNavigateTo='/' />
      <RestrictedForDesktop redirectTo='/user/account'>
        <UserProfile />
      </RestrictedForDesktop>
    </UserProtectedRoute>
  )
}

export default UserPage
