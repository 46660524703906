import { FC } from 'react'
import { UserProtectedRoute } from 'routes'
import { Box, Card } from '@mui/material'
import { Outlet } from 'react-router-dom'

import Logo from 'static/images/Logo-R4R-Mark-Navy.png'

const PayLayout: FC = () => {
  return (
    <UserProtectedRoute>
      <Box
        sx={{
          minWidth: '100vw',
          minHeight: '100vh',
          backgroundColor: 'primary.main',
          p: 2
        }}
      >
        <Box
          sx={{
            mx: 'auto',
            width: '100%',
            maxWidth: '640px'
          }}
        >
          <Card sx={{ p: 2 }}>
            <Box textAlign='center'>
              <Box
                component='img'
                src={Logo}
                alt='Resi4Rent Logo'
                sx={{
                  maxWidth: '200px',
                  height: 'auto',
                  margin: '0 auto'
                }}
              />
            </Box>
            <Outlet />
          </Card>
        </Box>
      </Box>
    </UserProtectedRoute>
  )
}

export default PayLayout
