import { FC } from 'react'
import { Box, Button, Typography, CircularProgress } from '@mui/material'
import { useParams, Link } from 'react-router-dom'
import { CONTRACT_DOCUMENT_TYPE } from 'services/contracts/contracts.consts'
import AdditionalServicesOnlinePaymentButton from 'components/AdditionalServices/AdditionalServicesOnlinePaymentButton'
import { useInvoice } from 'services/pay/pay.hooks'
import { useIntl } from 'react-intl'

const InvoicePayPage: FC = () => {
  const { documentId, id } = useParams()
  const { formatMessage } = useIntl()

  const {
    invoiceDetails,
    isLoading: invoiceIsLoading,
    error
  } = useInvoice({
    id: Number(documentId),
    leaseContractId: Number(id)
  })

  if (invoiceIsLoading && !!invoiceIsLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  if (error) {
    return (
      <Box>
        <Typography variant='h5' sx={{ textAlign: 'center' }}>
          {formatMessage({
            id: 'paymentScheduleDocumnet.missingDocument',
            defaultMessage: 'Brak dokumentu'
          })}
        </Typography>
        <Box display='flex' justifyContent='space-between' mt={4}>
          <Button component={Link} to='/'>
            Idź do panelu
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <Box p={4}>
      <Box>
        <Box>
          <Typography variant='h4'>
            {formatMessage({
              id: 'paymentScheduleDocumnetTitle.invoice',
              defaultMessage: 'Faktura VAT'
            })}
          </Typography>
          <Typography variant='h5'>
            {invoiceDetails?.number ||
              formatMessage({
                id: 'paymentScheduleDocumnet.awaiting',
                defaultMessage: 'Oczekuje na wystawienie'
              })}
          </Typography>
        </Box>
        <Box mt={4} mb={4}>
          {invoiceDetails?.items.map((item) => (
            <Box
              key={`${invoiceDetails.number}_${item.id}`}
              sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Typography>{item.title}</Typography>
              <Typography fontWeight={600} textAlign='right'>
                {item.amountGross} {invoiceDetails?.currency}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <Typography variant='h5'>
            {formatMessage({
              id: 'paymentScheduleDocumnet.receivable',
              defaultMessage: 'Należność'
            })}
            :
          </Typography>
          <Typography fontWeight={600} mb={2}>
            {invoiceDetails?.totalAmountGross} {invoiceDetails?.currency}
          </Typography>
          <Typography variant='h6'>
            {formatMessage({
              id: 'paymentScheduleDocumnet.paid',
              defaultMessage: 'Opłacono'
            })}
            :
          </Typography>
          <Typography fontWeight={600}>
            {invoiceDetails?.paidAmount} {invoiceDetails?.currency}
          </Typography>
        </Box>
      </Box>
      <Box display='flex' justifyContent='space-between' mt={4}>
        <Button component={Link} to='/'>
          Idź do panelu
        </Button>
        {!invoiceDetails?.isPaid && ['PLN'].includes(invoiceDetails?.currency ?? '') && (
          <AdditionalServicesOnlinePaymentButton
            documentType={CONTRACT_DOCUMENT_TYPE.invoice}
            documentId={Number(documentId)}
          />
        )}
      </Box>
    </Box>
  )
}

export default InvoicePayPage
