import { FC } from 'react'
import { WallList } from 'components/Wall'
import RestrictedForDesktop from 'components/RestrictedForDesktop'
import DesktopNavigation from 'components/DesktopNavigation'
import { UserProtectedRoute } from 'routes'
import { useIntl } from 'react-intl'

const WallPage: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <UserProtectedRoute>
      <DesktopNavigation
        label={formatMessage({
          id: 'wallList.board',
          defaultMessage: 'Tablica'
        })}
        isMainRoute
      />
      <RestrictedForDesktop>
        <WallList />
      </RestrictedForDesktop>
    </UserProtectedRoute>
  )
}

export default WallPage
