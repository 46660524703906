import { FC, useState, useMemo } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { ArrowForward } from '@mui/icons-material'

import { useActiveLeaseContractId } from 'services/contracts/hooks/useActiveLeaseContractId'
import ClipboardButton from 'components/ClipboardButton'
import { useLeaseContractsRentBankAccount } from 'services/contracts/hooks/useLeaseContractsRentBankAccount'
import { useLeaseContractsManagementCompany } from 'services/contracts/hooks/useLeaseContractsManagementCompany'
import ResponsiveFullScreenDialog from 'components/ResponsiveFullScreenDialog'
import { useIntl } from 'react-intl'
import { useLeaseContractsList } from 'services/contracts/hooks/useLeaseContracts'
import { useLeaseContractsDepositBankAccount } from 'services/contracts/hooks/useLeaseContractsDepositBankAccount'

interface AdditionalServicesPaymentDetailsButtonProps {
  amount: string
  paidAmount: string
  type?: 'standard' | 'deposit'
}

const AdditionalServicesPaymentDetailsButton: FC<AdditionalServicesPaymentDetailsButtonProps> = ({
  amount,
  paidAmount,
  type = 'standard'
}) => {
  const { formatMessage } = useIntl()
  const [isModalOpen, setModalOpen] = useState(false)
  const { activeLeaseContractId } = useActiveLeaseContractId()

  const { leaseContractsRentBankAccount } = useLeaseContractsRentBankAccount(activeLeaseContractId)
  const { leaseContractsDepositBankAccount } =
    useLeaseContractsDepositBankAccount(activeLeaseContractId)

  const bankAccount =
    type === 'deposit'
      ? leaseContractsDepositBankAccount?.bankAccount
      : leaseContractsRentBankAccount?.paymentBankAccount

  const { leaseContractsManagementCompany } =
    useLeaseContractsManagementCompany(activeLeaseContractId)

  const { getActiveContract } = useLeaseContractsList()

  const activeContract = useMemo(
    () => getActiveContract(activeLeaseContractId),
    [activeLeaseContractId, getActiveContract]
  )

  const amountToPay = useMemo(() => {
    const amountToPay = parseFloat(amount) - parseFloat(paidAmount)
    return amountToPay.toFixed(2)
  }, [amount, paidAmount])

  return (
    <>
      <Button endIcon={<ArrowForward />} onClick={() => setModalOpen(true)}>
        {formatMessage({
          id: 'additionalServicesPaymentDetailsButton.bankTransferDetails',
          defaultMessage: 'Dane do przelewu'
        })}
      </Button>
      <ResponsiveFullScreenDialog title='' isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <>
          <Box mb={2}>
            <Typography variant='caption'>
              {formatMessage({
                id: 'additionalServicesPaymentDetailsButton.name',
                defaultMessage: 'Nazwa'
              })}
            </Typography>
            <Typography mb={1}>{leaseContractsManagementCompany?.name}</Typography>
            <ClipboardButton data={leaseContractsManagementCompany?.name} />
          </Box>
          <Box mb={2}>
            <Typography variant='caption'>
              {formatMessage({
                id: 'additionalServicesPaymentDetailsButton.address',
                defaultMessage: 'Adres'
              })}
            </Typography>
            <Typography mb={1}>
              {leaseContractsManagementCompany?.addressStreet},{' '}
              {leaseContractsManagementCompany?.addressZipcode},{' '}
              {leaseContractsManagementCompany?.addressCity}
            </Typography>
            <ClipboardButton
              data={`${leaseContractsManagementCompany?.addressStreet}, ${leaseContractsManagementCompany?.addressZipcode}, ${leaseContractsManagementCompany?.addressCity}`}
            />
          </Box>
          <Box mb={2}>
            <Typography variant='caption'>
              {formatMessage({
                id: 'additionalServicesPaymentDetailsButton.amount',
                defaultMessage: 'Kwota'
              })}
            </Typography>
            <Typography mb={1}>
              {amountToPay} {activeContract?.paymentCurrency}
            </Typography>
            <ClipboardButton data={amountToPay} />
          </Box>
          <Box mb={2}>
            <Typography variant='caption'>
              {formatMessage({
                id: 'additionalServicesPaymentDetailsButton.paymentBankAccount',
                defaultMessage: 'Numer rachunku'
              })}
            </Typography>
            <Typography mb={1}>{bankAccount}</Typography>
            <ClipboardButton data={bankAccount} />
          </Box>
        </>
      </ResponsiveFullScreenDialog>
    </>
  )
}

export default AdditionalServicesPaymentDetailsButton
