import { FC } from 'react'
import { Box, TextField } from '@mui/material'
import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import { useLeaseContractsProtocolsSpacesCommentsList } from 'services/contracts/hooks/useLeaseContractsProtocolsSpacesComments'
import MobileBottomFooter from 'components/MobileBottomFooter'
import { useParams, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { LoadingButton } from '@mui/lab'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'

const AdditionalServicesProtocolSpaces: FC = () => {
  const { id, protocolId, spaceId } = useParams()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { formatMessage } = useIntl()

  const { leaseContractsProtocolsSpacesCommentCreate } =
    useLeaseContractsProtocolsSpacesCommentsList(Number(id), Number(protocolId), Number(spaceId))

  const form = useFormik<{
    comment: string
  }>({
    initialValues: {
      comment: ''
    },
    onSubmit: async ({ comment }, { setSubmitting }) => {
      setSubmitting(true)
      try {
        await leaseContractsProtocolsSpacesCommentCreate(comment)
        enqueueSnackbar(
          formatMessage({
            id: 'additionalServicesProtocolSpaces.form.success',
            defaultMessage: 'Komentarz został dodany.'
          }),
          {
            variant: 'success'
          }
        )
      } catch (e) {}
      enqueueSnackbar(
        formatMessage({
          id: 'additionalServicesProtocolSpaces.form.error',
          defaultMessage: 'Nie można dodać komentarza.'
        }),
        {
          variant: 'error'
        }
      )
      setSubmitting(false)
      navigate(`/additional-services/${id}/protocol`)
    }
  })

  return (
    <>
      <BackNavigationHeader
        label={formatMessage({
          id: 'additionalServicesProtocolSpaces.comments',
          defaultMessage: 'Zgłos uwagi'
        })}
      />
      <BackNavigationContent>
        <Box px={2} mt={1}>
          <TextField
            name='comment'
            onChange={form.handleChange}
            value={form.values.comment}
            label={formatMessage({
              id: 'additionalServicesProtocolSpaces.form.comment',
              defaultMessage: 'Opis'
            })}
            sx={{ mb: 4 }}
            fullWidth
            rows={2}
            multiline
            disabled={form.isSubmitting}
          />
        </Box>
        <MobileBottomFooter>
          <LoadingButton
            loading={form.isSubmitting}
            fullWidth
            sx={{ mt: 2 }}
            variant='contained'
            onClick={form.submitForm}
          >
            {formatMessage({
              id: 'additionalServicesProtocolSpaces.form.submit',
              defaultMessage: 'Zgłoś'
            })}
          </LoadingButton>
        </MobileBottomFooter>
      </BackNavigationContent>
    </>
  )
}

export default AdditionalServicesProtocolSpaces
