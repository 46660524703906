import { FC, useState } from 'react'
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  InputAdornment,
  FormHelperText
} from '@mui/material'
import type { TextFieldProps } from '@mui/material/TextField'
import { VisibilityOff, Visibility } from '@mui/icons-material'

const PasswordField: FC<TextFieldProps> = ({
  label,
  fullWidth,
  sx,
  helperText,
  error,
  ...props
}) => {
  const [isShowPassword, setShowPassword] = useState(false)
  return (
    <FormControl fullWidth={fullWidth} sx={sx} variant='outlined' error={error}>
      <InputLabel error={error}>{label}</InputLabel>
      <OutlinedInput
        {...(props as any)}
        error={error}
        type={isShowPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton onClick={() => setShowPassword((state) => !state)} edge='end'>
              {isShowPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={label}
      />
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default PasswordField
