import { FC } from 'react'
import { AdditionalServicesLease } from 'components/AdditionalServices'
import DesktopNavigation from 'components/DesktopNavigation'
import { UserProtectedRoute } from 'routes'
import RestrictedForDesktop from 'components/RestrictedForDesktop'
import { useIntl } from 'react-intl'

const AdditionalServicesLeaseList: FC = () => {
  const { formatMessage } = useIntl()
  return (
    <UserProtectedRoute>
      <DesktopNavigation
        label={formatMessage({
          id: 'additionalServicesLease.contracts',
          defaultMessage: 'Umowy'
        })}
        desktopNavigateTo='/'
      />
      <RestrictedForDesktop>
        <AdditionalServicesLease />
      </RestrictedForDesktop>
    </UserProtectedRoute>
  )
}

export default AdditionalServicesLeaseList
