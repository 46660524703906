import { FC } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import AppLayout from 'layouts/App'
import AuthLayout from 'layouts/Auth'
import LimitedMobileNavigationLayout from 'layouts/LimitedMobileNavigation'
import PayLayout from 'layouts/Pay/PayLayout'
import UserLayout from 'layouts/User'
// /auth
import LoginPage from 'pages/auth/Login.page'
import ResetPasswordPage from 'pages/auth/ResetPassword.page'
import SetNewPasswordPage from 'pages/auth/SetNewPassword.page'
import SignUpPage from 'pages/auth/SignUp.page'
import HomePage from 'pages/Home.page'
// /user
import {
  UserAccountPage,
  UserConstensDetailsPage,
  UserDetailsPage,
  UserMasquaradePage,
  UserPage,
  UserPersonalDataConfirmationPage,
  UserPersonalDataPage
} from 'pages/user'
// /additional-services
import { AdditionalServicesMenu } from 'components/AdditionalServices'
import {
  AdditionalServicesAttachmentsPage,
  AdditionalServicesDocumentsPage,
  AdditionalServicesInformationPage,
  AdditionalServicesLeaseList,
  AdditionalServicesMediaPage,
  AdditionalServicesPage,
  AdditionalServicesPaymentSchedulePage,
  AdditionalServicesProtocolAcceptPage,
  AdditionalServicesProtocolPage,
  AdditionalServicesProtocolSpacesPage,
  AdditionalServicesTenantsPage
} from 'pages/additionalServices'
// /faults
import { DefectsList } from 'components/Defects'
import { FaultMessagePage, FaultsPage, FaultsReportPage, FaultsSinglePage } from 'pages/faults'
// /board
import { WallList } from 'components/Wall'
import { BoardsMessagesPage, BoardsNewPost, BoardsPostPage, WallPage } from 'pages/board'
// /discounts
import { DiscountsList } from 'components/Discounts'
import { DiscountPage, DiscountsPage } from 'pages/discounts'
// /help
import { HelpList } from 'components/Help'
import {
  HelpInvoicesPage,
  HelpLocationGuidesPage,
  HelpPage,
  HelpTechnicalPackagesPage
} from 'pages/help'
// /offer
import { OfferPage } from 'pages/offer'
import OfferDetailsPage from 'pages/offer/OfferDetails.page'
import { OfferDecline, OfferMessages, OfferPriceDetails, OfferMessage } from 'components/Offer'
// /notifications
import { NotificationsList } from 'components/Notifications'
import { NotificationPage, NotificationsPage } from 'pages/notifications'
// pay
import AdditionalServicesAnnexesPage from 'pages/additionalServices/AdditionalServicesAnnexes.page'
import AdditionalServicesBookingAgreementPage from 'pages/additionalServices/AdditionalServicesBookingAgreement.page'
import { DebitnotePayPage, DepositPayPage, IntrestnotePayPage, InvoicePayPage } from 'pages/pay'

import { useEffect, useState } from 'react'
import { SafeArea } from 'capacitor-plugin-safe-area'
import OfferListView from 'components/Offer/OfferListView'
import OfferAccept from 'components/Offer/OfferAccept'

const Router: FC = () => {
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      for (const [key, value] of Object.entries(insets)) {
        document.documentElement.style.setProperty(`--safe-area-inset-${key}`, `${value}px`)
      }
      setIsReady(true)
    })
  }, [])

  if (!isReady) {
    return null
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<AppLayout />}>
          <Route index element={<HomePage />} />
          <Route path='pay/' element={<PayLayout />}>
            <Route path='invoice/:id/:documentId' element={<InvoicePayPage />} />
            <Route path='debitnote/:id/:documentId' element={<DebitnotePayPage />} />
            <Route path='intrestnote/:id/:documentId' element={<IntrestnotePayPage />} />
            <Route path='pay/depositpay/:id' element={<DepositPayPage />} />
          </Route>
          <Route path='auth/' element={<AuthLayout />}>
            <Route path='sign-in' element={<LoginPage />} />
            <Route path='sign-up' element={<SignUpPage />} />
            <Route path='reset-password' element={<ResetPasswordPage />} />
            <Route path='new-password/:token' element={<SetNewPasswordPage />} />
          </Route>
          <Route path='user/' element={<UserLayout />}>
            <Route index element={<UserPage />} />
            <Route path='account' element={<UserAccountPage />} />
            <Route path='personal-data' element={<UserPersonalDataPage />} />
            <Route
              path='personal-data-confirmation-form'
              element={<UserPersonalDataConfirmationPage />}
            />
            <Route path='details' element={<UserDetailsPage />} />
            <Route path='constens' element={<UserConstensDetailsPage />} />
            <Route path='masquarade' element={<UserMasquaradePage />} />
          </Route>
          <Route
            path='additional-services/'
            element={
              <LimitedMobileNavigationLayout
                navigationComponent={<AdditionalServicesMenu />}
                restrictedPaths={['/additional-services']}
              />
            }>
            <Route index element={<AdditionalServicesLeaseList />} />

            <Route path=':id/'>
              <Route index element={<AdditionalServicesPage />} />
              <Route path='payment-schedule' element={<AdditionalServicesPaymentSchedulePage />} />
              <Route path='annexes' element={<AdditionalServicesAnnexesPage />} />
              <Route path='attachments' element={<AdditionalServicesAttachmentsPage />} />
              <Route path='tenants' element={<AdditionalServicesTenantsPage />} />
              <Route path='documents' element={<AdditionalServicesDocumentsPage />} />
              <Route path='information' element={<AdditionalServicesInformationPage />} />
              <Route path='media' element={<AdditionalServicesMediaPage />} />
              <Route path='protocol' element={<AdditionalServicesProtocolPage />} />
              <Route
                path='protocol/:protocolId/accept'
                element={<AdditionalServicesProtocolAcceptPage />}
              />
              <Route
                path='protocol/:protocolId/space/:spaceId/comment'
                element={<AdditionalServicesProtocolSpacesPage />}
              />
              <Route
                path='booking-agreement'
                element={<AdditionalServicesBookingAgreementPage />}
              />
            </Route>
          </Route>
          <Route
            path='faults/'
            element={
              <LimitedMobileNavigationLayout
                navigationComponent={<DefectsList />}
                restrictedPaths={['/faults']}
              />
            }>
            <Route index element={<FaultsPage />} />
            <Route path='report/:id' element={<FaultsSinglePage />} />
            <Route path='report/:id/message' element={<FaultMessagePage />} />
            <Route path='report/add' element={<FaultsReportPage />} />
          </Route>
          <Route
            path='notifications/'
            element={
              <LimitedMobileNavigationLayout
                navigationComponent={<NotificationsList />}
                restrictedPaths={['/notifications']}
              />
            }>
            <Route index element={<NotificationsPage />} />
            <Route path=':id' element={<NotificationPage />} />
          </Route>
          <Route
            path='board/'
            element={
              <LimitedMobileNavigationLayout
                navigationComponent={<WallList />}
                restrictedPaths={['/board']}
              />
            }>
            <Route index element={<WallPage />} />
            <Route path='new-event' element={<BoardsNewPost />} />
            <Route path='messages/:id' element={<BoardsMessagesPage />} />
            <Route path=':boardId/:id' element={<BoardsPostPage />} />
          </Route>
          <Route
            path='discounts/'
            element={
              <LimitedMobileNavigationLayout
                navigationComponent={<DiscountsList />}
                restrictedPaths={['/discounts']}
              />
            }>
            <Route index element={<DiscountsPage />} />
            <Route path=':id' element={<DiscountPage />} />
          </Route>
          <Route
            path='help/'
            element={
              <LimitedMobileNavigationLayout
                navigationComponent={<HelpList />}
                restrictedPaths={['/help']}
              />
            }>
            <Route index element={<HelpPage />} />
            <Route path=':id' element={<HelpPage />} />
            <Route path='location-guides' element={<HelpLocationGuidesPage />} />
            <Route path='technical-packages' element={<HelpTechnicalPackagesPage />} />
            <Route path='invoices' element={<HelpInvoicesPage />} />
          </Route>
          <Route
            path='offer/'
            element={
              <LimitedMobileNavigationLayout
                navigationComponent={<OfferListView />}
                restrictedPaths={['/offer']}
              />
            }>
            <Route index element={<OfferPage />} />
            <Route path=':id' element={<OfferDetailsPage />} />
            <Route path=':id/price-details' element={<OfferPriceDetails />} />
            <Route path=':id/messages' element={<OfferMessages />} />
            <Route path=':id/message' element={<OfferMessage />} />
            <Route path=':id/decline' element={<OfferDecline />} />
            <Route path=':id/accept' element={<OfferAccept />} />
          </Route>
          <Route path='*' element={<Navigate to='/' replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Router
