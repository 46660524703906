import { FC } from 'react'
import { NotificationsNoneOutlined } from '@mui/icons-material'
import { IconButton, useTheme, useMediaQuery, Badge } from '@mui/material'
import { Link } from 'react-router-dom'

import { useNotificationsList } from 'services/notifications/notifications.hook'

interface NotificationIndicatorProps {}

const NotificationIndicator: FC<NotificationIndicatorProps> = () => {
  const { hasUnread } = useNotificationsList()

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  if (hasUnread) {
    return (
      <IconButton component={Link} to='/notifications' size={isDesktop ? 'large' : 'medium'}>
        <Badge color='secondary' variant='dot'>
          <NotificationsNoneOutlined />
        </Badge>
      </IconButton>
    )
  }

  return (
    <IconButton size={isDesktop ? 'large' : 'medium'} component={Link} to='/notifications'>
      <NotificationsNoneOutlined />
    </IconButton>
  )
}

export default NotificationIndicator
