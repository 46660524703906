import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useSnackbar } from 'notistack'

import { useAccessToken } from 'services/token/token.hooks'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useTermsOfUse } from 'services/terms/terms.hooks'

interface UsersProfileAddressesResponse {
  street: string
  city: string
  zipcode: string
  countryId: number
}

const usersProfileAddressesInitial = {
  street: '',
  city: '',
  zipcode: '',
  countryId: -1
}

export const useUsersProfilAddressOfResidence = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  const { data: usersProfilAddressOfResidence = usersProfileAddressesInitial } = useQuery({
    queryKey: ['usersAddressOfResidence', masquaradeToken],
    queryFn: () =>
      apiInstance<UsersProfileAddressesResponse>({
        url: '/users/profile/addresses/address_of_residence/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && hasUserConsented
  })

  const usersProfilAddressOfResidenceUpdate = useMutation({
    mutationFn: (address: Partial<UsersProfileAddressesResponse>) =>
      apiInstance<UsersProfileAddressesResponse>({
        url: '/users/profile/addresses/address_of_residence/',
        method: 'PUT',
        data: address,
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    onSuccess: (data) => {
      queryClient.setQueryData(['usersAddressOfResidence', masquaradeToken], data)
      enqueueSnackbar('Wniosek został złożony poprawnie.', {
        variant: 'success'
      })
    },
    onError: () => {
      enqueueSnackbar('Błąd. Spróbuj później.', {
        variant: 'error'
      })
    }
  })

  return {
    usersProfilAddressOfResidence,
    usersProfilAddressOfResidenceUpdate
  }
}

export const useUsersProfilBillingAddress = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  const { data: usersProfilBillingAddress = usersProfileAddressesInitial } = useQuery({
    queryKey: ['usersBillingAddress', masquaradeToken],
    queryFn: () =>
      apiInstance<UsersProfileAddressesResponse>({
        url: '/users/profile/addresses/billing_address/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && hasUserConsented
  })

  const usersProfilBillingAddressUpdate = useMutation({
    mutationFn: (address: Partial<UsersProfileAddressesResponse>) =>
      apiInstance<UsersProfileAddressesResponse>({
        url: '/users/profile/addresses/billing_address/',
        method: 'PUT',
        data: address,
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    onSuccess: (data) => {
      queryClient.setQueryData(['usersBillingAddress', masquaradeToken], data)
      enqueueSnackbar('Wniosek został złożony poprawnie.', {
        variant: 'success'
      })
    },
    onError: () => {
      enqueueSnackbar('Błąd. Spróbuj później.', {
        variant: 'error'
      })
    }
  })

  return {
    usersProfilBillingAddress,
    usersProfilBillingAddressUpdate
  }
}

export const useUsersProfilDeliverAddressDuring = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  const { data: usersProfilDeliverAddressDuring = usersProfileAddressesInitial } = useQuery({
    queryKey: ['usersDeliverAddressDuring', masquaradeToken],
    queryFn: () =>
      apiInstance<UsersProfileAddressesResponse>({
        url: '/users/profile/addresses/deliver_address_during/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && hasUserConsented
  })

  const usersProfilDeliverAddressDuringUpdate = useMutation({
    mutationFn: (address: Partial<UsersProfileAddressesResponse>) =>
      apiInstance<UsersProfileAddressesResponse>({
        url: '/users/profile/addresses/deliver_address_during/',
        method: 'PUT',
        data: address,
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    onSuccess: (data) => {
      queryClient.setQueryData(['usersDeliverAddressDuring', masquaradeToken], data)
      enqueueSnackbar('Wniosek został złożony poprawnie.', {
        variant: 'success'
      })
    },
    onError: () => {
      enqueueSnackbar('Błąd. Spróbuj później.', {
        variant: 'error'
      })
    }
  })

  return {
    usersProfilDeliverAddressDuring,
    usersProfilDeliverAddressDuringUpdate
  }
}

export const useUsersProfilDeliverAddressAfter = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  const { data: usersProfilDeliverAddressAfter = usersProfileAddressesInitial } = useQuery({
    queryKey: ['usersDeliverAddressAfter', masquaradeToken],
    queryFn: () =>
      apiInstance<UsersProfileAddressesResponse>({
        url: '/users/profile/addresses/deliver_address_after/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && hasUserConsented
  })

  const usersProfilDeliverAddressAfterUpdate = useMutation({
    mutationFn: (address: Partial<UsersProfileAddressesResponse>) =>
      apiInstance<UsersProfileAddressesResponse>({
        url: '/users/profile/addresses/deliver_address_after/',
        method: 'PUT',
        data: address,
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    onSuccess: (data) => {
      queryClient.setQueryData(['usersDeliverAddressAfter', masquaradeToken], data)
      enqueueSnackbar('Wniosek został złożony poprawnie.', {
        variant: 'success'
      })
    },
    onError: () => {
      enqueueSnackbar('Błąd. Spróbuj później.', {
        variant: 'error'
      })
    }
  })

  return {
    usersProfilDeliverAddressAfter,
    usersProfilDeliverAddressAfterUpdate
  }
}
