import env from 'consts/env'
import * as Sentry from '@sentry/capacitor'

export default function useSentry() {
  const captureException = (error: any, info: any) => {
    if (env.IS_PRODUCTION && !!env.SENTRY_DSN) {
      return Sentry.captureException(error, { extra: info })
    }

    return console.error('Error: ', { error, info })
  }

  return { captureException }
}
