import { apiInstance } from 'services/api'
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { useAccessToken } from 'services/token/token.hooks'
import { useTermsOfUse } from 'services/terms/terms.hooks'

export const useMasquarade = () => {
  const queryClient = useQueryClient()
  const { accessToken } = useAccessToken()
  const { hasUserConsented } = useTermsOfUse()

  const {
    data: { canUseMasquerade } = { canUseMasquerade: false },
    isLoading,
    isFetching
  } = useQuery({
    queryKey: ['masquaradeCheck'],
    queryFn: () =>
      apiInstance<{ canUseMasquerade: boolean }>({
        url: '/users/masquarade/check/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && hasUserConsented
  })

  const {
    data: masquaradeUsersList = [],
    isLoading: isMasquaradeUsersListLoading,
    isFetching: isMasquaradeUsersListFetching
  } = useQuery({
    refetchInterval: 300000,
    cacheTime: 0,
    retry: false,
    queryFn: () =>
      apiInstance<{ results: { email: string }[] }>({
        url: '/users/masquarade/users/list/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`
        }
      }).then(({ data }) => data.results),
    enabled: !!accessToken && hasUserConsented
  })

  const { mutateAsync: masquaradeLogin, isLoading: isMasquaradeUserLoading } = useMutation({
    mutationKey: ['masquaradeAuth'],
    mutationFn: ({ email, password }: { email: string; password: string }) =>
      apiInstance<{
        user: {
          firstName: string
          lastName: string
          email: string
          phone: string
        }
        maskToken: string
      }>({
        url: '/users/masquarade/login/',
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`
        },
        data: {
          email,
          password
        }
      }),
    onSuccess: ({ data }) => {
      queryClient.setQueryData(['masquaradeUser'], data.user)
      queryClient.setQueryData(['masquaradeToken'], data.maskToken)
    }
  })

  return {
    canUseMasquerade,
    isCanMasqueradeLoading: isLoading || isFetching,
    isMasquaradeUserLoading,
    masquaradeUsersList,
    isMasquaradeUsersListLoading: isMasquaradeUsersListLoading || isMasquaradeUsersListFetching,
    masquaradeLogin
  }
}

export const useMasquaradeToken = () => {
  const {
    data: masquaradeToken,
    refetch,
    ...rest
  } = useQuery({
    queryKey: ['masquaradeToken'],
    queryFn: () => null,
    enabled: false
  })

  const clearMasquaradeToken = async () => {
    await refetch()
  }

  return {
    masquaradeToken,
    clearMasquaradeToken,
    isMasquarade: !!masquaradeToken,
    ...rest
  }
}

export const useMasquaradeUser = () => {
  const {
    data: masquaradeUser = {
      firstName: '',
      lastName: '',
      email: '',
      phone: ''
    },
    refetch,
    ...rest
  } = useQuery({
    queryKey: ['masquaradeUser'],
    queryFn: () => ({
      firstName: '',
      lastName: '',
      email: '',
      phone: ''
    }),
    enabled: false
  })

  const clearMasquaradeUser = async () => {
    await refetch()
  }

  return {
    masquaradeUser,
    clearMasquaradeUser,
    isMasquarade: !!masquaradeUser.email.length,
    ...rest
  }
}
