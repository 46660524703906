import { FC, forwardRef } from 'react'
import {
  Dialog,
  AppBar,
  useMediaQuery,
  Toolbar,
  IconButton,
  useTheme,
  Typography,
  Box
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import Slide from '@mui/material/Slide'
import { ArrowBackIosNew } from '@mui/icons-material'

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

interface ResponsiveFullScreenDialogProps {
  children: React.ReactElement
  isOpen: boolean
  onClose: () => unknown
  title?: string
}

const ResponsiveFullScreenDialog: FC<ResponsiveFullScreenDialogProps> = ({
  isOpen,
  children,
  onClose,
  title = ''
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Dialog
      fullScreen={isMobile}
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
      fullWidth
      maxWidth='md'
    >
      {isMobile && (
        <AppBar sx={{ position: 'relative' }} color='primary'>
          <Toolbar>
            <IconButton edge='start' color='inherit' onClick={onClose}>
              <ArrowBackIosNew />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
      )}
      <Box p={3}>{children}</Box>
    </Dialog>
  )
}

export default ResponsiveFullScreenDialog
