import { FC, useState } from 'react'
import { Box, Button, Typography, Modal } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useIntl } from 'react-intl'
import { useUserDeactivateAccount } from 'services/profile/profile.hooks'

const DeactivateAccount: FC = () => {
  const [isOpen, setOpen] = useState(false)
  const { formatMessage } = useIntl()
  const { deactivateAccount } = useUserDeactivateAccount()

  return (
    <>
      <Button fullWidth variant='contained' color='error' onClick={() => setOpen(true)}>
        {formatMessage({
          id: 'userAccount.deactivateAccount',
          defaultMessage: 'Deactivate account'
        })}
      </Button>
      <Modal
        open={isOpen}
        onClose={() => (deactivateAccount.isLoading ? null : setOpen(false))}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: 320,
            width: '100%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4
          }}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {formatMessage({
              id: 'userAccount.deactivateAccount',
              defaultMessage: 'Deactivate account'
            })}
          </Typography>
          <Typography id='modal-modal-description' sx={{ mt: 2 }}>
            {formatMessage({
              id: 'userAccount.deactivateAccountMessage',
              defaultMessage:
                'Are you sure you want to deactivate your account? Deactivating your account will result in losing access to the service.'
            })}
          </Typography>
          <Box mt={4}>
            <LoadingButton
              disabled={deactivateAccount.isLoading}
              loading={deactivateAccount.isLoading}
              fullWidth
              variant='contained'
              color='error'
              sx={{ mt: 2 }}
              onClick={() => {
                deactivateAccount.mutateAsync()
              }}>
              {formatMessage({
                id: 'userAccount.deactivateAccount',
                defaultMessage: 'Deactivate account'
              })}
            </LoadingButton>
            <Button
              disabled={deactivateAccount.isLoading}
              fullWidth
              variant='outlined'
              color='primary'
              sx={{ mt: 2 }}
              onClick={() => setOpen(false)}>
              {formatMessage({
                id: 'userAccount.cancel',
                defaultMessage: 'Cancel'
              })}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default DeactivateAccount
