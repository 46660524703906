import { useQuery, useMutation } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useAccessToken } from 'services/token/token.hooks'
import { useTermsOfUse } from 'services/terms/terms.hooks'
import { NOTIFICATION_STATUS_TYPE, NotificationStatusTypes } from './notifications.consts'

export interface NotificationsListResponse {
  id: number
  recipient: number
  title: string
  text: string
  status: NotificationStatusTypes
  attachment: string
  photos: {
    id: number
    url: string
    contentType: number
    objectId: number
    url240x240: string
    name: string
    nameLocal: string
    planCategory: number
    file: string
    url840490: string
    downloadUrl: string
    isplan: true
    sortOrder: number
  }[]
}

export interface MessagesListResponse {
  createDate: string
  id: number
  renderedEmail: string
  subject: string
}

export const useNotificationsList = () => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  const {
    data: notificationsList = [],
    refetch,
    ...rest
  } = useQuery({
    queryKey: ['notificationsList', masquaradeToken],
    cacheTime: 3600000,
    queryFn: () =>
      apiInstance<NotificationsListResponse[]>({
        url: '/notifications/notifications/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && hasUserConsented
  })

  const { data: messagesList = [] } = useQuery({
    queryKey: ['messagesList', masquaradeToken],
    refetchInterval: 30000,
    cacheTime: 0,
    queryFn: () =>
      apiInstance<MessagesListResponse[]>({
        url: '/messages/messages/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && hasUserConsented
  })

  const { mutateAsync: mutateAsyncNotificationUpdate } = useMutation({
    cacheTime: 0,
    mutationFn: ({ id, ...notification }: Partial<NotificationsListResponse>) =>
      apiInstance<NotificationsListResponse>({
        url: `/notifications/notifications/${id}/`,
        method: 'PATCH',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        },
        data: {
          ...notification
        }
      }).then(({ data }) => {
        refetch()
        return data
      })
  })

  const hasUnread = !!notificationsList.filter(
    ({ status }) => status === NOTIFICATION_STATUS_TYPE.unread
  ).length

  const getNotification = (notificationId: number) =>
    notificationsList.find(({ id }) => notificationId === id)

  return {
    messagesList,
    notificationsList,
    hasUnread,
    getNotification,
    mutateAsyncNotificationUpdate,
    ...rest
  }
}
