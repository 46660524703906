import { useMutation } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useSnackbar } from 'notistack'
import { useAccessToken } from 'services/token/token.hooks'
import { useMasquaradeUser, useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useIntl } from 'react-intl'

export const useUserDeactivateAccount = () => {
  const { accessToken, clearUserData } = useAccessToken()
  const { masquaradeToken, clearMasquaradeToken } = useMasquaradeToken()
  const { clearMasquaradeUser } = useMasquaradeUser()
  const { enqueueSnackbar } = useSnackbar()
  const { formatMessage } = useIntl()

  const deactivateAccount = useMutation({
    mutationFn: () =>
      apiInstance({
        url: '/users/profile/deactivate/',
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    onSuccess: () => {
      enqueueSnackbar(
        formatMessage({
          id: 'userAccount.deactivateAccountSuccess',
          defaultMessage: 'Your account has been deactivated successfully.'
        }),
        {
          variant: 'success'
        }
      )
      clearUserData()
      clearMasquaradeToken()
      clearMasquaradeUser()
    },
    onError: () => {
      enqueueSnackbar(
        formatMessage({
          id: 'userAccount.deactivateAccountError',
          defaultMessage: 'An error occurred while deactivating your account.'
        }),
        {
          variant: 'error'
        }
      )
    }
  })

  return { deactivateAccount }
}
