import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useAccessToken } from 'services/token/token.hooks'
import type { LeaseContractResponse } from './useLeaseContracts'
import { useTermsOfUse } from 'services/terms/terms.hooks'

export interface LeaseContractsProtocolResponseLegacy {
  id: number
  intercom: number | null
  intercomSymbol: string | null
  frontDoorKeys: number | null
  frontDoorKeysSymbol: string | null
  storageKeys: number | null
  storageBoxKey: boolean
  carliftParkingKey: boolean
  garageDoorKey: boolean
  acceptance: boolean
  mediaMeter: {
    id: number
    dateMetersFrom: string
    wattHourMeter: number
    heatingMeter: number
    hotWaterMeter: number
    coldWaterMeter: number
    airConditioningMeter: number
    gasMeter: number
  }
  flat: {
    id: number
    area: number
    floor: number
    flatIdNumber: string
    numberOfRooms: number
    flatType: number
    flatTypeExtra: number
  }
}

export interface LeaseContractsProtocolResponse {
  id: number

  intercom: number | null
  intercomSymbol: string | null
  frontDoorKeys: number | null
  frontDoorKeysSymbol: string | null
  storageKeys: number | null
  storageBoxKey: boolean

  deliveryFrontDoorKeys: number
  deliveryStorageKeys: number
  deliveryStorageBoxKey: boolean
  deliveryCarliftParkingKey: boolean
  deliveryGarageDoorKey: boolean
  delivery: boolean
  deliveryComment: string
  deliveryTenantComment: string
  deliveryDate: string
  deliveryMediaMeter: {
    id: number
    dateMetersFrom: string
    wattHourMeter: number
    heatingMeter: number
    hotWaterMeter: number
    coldWaterMeter: number
    airConditioningMeter: number
    gasMeter: number
  }
  flat: {
    id: number
    area: number
    floor: number
    flatIdNumber: string
    numberOfRooms: number
    flatType: number
    flatTypeExtra: number
  }
}

export const useLeaseContractsProtocolsList = (contractId: LeaseContractResponse['id']) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  const { data: leaseContractsList = [] } = useQuery({
    queryKey: ['leaseContractsProtocolsList', contractId, masquaradeToken],
    queryFn: () =>
      apiInstance<LeaseContractsProtocolResponseLegacy[]>({
        url: `/lease/contracts/${contractId}/delivery/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && hasUserConsented
  })

  return { leaseContractsList }
}

export const useLeaseContractsProtocol = (
  contractId: LeaseContractResponse['id'],
  protocolId?: LeaseContractsProtocolResponse['id']
) => {
  const { accessToken } = useAccessToken()
  const queryClient = useQueryClient()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  const { data: leaseContract } = useQuery({
    queryKey: ['leaseContractProtocol', contractId, protocolId, masquaradeToken],
    queryFn: () =>
      apiInstance<LeaseContractsProtocolResponse>({
        url: `/lease/contracts/${contractId}/delivery/${protocolId}/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && !!protocolId && hasUserConsented
  })

  const { mutateAsync: leaseContractsProtocolAccept } = useMutation({
    mutationFn: () =>
      apiInstance<LeaseContractsProtocolResponse>({
        url: `/lease/contracts/${contractId}/delivery/${protocolId}/accept/`,
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    onSuccess: (data) => {
      queryClient.setQueryData(
        ['leaseContractProtocol', contractId, protocolId, masquaradeToken],
        data
      )
    }
  })

  const { mutateAsync: leaseContractsProtocolAcceptWithComment } = useMutation({
    mutationFn: (comment: string) =>
      apiInstance<LeaseContractsProtocolResponse>({
        url: `/lease/contracts/${contractId}/delivery/${protocolId}/accept/with-comment/`,
        method: 'POST',
        data: {
          comment
        },
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    onSuccess: (data) => {
      queryClient.setQueryData(
        ['leaseContractProtocol', contractId, protocolId, masquaradeToken],
        data
      )
    }
  })

  return {
    leaseContract,
    leaseContractsProtocolAccept,
    leaseContractsProtocolAcceptWithComment
  }
}
