import { FC } from 'react'

import ResetPassword from 'components/ResetPassword'
import { UnauthorizedRoute } from 'routes'

const ResetPasswordPage: FC = () => {
  return (
    <UnauthorizedRoute>
      <ResetPassword />
    </UnauthorizedRoute>
  )
}

export default ResetPasswordPage
