import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import DesktopNavigation from 'components/DesktopNavigation'
import { useIntl } from 'react-intl'
import { useFormik } from 'formik'
import { useOfferRejectionReasons } from 'services/offer/hooks/useOfferRejectionReasons'
import { useLanguage } from 'services/i18n/i18n.hooks'
import { useOffer } from 'services/offer/hooks'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import Section from 'components/Section'

const OfferDecline = () => {
  const { id } = useParams()
  const { formatMessage } = useIntl()
  const { language } = useLanguage()
  const { data: rejectionReasons = [], isFetching, isLoading } = useOfferRejectionReasons()
  const { offerDecline } = useOffer()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: { reason: '', comment: '' },
    onSubmit: async ({ reason, comment }, { setSubmitting }) => {
      setSubmitting(true)

      await offerDecline({
        reason: reason,
        comment: comment,
        offerId: id
      })

      navigate(`/`)
    }
  })

  if (isFetching || isLoading) {
    return (
      <Section sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress />
      </Section>
    )
  }

  return (
    <>
      <DesktopNavigation
        label={formatMessage({
          id: 'offer.decline',
          defaultMessage: 'Odrzuć'
        })}
      />
      <BackNavigationHeader
        label={formatMessage({
          id: 'offer.decline',
          defaultMessage: 'Odrzuć'
        })}
      />
      <BackNavigationContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          height: '100vh'
        }}>
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Typography sx={{ fontWeight: 600, fontSize: '26px', lineHeight: '35px' }}>
              {formatMessage({
                id: 'offer.declineTitle"',
                defaultMessage: 'Przykro nam, że nasza oferta nie spełniła Twoich oczekiwań.'
              })}
            </Typography>
            <Typography sx={{ fontWeight: 500, fontSize: '16px', lineHeight: '28px' }}>
              {formatMessage({
                id: 'offer.declineSubtitle',
                defaultMessage: 'Napisz nam, dlaczego ją odrzuciłeś:'
              })}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '22px', marginTop: '24px' }}>
            <FormControl>
              <InputLabel id='reject-label'>
                {formatMessage({
                  id: 'offer.declineReason',
                  defaultMessage: 'Wybierz powód*'
                })}
              </InputLabel>
              <Select
                required
                name='reason'
                onChange={formik.handleChange}
                value={formik.values.reason}
                labelId='reject-label'
                id='reject-select'
                variant='outlined'
                sx={{ width: '100%' }}>
                {rejectionReasons.map((option: { id: number; name: string; nameLocal: string }) => (
                  <MenuItem key={option.id} value={option.name}>
                    {language === 'pl' ? option.nameLocal : option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              required
              name='comment'
              onChange={formik.handleChange}
              value={formik.values.comment}
              label={formatMessage({
                id: 'offer.declineComment',
                defaultMessage: 'Komentarz'
              })}
              sx={{
                width: '100%',
                minHeight: '272px',
                borderRadius: '10px',
                fontSize: '16px'
              }}
              fullWidth
              rows={10}
              multiline
            />
          </Box>
        </Box>
        <LoadingButton
          disabled={!formik.values.reason || !formik.values.comment}
          variant='contained'
          sx={{ width: '315px', marginTop: '20px' }}
          loading={formik.isSubmitting}
          onClick={formik.submitForm}>
          {formatMessage({
            id: 'offer.decline',
            defaultMessage: 'Odrzuć'
          })}
        </LoadingButton>
      </BackNavigationContent>
    </>
  )
}

export default OfferDecline
