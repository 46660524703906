import { useQuery } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useAccessToken } from 'services/token/token.hooks'
import type { LeaseContractResponse } from 'services/contracts/hooks/useLeaseContracts'
import { useTermsOfUse } from 'services/terms/terms.hooks'

export interface LeaseContractsRentBankAccountResponse {
  id: number
  paymentBankAccount: string
}

export const useLeaseContractsRentBankAccount = (id?: LeaseContractResponse['id']) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  const { data: leaseContractsRentBankAccount, ...rest } = useQuery({
    queryKey: ['leaseContractsRentBankAccount', id, masquaradeToken],
    queryFn: () =>
      apiInstance<LeaseContractsRentBankAccountResponse>({
        url: `/lease/contracts/${id}/rent-bank-account/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && !!id && hasUserConsented
  })

  return { leaseContractsRentBankAccount, ...rest }
}
