import { CircularProgress } from '@mui/material'
import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { useOfferListOffer } from 'services/offer/hooks/useOfferList'
import Section from 'components/Section'
import 'react-image-gallery/styles/css/image-gallery.css'
import DesktopNavigation from 'components/DesktopNavigation'
import OfferDetailsView from './OfferDetailsView'
import { UserProtectedRoute } from 'routes'

const OfferDetails: FC = () => {
  const { id } = useParams()
  const { data: offerDetail = [], isFetching, isLoading } = useOfferListOffer(id)

  if (isFetching || isLoading) {
    return (
      <Section
        sx={{
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <CircularProgress />
      </Section>
    )
  }

  return (
    <>
      <BackNavigationHeader
        navigateTo='/offer'
        desktopNavigateTo='/offer'
        label={offerDetail.flat.address}
        sx={{
          backgroundColor: 'rgba(29, 34, 110, 0.7)',
          color: '#fff',
          '.MuiIconButton-root': {
            color: '#fff'
          },
          zIndex: 1000
        }}
      />
      <DesktopNavigation label={offerDetail.flat.address} />
      <UserProtectedRoute>
        <BackNavigationContent sx={{ position: 'relative', paddingBottom: '20px' }}>
          <OfferDetailsView />
        </BackNavigationContent>
      </UserProtectedRoute>
    </>
  )
}

export default OfferDetails
