import { FC, ReactNode, useState } from 'react'
import { Collapse, Box, Typography, IconButton } from '@mui/material'
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'

interface CollapseSectionProps {
  children: ReactNode
  title: ReactNode
  isDefaultOpen?: boolean
}

const CollapseSection: FC<CollapseSectionProps> = ({ children, title, isDefaultOpen = false }) => {
  const [isOpen, setOpen] = useState(isDefaultOpen)
  return (
    <Box py={2}>
      <Typography
        fontWeight={600}
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        {title}{' '}
        <IconButton size='small' onClick={() => setOpen((state) => !state)}>
          {isOpen ? <KeyboardArrowUp fontSize='large' /> : <KeyboardArrowDown fontSize='large' />}
        </IconButton>
      </Typography>
      <Collapse in={isOpen}>
        <Box py={2}>{children}</Box>
      </Collapse>
    </Box>
  )
}

export default CollapseSection
