import { FC } from 'react'
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import { KeyboardArrowRight } from '@mui/icons-material'

import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import { useIntl } from 'react-intl'

const AdditionalServicesDocuments: FC = () => {
  const { formatMessage } = useIntl()
  return (
    <>
      <BackNavigationHeader
        label={formatMessage({
          id: 'additionalServicesDocuments.sign',
          defaultMessage: 'Dokumenty do podpisu'
        })}
      />
      <BackNavigationContent>
        <List>
          <ListItem
            disablePadding
            sx={{
              borderBottom: '1px solid #CDCDCD',
              borderTop: '1px solid #CDCDCD'
            }}
            secondaryAction={<KeyboardArrowRight color='disabled' />}
          >
            <ListItemButton sx={{ py: 2 }}>
              <ListItemText primary='Załącznik 1' />
            </ListItemButton>
          </ListItem>
        </List>
      </BackNavigationContent>
    </>
  )
}

export default AdditionalServicesDocuments
