import { FC } from 'react'
import DesktopNavigation from 'components/DesktopNavigation'
import { UserProtectedRoute } from 'routes'

const HelpLocationGuidesPage: FC = () => {
  return (
    <UserProtectedRoute>
      <DesktopNavigation label='Przewodniki dot. lokalizacji' />
    </UserProtectedRoute>
  )
}

export default HelpLocationGuidesPage
