import { Box, CircularProgress, Typography } from '@mui/material'
import Section, { SectionCover } from 'components/Section'
import { useOfferList } from 'services/offer/hooks'
import { LocationOnOutlined, CalendarTodayOutlined, HomeOutlined } from '@mui/icons-material'
import dayjs from 'dayjs'
import { OfferButton } from '../Offer'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'

const OfferListView = () => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { data: offerList = [], isFetching, isLoading } = useOfferList()

  if (isFetching || isLoading) {
    return (
      <Section
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <CircularProgress />
      </Section>
    )
  }

  return (
    <Box sx={{ width: '100%', maxWidth: '400px', marginX: 'auto' }}>
      {offerList?.map(({ id, flatType, flat, investmentName, price, validTo, availableOn }) => (
        <Section
          key={`${id}`}
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            mx: '18px'
          }}>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                marginBottom: '14px'
              }}>
              <SectionCover
                src={flat?.photos[0]?.file}
                alt=''
                sx={{
                  height: '137px',
                  minWidth: '320px',
                  maxWidth: '680px',
                  objectFit: 'cover'
                }}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography sx={{ width: '100%', height: '100%', fontWeight: 600, fontSize: 20 }}>
                {investmentName}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '9px',
                marginBottom: '54px',
                gap: '9px'
              }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '12px'
                }}>
                <LocationOnOutlined sx={{ width: '16px', height: '16px' }} />
                <Typography sx={{ width: '100%', fontWeight: 500, fontSize: '14px' }}>
                  {flat.address}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '12px'
                }}>
                <HomeOutlined sx={{ width: '16px', height: '16px' }} />
                <Typography sx={{ width: '100%', fontWeight: 500, fontSize: '14px' }}>
                  {flatType}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '12px'
                }}>
                <CalendarTodayOutlined sx={{ width: '16px', height: '16px' }} />
                <Typography sx={{ width: '100%', fontWeight: 500, fontSize: '14px' }}>
                  {dayjs(availableOn).format('DD/MM/YYYY')}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px'
                }}>
                <Typography sx={{ fontWeight: 600, fontSize: '20px' }}>{+price} zł</Typography>
                <Typography sx={{ fontWeight: 500 }}>/mies.</Typography>
              </Box>
              <OfferButton
                children={formatMessage({
                  id: 'offer.seeTheOffer',
                  defaultMessage: 'Obejrzyj'
                })}
                sx={{ maxHeight: '26px', cursor: 'pointer' }}
                onClick={() => navigate(`/offer/${id}`)}
              />
            </Box>
          </Box>
        </Section>
      ))}
    </Box>
  )
}

export default OfferListView
