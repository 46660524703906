import { FC } from 'react'
import { useLeaseContractProtocols } from 'services/protocols/protocols.hooks'
import { useActiveLeaseContractId } from 'services/contracts/hooks/useActiveLeaseContractId'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useIntl } from 'react-intl'
import { useSnackbar } from 'notistack'
import { Box, Typography, TextField, Paper } from '@mui/material'
import { LoadingButton } from '@mui/lab'

const ProtocolComments: FC<{
  roomId: number
  protocolId: number
  comments: {
    id: number
    room: number
    comment: string
    image: string
  }[]
}> = ({ protocolId, roomId, comments }) => {
  const { formatMessage } = useIntl()
  const { activeLeaseContractId } = useActiveLeaseContractId()
  const { addComment } = useLeaseContractProtocols(activeLeaseContractId)
  const { enqueueSnackbar } = useSnackbar()

  const commentForm = useFormik({
    initialValues: {
      comment: ''
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      comment: Yup.string()

        .required(
          formatMessage({
            id: 'validation.required',
            defaultMessage: 'Wymagane'
          })
        )
    }),
    onSubmit: async (values, formHelpers) => {
      formHelpers.setSubmitting(true)
      try {
        await addComment.mutateAsync({ ...values, protocolId, room: roomId })
        enqueueSnackbar(
          formatMessage({
            id: 'additionalServicesProtocolSpaces.form.success',
            defaultMessage: 'Komentarz został dodany.'
          }),
          {
            variant: 'success'
          }
        )
      } catch (e) {
        enqueueSnackbar(
          formatMessage({
            id: 'additionalServicesProtocolSpaces.form.error',
            defaultMessage: 'Nie można dodać komentarza.'
          }),
          {
            variant: 'error'
          }
        )
      }

      formHelpers.resetForm()
      formHelpers.setSubmitting(false)
    }
  })

  return (
    <Box>
      <Box mb={2}>
        <Typography>
          {formatMessage({
            id: 'additionalServicesProtocolSpaces.comments',
            defaultMessage: 'Zgłos uwagi'
          })}
        </Typography>
        <TextField
          name='comment'
          onChange={commentForm.handleChange}
          value={commentForm.values.comment}
          label={formatMessage({
            id: 'additionalServicesProtocolSpaces.form.comment',
            defaultMessage: 'Opis'
          })}
          sx={{ mb: 2 }}
          fullWidth
          rows={2}
          multiline
          disabled={commentForm.isSubmitting}
        />
        <LoadingButton
          loading={commentForm.isSubmitting}
          fullWidth
          sx={{ mt: 2 }}
          variant='contained'
          onClick={commentForm.submitForm}>
          {formatMessage({
            id: 'additionalServicesProtocolSpaces.form.submit',
            defaultMessage: 'Zgłoś'
          })}
        </LoadingButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignContent: 'flex-start',
          maxWidth: '70vh',
          overflowY: 'auto'
        }}>
        {comments.length === 0 && (
          <Typography>
            {formatMessage({
              id: 'acceptanceProtocolSpaceComments.noComment',
              defaultMessage: 'Brak komentarzy'
            })}
          </Typography>
        )}
        {comments.reverse().map((comment) => (
          <Paper sx={{ p: 2, mb: 2 }} square key={comment.id}>
            {comment.comment}
          </Paper>
        ))}
      </Box>
    </Box>
  )
}

export default ProtocolComments
