import { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import DesktopNavigation from 'components/DesktopNavigation'
import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import { useNotificationsList } from 'services/notifications/notifications.hook'
import { Typography } from '@mui/material'
import { NOTIFICATION_STATUS_TYPE } from 'services/notifications/notifications.consts'

const Notification: FC = () => {
  const { id } = useParams()
  const { getNotification, mutateAsyncNotificationUpdate } = useNotificationsList()

  const notification = getNotification(Number(id))

  useEffect(() => {
    if (notification?.status === NOTIFICATION_STATUS_TYPE.unread) {
      mutateAsyncNotificationUpdate({
        ...notification,
        status: NOTIFICATION_STATUS_TYPE.read
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (notification) {
    return (
      <>
        <DesktopNavigation label={notification.title} desktopNavigateTo='/' />
        <BackNavigationHeader label={notification.title} />
        <BackNavigationContent>
          <Typography>{notification.text}</Typography>
        </BackNavigationContent>
      </>
    )
  }

  return null
}

export default Notification
