import { useMemo } from 'react'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { compose, groupBy, sort } from 'ramda'

import {
  useLeaseContractsDebitNotesList,
  LeaseContractsDebitNotesListWithDate
} from 'services/contracts/hooks/useLeaseContractsDebitNotesList'
import {
  useLeaseContractsPaymentSchedulesList,
  LeaseContractsPaymentSchedulesListWithTime
} from 'services/contracts/hooks/useLeaseContractsPaymentSchedulesList'
import {
  useLeaseContractsInvoicesList,
  LeaseContractsInvoicesListWithDate
} from 'services/contracts/hooks/useLeaseContractsInvoicesList'
import {
  useLeaseContractsInterestNotesList,
  LeaseContractsInterestNotesListWithDate
} from 'services/contracts/hooks/useLeaseContractsInterestNotesList'
import { useLanguage } from 'services/i18n/i18n.hooks'

dayjs.extend(isBetween)

type SchedulesWithDocumnetsType = LeaseContractsDebitNotesListWithDate &
  LeaseContractsPaymentSchedulesListWithTime &
  LeaseContractsInvoicesListWithDate &
  LeaseContractsInterestNotesListWithDate

export const useSchedulesWithDocumnets = ({
  activeLeaseContractId,
  isPaid
}: {
  activeLeaseContractId: number
  isPaid: boolean
}) => {
  const { dateAdapter } = useLanguage()

  const { leaseContractsInvoicesList, isLoading: isLoadingLeaseContractsInvoicesList } =
    useLeaseContractsInvoicesList(activeLeaseContractId, isPaid)
  const { leaseContractsDebitNotesLis, isLoading: isLoadingLeaseContractsDebitNotesList } =
    useLeaseContractsDebitNotesList(activeLeaseContractId, isPaid)
  const { leaseContractsInterestNotesList, isLoading: isLoadingLeaseContractsInterestNotesList } =
    useLeaseContractsInterestNotesList(activeLeaseContractId, isPaid)
  const { leaseContractsPaymentSchedulesList } =
    useLeaseContractsPaymentSchedulesList(activeLeaseContractId)

  const schedulesWithDocumnets = useMemo<{
    [key: string]: SchedulesWithDocumnetsType[]
  }>(
    () =>
      compose(
        groupBy(({ displayDate }: SchedulesWithDocumnetsType) =>
          displayDate.locale(dateAdapter).format('MMMM YYYY')
        ),
        sort((a, b) => (dayjs(a.displayDate).isAfter(dayjs(b.displayDate)) ? 1 : -1))
      )(
        isPaid
          ? ([
              ...leaseContractsInvoicesList,
              ...leaseContractsDebitNotesLis,
              ...leaseContractsInterestNotesList
            ] as any)
          : ([
              ...leaseContractsInvoicesList,
              ...leaseContractsDebitNotesLis,
              ...leaseContractsInterestNotesList,
              ...leaseContractsPaymentSchedulesList
            ] as any)
      ) as any,
    [
      leaseContractsInvoicesList,
      leaseContractsDebitNotesLis,
      leaseContractsInterestNotesList,
      leaseContractsPaymentSchedulesList,
      isPaid,
      dateAdapter
    ]
  )

  const accountBalance = useMemo(
    () =>
      Object.keys(schedulesWithDocumnets)
        .map((key) => {
          return schedulesWithDocumnets[key].reduce((acc, curr) => {
            if (!!curr.number) {
              const paidAmount = curr.paidAmount || curr.paymentAmount
              const amout = curr.amount || curr.totalAmountGross

              return parseFloat(amout) - parseFloat(paidAmount) + acc
            }
            return acc
          }, 0)
        })
        .reduce((acc, curr) => acc + curr, 0),
    [schedulesWithDocumnets]
  )

  return {
    schedulesWithDocumnets,
    accountBalance,
    isLoading:
      isLoadingLeaseContractsInterestNotesList ||
      isLoadingLeaseContractsDebitNotesList ||
      isLoadingLeaseContractsInvoicesList
  }
}
