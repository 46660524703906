import { useQuery } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useAccessToken } from 'services/token/token.hooks'
import { useTermsOfUse } from 'services/terms/terms.hooks'

export interface OfferListResponse {
  id: number
  price: string
  file: string
  validTo: string
  availableOn: string
  investmentName: string
  flatType: string
  flat: {
    id: number
    area: number
    floor: number
    flat_id_number: string
    flat_final_number: string
    number_of_rooms: number
    flat_type_name: string
    build_year: string
    photos: any
    flat_spaces: { OfferFlatSpace: { id: number; name: string; name_local: string } }
    address: string
  }
}

export const useOfferList = () => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  return useQuery({
    queryKey: ['offerList', masquaradeToken],
    cacheTime: 5000,
    staleTime: 5000,
    queryFn: () =>
      apiInstance<OfferListResponse[]>({
        url: '/projects/offers/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && hasUserConsented
  })
}

export const useOfferListOffer = (id?: string) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  return useQuery({
    queryKey: ['offerListOffer', id, masquaradeToken],
    cacheTime: 5000,
    staleTime: 5000,
    queryFn: () =>
      apiInstance({
        url: `/projects/offers/${id}/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!id && !!accessToken && hasUserConsented
  })
}
