import { FC, ReactNode } from 'react'
import { ThemeProvider as ThemeProviderMUI } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'

import theme from './theme'

const ThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <ThemeProviderMUI theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProviderMUI>
  )
}

export default ThemeProvider
