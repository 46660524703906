export const CONTRACT_STATUS = {
  contractStatusTypeActive: 0,
  contractStatusTypeInRejection: 1,
  contractStatusTypeRejected: 2,
  contractStatusDataVerification: 3,
  contractStatusFinancialVerification: 4,
  contractStatusEnded: 5,
  contractStatusBookingAgreement: 6,
  contractStatusDepositPaid: 7,
  contractStatusActivationReady: 8
}
export const CONTRACT_ACTIVE_GROUP = [
  CONTRACT_STATUS.contractStatusTypeActive,
  CONTRACT_STATUS.contractStatusActivationReady
]
export const CONTRACT_BOOKING_GROUP = [
  CONTRACT_STATUS.contractStatusBookingAgreement,
  CONTRACT_STATUS.contractStatusDepositPaid
]
export const CONTRACT_ENDED_GROUP = [CONTRACT_STATUS.contractStatusEnded]
export const CONTRACT_VISIBLE_GROUP = [
  ...CONTRACT_ACTIVE_GROUP,
  ...CONTRACT_BOOKING_GROUP,
  ...CONTRACT_ENDED_GROUP
]

export const CONTRACT_DOCUMENT_TYPE = {
  interestNote: 'interestNote',
  invoice: 'invoice',
  paymentSchedule: 'paymentSchedule',
  debitNote: 'debitNote',
  deposit: 'deposit'
} as const

type ContractDocumentType = typeof CONTRACT_DOCUMENT_TYPE
export type ContractDocumentTypes = ContractDocumentType[keyof ContractDocumentType]

export const FLAT_TYPES_EXTRA = {
  standard: 0,
  plus: 1,
  premium: 2
}

type FlatTypeExtraType = typeof FLAT_TYPES_EXTRA
export type FlatTypesExtraType = FlatTypeExtraType[keyof FlatTypeExtraType]

export const INTERCOM_TYPES = {
  code: 0,
  token: 1,
  card: 2
}

type IntercomTypes = typeof INTERCOM_TYPES
export type IntercomType = IntercomTypes[keyof IntercomTypes]
