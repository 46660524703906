import { useQuery } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import dayjs, { Dayjs } from 'dayjs'
import { useAccessToken } from 'services/token/token.hooks'
import { LeaseContractResponse } from 'services/contracts/hooks/useLeaseContracts'
import { CONTRACT_DOCUMENT_TYPE, ContractDocumentTypes } from 'services/contracts/contracts.consts'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useTermsOfUse } from 'services/terms/terms.hooks'

export interface LeaseContractsInterestNotesListResponse {
  id: number
  number: string
  amount: string
  dateOfIssue: string
  file: string
  paymentAmount: string
  positions: {
    id: number
    title: string
    amount: string
  }[]
  isPaid: boolean
}

export interface LeaseContractsInterestNotesListWithDate
  extends Omit<LeaseContractsInterestNotesListResponse, 'dateOfIssue'> {
  displayDate: Dayjs
  type: ContractDocumentTypes
}

export const useLeaseContractsInterestNotesList = (
  id?: LeaseContractResponse['id'],
  isPaid: boolean = false
) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  const { data: leaseContractsInterestNotesList = [], ...rest } = useQuery({
    queryKey: ['leaseContractsInterestNotesList', id, isPaid, masquaradeToken],
    cacheTime: 3600000,
    queryFn: () =>
      apiInstance<LeaseContractsInterestNotesListResponse[]>({
        url: `/lease/contracts/${id}/interest-notes/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        },
        params: {
          isPaid
        }
      }).then(({ data }) => data),
    select: (date) =>
      date.map((note) => ({
        ...note,
        displayDate: dayjs(note.dateOfIssue).startOf('month'),
        type: CONTRACT_DOCUMENT_TYPE.interestNote
      })) as LeaseContractsInterestNotesListWithDate[],
    enabled: !!accessToken && !!id && hasUserConsented
  })

  return { leaseContractsInterestNotesList, ...rest }
}
