import { FC, useState } from 'react'
import { UserProtectedRoute } from 'routes'
import UserPersonalDetailsConfirmationForm from 'components/User/UserPersonalDetailsConfirmationForm'
import UserPersonalDataSuccess from './UserPersonalDataSuccess'

const UserPersonalDataConfirmationPage: FC = () => {
  const [isConfirmed, setIsConfirmed] = useState(false)
  //TODO - add check for already confirmed form by admin in CRM

  return (
    <UserProtectedRoute>
      {isConfirmed ? (
        <UserPersonalDataSuccess />
      ) : (
        <UserPersonalDetailsConfirmationForm handleSuccess={() => setIsConfirmed(true)} />
      )}
    </UserProtectedRoute>
  )
}

export default UserPersonalDataConfirmationPage
