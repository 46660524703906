import { FC } from 'react'
import { TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { LoadingButton } from '@mui/lab'
import { useIntl } from 'react-intl'
import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import { useUsersIceContact } from 'services/profile/profile.hooks'

interface UserDetailsIceProps {
  onBack: () => void
}

const UserDetailsIce: FC<UserDetailsIceProps> = ({ onBack }) => {
  const { formatMessage } = useIntl()
  const { usersIceContact, usersIceContactUpdate, usersIceContactCreate, hasIceContact } =
    useUsersIceContact()

  const iceForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: usersIceContact.firstName ?? '',
      lastName: usersIceContact.lastName ?? '',
      phone: usersIceContact.phone ?? ''
    },
    onSubmit: async (values, { setSubmitting }) => {
      if (hasIceContact) {
        await usersIceContactUpdate.mutate(values)
      } else {
        await usersIceContactCreate.mutate(values)
      }

      setSubmitting(true)
    }
  })
  return (
    <form onSubmit={iceForm.handleSubmit}>
      <BackNavigationHeader
        label={formatMessage({
          id: 'userDetailsIce.label',
          defaultMessage: 'Dane kontaktowe'
        })}
        onBack={onBack}
      />
      <BackNavigationContent>
        <Typography fontSize={14} sx={{ mb: 3, mt: 'calc(env(safe-area-inset-top) + 1rem)' }}>
          {formatMessage({
            id: 'userDetailsIce.ice',
            defaultMessage:
              'ICE (in case of emergency), to numer do osoby, z którą będziemy kontaktować się w sytuacji awaryjnej, np.: nie mamy z Tobą kontaktu i obawiamy się, że mogło stać się coś niepokojącego.'
          })}
        </Typography>
        <TextField
          fullWidth
          sx={{ mb: 3 }}
          label={formatMessage({
            id: 'userDetailsIce.form.firstName',
            defaultMessage: 'Imie'
          })}
          name='firstName'
          value={iceForm.values.firstName}
          onChange={iceForm.handleChange}
        />
        <TextField
          fullWidth
          sx={{ mb: 3 }}
          name='lastName'
          label={formatMessage({
            id: 'userDetailsIce.form.lastName',
            defaultMessage: 'Nazwisko'
          })}
          value={iceForm.values.lastName}
          onChange={iceForm.handleChange}
        />
        <TextField
          fullWidth
          sx={{ mb: 3 }}
          name='phone'
          label={formatMessage({
            id: 'userDetailsIce.form.phone',
            defaultMessage: 'Numer telefonu'
          })}
          value={iceForm.values.phone}
          onChange={iceForm.handleChange}
        />
        <LoadingButton
          fullWidth
          sx={{ mt: 6 }}
          variant='contained'
          loading={iceForm.isSubmitting}
          type='submit'
        >
          {formatMessage({
            id: 'userDetailsIce.form.submit',
            defaultMessage: 'ZAWNIOSKUJ O ZMIANĘ DANYCH'
          })}
        </LoadingButton>
      </BackNavigationContent>
    </form>
  )
}

export default UserDetailsIce
