import { FC } from 'react'
import DesktopNavigation from 'components/DesktopNavigation'
import { UserProtectedRoute } from 'routes'
import { useIntl } from 'react-intl'
import AdditionalServicesAnnexes from 'components/AdditionalServices/AdditionalServicesAnnexes'

const AdditionalServicesAnnexesPage: FC = () => {
  const { formatMessage } = useIntl()
  return (
    <UserProtectedRoute>
      <DesktopNavigation
        label={formatMessage({
          id: 'additionalServicesAttachments.annexes',
          defaultMessage: 'Aneksy'
        })}
        desktopNavigateTo='/additional-services'
      />
      <AdditionalServicesAnnexes />
    </UserProtectedRoute>
  )
}

export default AdditionalServicesAnnexesPage
