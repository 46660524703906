import { FC } from 'react'
import DesktopNavigation from 'components/DesktopNavigation'
import { UserProtectedRoute } from 'routes'

import { BoardsNewPost } from 'components/BoardsPost'

const BoardsPostPage: FC = () => {
  return (
    <UserProtectedRoute>
      <DesktopNavigation label='Dodaj do tablicy' />
      <BoardsNewPost />
    </UserProtectedRoute>
  )
}

export default BoardsPostPage
