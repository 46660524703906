import { useQuery } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import dayjs, { Dayjs } from 'dayjs'
import { useAccessToken } from 'services/token/token.hooks'
import { CONTRACT_DOCUMENT_TYPE, ContractDocumentTypes } from 'services/contracts/contracts.consts'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useTermsOfUse } from 'services/terms/terms.hooks'

export interface LeaseContractsPaymentSchedulesListResponse {
  id: number
  finalGrossAmount: string
  invoiceDate: string
}

export interface LeaseContractsPaymentSchedulesListWithTime
  extends Omit<LeaseContractsPaymentSchedulesListResponse, 'invoiceDate'> {
  displayDate: Dayjs
  invoiceDate: Dayjs
  type: ContractDocumentTypes
}

export const useLeaseContractsPaymentSchedulesList = (
  id?: LeaseContractsPaymentSchedulesListResponse['id']
) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  const { data: leaseContractsPaymentSchedulesList = [], ...rest } = useQuery({
    queryKey: ['leaseContractsPaymentSchedulesList', id, masquaradeToken],
    queryFn: () =>
      apiInstance<LeaseContractsPaymentSchedulesListResponse[]>({
        url: `/lease/contracts/${id}/payment-schedules/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    select: (data) =>
      data.map((schedule) => ({
        ...schedule,
        displayDate: dayjs(schedule.invoiceDate).startOf('month'),
        invoiceDate: dayjs(schedule.invoiceDate),
        type: CONTRACT_DOCUMENT_TYPE.paymentSchedule
      })) as LeaseContractsPaymentSchedulesListWithTime[],
    enabled: !!accessToken && !!id && hasUserConsented
  })

  return { leaseContractsPaymentSchedulesList, ...rest }
}
