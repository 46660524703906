import { FC } from 'react'
import { Box } from '@mui/material'
import type { BoxProps } from '@mui/material/Box'

const BoxWithSafeZone: FC<BoxProps> = ({ sx, ...props }) => {
  return (
    <Box
      sx={{
        paddingTop: 'env(safe-area-inset-top)',
        paddingBottom: 'env(safe-area-inset-bottom)',
        ...sx
      }}
      {...props}
    />
  )
}

export default BoxWithSafeZone
