import { FC, useEffect } from 'react'
import { Button } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { LoadingButton } from '@mui/lab'
import * as Yup from 'yup'
import { useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'

import PasswordField from 'components/PasswordField'
import { useUserPasswordReset } from 'services/token/token.hooks'

const SetNewPassword: FC = () => {
  const { formatMessage } = useIntl()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const navigation = useNavigate()
  const { mutateAsync } = useUserPasswordReset()
  const { token } = useParams<{ token: string }>()

  const form = useFormik({
    initialValues: {
      password: ''
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      password: Yup.string().required(
        formatMessage({
          id: 'validation.required',
          defaultMessage: 'Wymagane'
        })
      )
    }),
    onSubmit: async ({ password }, helpers) => {
      helpers.setSubmitting(true)
      try {
        await mutateAsync({ password, token: token as string })
        enqueueSnackbar(
          formatMessage({
            id: 'setNewPassword.success',
            defaultMessage:
              'Hasło zostało zmienione, teraz możesz się zalogować używając nowego hasła.'
          }),
          {
            variant: 'success'
          }
        )
        navigation('/auth/sign-in')
      } catch (e: any) {
        helpers.setErrors(e.response.data)
        enqueueSnackbar(
          formatMessage({
            id: 'setNewPassword.error',
            defaultMessage:
              'Nie można nadać nowego hasła. Sprawdź poprawność link lub nowego hasła.'
          }),
          {
            variant: 'error'
          }
        )
      }
      helpers.setSubmitting(false)
    }
  })

  useEffect(() => {
    queryClient.invalidateQueries()
    // eslint-disable-next-line
  }, [])

  return (
    <form onSubmit={form.handleSubmit}>
      <PasswordField
        name='password'
        value={form.values.password}
        onChange={form.handleChange}
        fullWidth
        sx={{ mb: 3 }}
        label={formatMessage({
          id: 'setNewPassword.form.password',
          defaultMessage: 'Hasło'
        })}
        disabled={form.isSubmitting}
        error={!!form.errors.password}
        helperText={!!form.errors.password && form.errors.password}
      />
      <LoadingButton
        fullWidth
        size='large'
        variant='contained'
        sx={{ mb: 1 }}
        type='submit'
        disabled={form.isSubmitting}
      >
        {formatMessage({
          id: 'setNewPassword.form.submit',
          defaultMessage: 'Ustaw nowe hasło'
        })}
      </LoadingButton>
      <Button
        onClick={() => navigation('/auth/sign-in')}
        color='secondary'
        fullWidth
        size='large'
        variant='text'
        sx={{ textTransform: 'none', fontWeight: '400' }}
      >
        {formatMessage({
          id: 'setNewPassword.form.signIn',
          defaultMessage: 'Wróć do logowania'
        })}
      </Button>
    </form>
  )
}

export default SetNewPassword
