import { FC, ComponentType } from 'react'
import { Box, Typography, useTheme } from '@mui/material'

interface InfoIndicatorProps {
  isVisible: boolean
  Icon: ComponentType<any>
  helperText: string
}

const InfoIndicator: FC<InfoIndicatorProps> = ({ isVisible, helperText, Icon }) => {
  const theme = useTheme()

  if (isVisible) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          my: 2
        }}
      >
        <Box
          sx={{
            width: '140px',
            height: '140px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            backgroundColor: theme.palette.grey[200]
          }}
        >
          <Icon sx={{ color: theme.palette.secondary.light, fontSize: '10rem' }} />
        </Box>
        <Typography mt={2} variant='body2'>
          {helperText}
        </Typography>
      </Box>
    )
  }

  return null
}

export default InfoIndicator
