import { useMutation } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useAccessToken } from 'services/token/token.hooks'

export interface OfferResponse {
  reason: string
  comment: string
  offerId?: string
}

export const useOffer = () => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()

  const { mutateAsync: mutateAsyncOfferDecline, ...rest } = useMutation({
    mutationFn: ({
      reason,
      comment,
      offerId
    }: {
      reason: string
      comment: string
      offerId?: string
    }) =>
      apiInstance<OfferResponse>({
        url: `/projects/offers/${offerId}/decline/`,
        method: 'POST',
        data: { reason, comment },
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      })
  })

  const { mutateAsync: mutateAsyncOfferAccept } = useMutation({
    mutationFn: ({ id }: { id: any }) =>
      apiInstance<OfferResponse>({
        url: `/projects/offers/${id}/accept/`,
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      })
  })

  return { offerDecline: mutateAsyncOfferDecline, offerAccept: mutateAsyncOfferAccept, ...rest }
}
