import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useSnackbar } from 'notistack'
import { useTermsOfUse } from 'services/terms/terms.hooks'
import { useAccessToken } from 'services/token/token.hooks'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'

interface UserConsentsResponse {
  marketingConsent: boolean
  newsletterConsent: boolean
}

export const useUserConsents = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  const { data: usersProfileMarketing } = useQuery({
    queryKey: ['userConsents', masquaradeToken],
    queryFn: () =>
      apiInstance<UserConsentsResponse>({
        url: '/users/profile/marketing/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && hasUserConsented
  })

  const usersProfileMarketingUpdate = useMutation({
    mutationFn: (profile: Partial<UserConsentsResponse>) =>
      apiInstance<UserConsentsResponse>({
        url: '/users/profile/marketing/',
        method: 'PATCH',
        data: profile,
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    onSuccess: (data) => {
      queryClient.setQueryData(['userConsents', masquaradeToken], data)
      enqueueSnackbar('Wniosek został złożony poprawnie.', {
        variant: 'success'
      })
    },
    onError: () => {
      enqueueSnackbar('Błąd. Spróbuj później.', {
        variant: 'error'
      })
    }
  })

  return { usersProfileMarketing, usersProfileMarketingUpdate }
}
