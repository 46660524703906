import { FC } from 'react'
import { UserDetailsConsents } from 'components/User'
import DesktopNavigation from 'components/DesktopNavigation'
import { UserProtectedRoute } from 'routes'
import { useIntl } from 'react-intl'

const UserConstensDetailsPage: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <UserProtectedRoute>
      <DesktopNavigation
        label={formatMessage({
          id: 'userProfile.constens',
          defaultMessage: 'Zgody i powiadomienia'
        })}
        desktopNavigateTo='/'
      />
      <UserDetailsConsents />
    </UserProtectedRoute>
  )
}

export default UserConstensDetailsPage
