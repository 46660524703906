import { FC } from 'react'
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { KeyboardArrowRight, ReportGmailerrorredOutlined } from '@mui/icons-material'
import { useNavigate, useParams } from 'react-router-dom'
import { useLeaseContractProtocols } from 'services/protocols/protocols.hooks'
import { useIntl } from 'react-intl'

const AcceptanceProtocolListItem: FC = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { id } = useParams()
  const { protocolsList } = useLeaseContractProtocols(Number(id))

  if (!!protocolsList.length) {
    return (
      <ListItem
        onClick={() => navigate(`/additional-services/${id}/protocol`)}
        disablePadding
        sx={{
          borderBottom: '1px solid #CDCDCD'
        }}
        secondaryAction={<KeyboardArrowRight color='disabled' />}>
        <ListItemButton sx={{ py: 2 }}>
          <ListItemIcon>
            <ReportGmailerrorredOutlined color='primary' />
          </ListItemIcon>
          <ListItemText
            primary={formatMessage({
              id: 'acceptanceProtocolListItem.protocol',
              defaultMessage: 'Protokół Z-O'
            })}
          />
        </ListItemButton>
      </ListItem>
    )
  }

  return null
}

export default AcceptanceProtocolListItem
