import { FC } from 'react'
import { Button, TextField } from '@mui/material'
import { useIntl } from 'react-intl'
import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'

interface UserDetailsContactDetailsProps {
  onBack: () => void
}

const UserDetailsContactDetails: FC<UserDetailsContactDetailsProps> = ({ onBack }) => {
  const { formatMessage } = useIntl()
  return (
    <>
      <BackNavigationHeader
        label={formatMessage({
          id: 'userDetailsContactDetails.label',
          defaultMessage: 'Ogólne dane kontaktowe'
        })}
        onBack={onBack}
      />
      <BackNavigationContent>
        <TextField
          fullWidth
          sx={{ mb: 3, mt: 'calc(env(safe-area-inset-top) + 1rem)' }}
          label={formatMessage({
            id: 'userDetailsContactDetails.street',
            defaultMessage: 'Ulica'
          })}
        />
        <TextField
          fullWidth
          sx={{ mb: 3 }}
          label={formatMessage({
            id: 'userDetailsContactDetails.house',
            defaultMessage: 'Numer domu'
          })}
        />
        <TextField
          fullWidth
          sx={{ mb: 3 }}
          label={formatMessage({
            id: 'userDetailsContactDetails.flat',
            defaultMessage: 'numer mieszkania'
          })}
        />
        <TextField
          fullWidth
          sx={{ mb: 3 }}
          label={formatMessage({
            id: 'userDetailsContactDetails.postalCode',
            defaultMessage: 'kod pocztowy'
          })}
        />
        <TextField
          fullWidth
          sx={{ mb: 3 }}
          label={formatMessage({
            id: 'userDetailsContactDetails.city',
            defaultMessage: 'miasto'
          })}
        />
        <TextField
          fullWidth
          sx={{ mb: 3 }}
          label={formatMessage({
            id: 'userDetailsContactDetails.country',
            defaultMessage: 'kraj'
          })}
        />
        <Button fullWidth sx={{ mt: 6 }} variant='contained'>
          {formatMessage({
            id: 'userDetailsContactDetails.submit',
            defaultMessage: 'ZAWNIOSKUJ O ZMIANĘ DANYCH'
          })}
        </Button>
      </BackNavigationContent>
    </>
  )
}

export default UserDetailsContactDetails
