const useSafeAreaInsets = () => {
  const safeAreaInsetTop = getComputedStyle(document.documentElement).getPropertyValue(
    '--safe-area-inset-top'
  )
  const safeAreaInsetBottom = getComputedStyle(document.documentElement).getPropertyValue(
    '--safe-area-inset-bottom'
  )
  const safeAreaInsetLeft = getComputedStyle(document.documentElement).getPropertyValue(
    '--safe-area-inset-left'
  )
  const safeAreaInsetRight = getComputedStyle(document.documentElement).getPropertyValue(
    '--safe-area-inset-left'
  )

  return {
    safeAreaInsetTop: !!safeAreaInsetTop ? safeAreaInsetTop : '0px',
    safeAreaInsetBottom: !!safeAreaInsetBottom ? safeAreaInsetBottom : '0px',
    safeAreaInsetLeft: !!safeAreaInsetLeft ? safeAreaInsetLeft : '0px',
    safeAreaInsetRight: !!safeAreaInsetRight ? safeAreaInsetRight : '0px'
  }
}

export default useSafeAreaInsets
