import { useQuery } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useAccessToken } from 'services/token/token.hooks'
import { LeaseContractResponse } from 'services/contracts/hooks/useLeaseContracts'
import { omit } from 'ramda'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useTermsOfUse } from 'services/terms/terms.hooks'

export const grossCostTypes = {
  WATT_HOUR_METER: 'wattHourMeter',
  HEATING_METER: 'heatingMeter',
  HOT_WATER_METER: 'hotWaterMeter',
  COLD_WATER_METER: 'coldWaterMeter',
  AIR_CONDITIONING_METER: 'airConditioningMeter'
} as const

type GrossCostType = typeof grossCostTypes
export type CostType = GrossCostType[keyof GrossCostType]

export interface LeaseContractsMediaMetersListResponse {
  id: number
  totalGrossCost: null | string
  flat: number
  grossCost: {
    wattHourMeter: number
    heatingMeter: number
    hotWaterMeter: number
    coldWaterMeter: number
    airConditioningMeter: number
    gasMeter: number
  }
  dateMetersFrom: string
  wattHourMeter: number
  heatingMeter: number
  hotWaterMeter: number
  coldWaterMeter: number
  airConditioningMeter: number
  wear: {
    wattHourMeter: number
    heatingMeter: number
    hotWaterMeter: number
    coldWaterMeter: number
    airConditioningMeter: number
    gasMeter: number
  }
}

export const useLeaseContractsMediaMetersList = (id?: LeaseContractResponse['id']) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  const { data: leaseContractsMediaMetersList = [], ...rest } = useQuery({
    queryKey: ['leaseContractsMediaMetersList', id, masquaradeToken],
    queryFn: () =>
      apiInstance<LeaseContractsMediaMetersListResponse[]>({
        url: `/lease/contracts/${id}/media-meters/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(
        ({ data }) =>
          data
            .map((media) => ({
              ...media,
              grossCost: omit(['gasMeter'], media.grossCost),
              wear: omit(['gasMeter'], media.wear)
            }))
            .filter(
              ({ totalGrossCost }) => !!totalGrossCost
            ) as LeaseContractsMediaMetersListResponse[]
      ),
    enabled: !!accessToken && !!id && hasUserConsented
  })

  return { leaseContractsMediaMetersList, ...rest }
}
