import { useQueryClient, useQuery } from '@tanstack/react-query'
import plAdapter from 'dayjs/locale/pl'
import enAdapter from 'dayjs/locale/en'
import { useMemo } from 'react'

const adapters: any = {
  en: enAdapter,
  pl: plAdapter
}

const DEFAULT_LANGUAGE = 'pl'
const AVAILABLE_LANGUAGES = [
  {
    label: 'Polski',
    value: DEFAULT_LANGUAGE
  },
  {
    label: 'English',
    value: 'en'
  }
]

export const useLanguage = () => {
  const queryClient = useQueryClient()

  const {
    data: language = DEFAULT_LANGUAGE,
    refetch,
    ...rest
  } = useQuery({
    queryKey: ['language'],
    queryFn: () => DEFAULT_LANGUAGE,
    enabled: false
  })

  const setLanguage = (newLanguage: string) => {
    queryClient.setQueryData(['language'], newLanguage)
  }

  const dateAdapter = useMemo(() => adapters[language], [language])

  return {
    language,
    setLanguage,
    DEFAULT_LANGUAGE,
    AVAILABLE_LANGUAGES,
    dateAdapter,
    ...rest
  }
}
