import { Box } from '@mui/material'
import { FC } from 'react'
import { APIProvider, AdvancedMarker, Map, Pin } from '@vis.gl/react-google-maps'
import { HomeOutlined } from '@mui/icons-material'
import { getLatLng } from 'utils'

type OfferMapProps = {
  geoposition: { lat: number; lng: number }
  pois: {
    name: string
    nameEn: string
    geoposition: string
    iconUrl: string
  }
}

const OfferMap: FC<OfferMapProps> = ({ geoposition, pois }) => {
  const { lat, lng } = geoposition

  const Pois = ({ points }: any) => {
    return (
      <>
        {points?.map((point: { id: number; geoposition: string; iconUrl: string }) => (
          <AdvancedMarker key={point.id} position={getLatLng(point.geoposition)}>
            <Pin
              background={'#527EF7'}
              glyphColor={'#000'}
              borderColor={'#fff'}
              scale={1.5}
              children={
                <Box
                  component='img'
                  src={point.iconUrl}
                  sx={{ width: '24px', height: '24px', objectFit: 'contain' }}
                />
              }
            />
          </AdvancedMarker>
        ))}
      </>
    )
  }

  return (
    <Box sx={{ marginTop: '20px', width: '100vw', height: '191px' }}>
      <APIProvider apiKey={'AIzaSyDg0paToUROEsCfNHR4oN1icU6pvdOLe7M'}>
        <Map
          style={{ width: '100%', height: '191px' }}
          defaultCenter={{ lat: lat, lng: lng }}
          defaultZoom={12}
          gestureHandling={'greedy'}
          mapId={'e11260affeeaa8ca'}>
          <AdvancedMarker position={geoposition}>
            <Pin
              background={'#527EF7'}
              glyphColor={'#000'}
              borderColor={'#fff'}
              children={<HomeOutlined style={{ color: '#fff' }} />}
            />
          </AdvancedMarker>
          <Pois points={pois} />
        </Map>
      </APIProvider>
    </Box>
  )
}

export default OfferMap
