import { useQuery } from '@tanstack/react-query'
import { apiInstance } from 'services/api'

import { useAccessToken } from 'services/token/token.hooks'
import type { LeaseContractResponse } from 'services/contracts/hooks/useLeaseContracts'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'

export interface LeaseContractsDepositBankAccountResponse {
  id: number
  bankAccount: string
}

export const useLeaseContractsDepositBankAccount = (id?: LeaseContractResponse['id']) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()

  const { data: leaseContractsDepositBankAccount, ...rest } = useQuery({
    queryKey: ['leaseContractsDepositBankAccount', id, masquaradeToken],
    queryFn: () =>
      apiInstance<LeaseContractsDepositBankAccountResponse>({
        url: `/lease/contracts/${id}/deposit-bank-account/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && !!id
  })

  return { leaseContractsDepositBankAccount, ...rest }
}
