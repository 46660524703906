import { FC, useState, useMemo } from 'react'
import { List, ListItem, ListItemButton, ListItemText, Tabs, Tab } from '@mui/material'
import { KeyboardArrowRight } from '@mui/icons-material'
import { useLeaseContractsList } from 'services/contracts/hooks/useLeaseContracts'
import { useNavigate } from 'react-router-dom'
import InfoIndicator from 'components/InfoIndicator'
import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import {
  CONTRACT_ACTIVE_GROUP,
  CONTRACT_ENDED_GROUP,
  CONTRACT_BOOKING_GROUP,
  CONTRACT_STATUS
} from 'services/contracts/contracts.consts'
import { ArticleOutlined } from '@mui/icons-material'
import { useIntl } from 'react-intl'

const AdditionalServicesLease: FC = () => {
  const { formatMessage } = useIntl()
  const [leaseType, setLeaseType] = useState<number[]>(CONTRACT_ACTIVE_GROUP)
  const { leaseContractsList, isFetching, isLoading } = useLeaseContractsList()
  const navigate = useNavigate()

  const leaseContractsFiltredList = useMemo(
    () => leaseContractsList.filter(({ leaseStatus }) => leaseType.includes(leaseStatus)),
    [leaseType, leaseContractsList]
  )

  const onClick = (id: number, leaseStatus?: number) => {
    if (leaseStatus === CONTRACT_STATUS.contractStatusBookingAgreement) {
      navigate(`/additional-services/${id}/booking-agreement`)
    } else {
      navigate(`/additional-services/${id}`)
    }
  }

  return (
    <>
      <BackNavigationHeader
        label={formatMessage({
          id: 'additionalServicesLease.contracts',
          defaultMessage: 'Umowy'
        })}
      />
      <BackNavigationContent>
        <Tabs value={leaseType} onChange={(_, newValue) => setLeaseType(newValue)} sx={{ mb: 3 }}>
          <Tab
            label={formatMessage({
              id: 'additionalServicesLease.active',
              defaultMessage: 'Aktywne'
            })}
            value={CONTRACT_ACTIVE_GROUP}
          />
          <Tab
            label={formatMessage({
              id: 'additionalServicesLease.signature',
              defaultMessage: 'Do podpisu'
            })}
            value={CONTRACT_BOOKING_GROUP}
          />
          <Tab
            label={formatMessage({
              id: 'additionalServicesLease.end',
              defaultMessage: 'Zakończone'
            })}
            value={CONTRACT_ENDED_GROUP}
          />
        </Tabs>
        <List>
          {leaseContractsFiltredList.map(
            ({ contractNumber, id, leaseContractFile, leaseStatus }) => (
              <ListItem
                key={`${contractNumber}_${id}`}
                disablePadding
                sx={{
                  borderBottom: '1px solid #CDCDCD',
                  borderTop: '1px solid #CDCDCD'
                }}
                secondaryAction={<KeyboardArrowRight color='disabled' />}
                onClick={() => onClick(id, leaseStatus)}>
                <ListItemButton sx={{ py: 2 }}>
                  <ListItemText primary={contractNumber} />
                </ListItemButton>
              </ListItem>
            )
          )}
          <InfoIndicator
            helperText={formatMessage({
              id: 'additionalServicesLease.noContracts',
              defaultMessage: 'Nie znaleźono umów dla wybranego typu.'
            })}
            Icon={ArticleOutlined}
            isVisible={!leaseContractsFiltredList.length && !isFetching && !isLoading}
          />
        </List>
      </BackNavigationContent>
    </>
  )
}

export default AdditionalServicesLease
