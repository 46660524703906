import { KeyboardArrowRight, SupervisorAccount } from '@mui/icons-material'
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useMasquarade } from 'services/masquarade/masquarade.hooks'

const MasquaradeListItem: FC = () => {
  const navigate = useNavigate()
  const { canUseMasquerade } = useMasquarade()
  const { formatMessage } = useIntl()

  if (canUseMasquerade) {
    return (
      <ListItem
        onClick={() => navigate('/user/masquarade')}
        disablePadding
        sx={{
          borderBottom: '1px solid #CDCDCD'
        }}
        secondaryAction={<KeyboardArrowRight />}
      >
        <ListItemButton sx={{ py: 2 }}>
          <ListItemIcon>
            <SupervisorAccount color='primary' />
          </ListItemIcon>
          <ListItemText
            primary={formatMessage({
              id: 'masquaradeListItem.masquarade',
              defaultMessage: 'Maskarada'
            })}
          />
        </ListItemButton>
      </ListItem>
    )
  }

  return null
}

export default MasquaradeListItem
