import { FC, ReactNode } from 'react'
import { Box, Typography, SxProps } from '@mui/material'

import type { ButtonProps } from '@mui/material/Button'

import { Button } from './PaperButton.styled'

interface PaperButtonProps extends ButtonProps {
  label?: string
  isActive?: boolean
  icon: (sx: SxProps) => ReactNode
}

const PaperButton: FC<PaperButtonProps> = ({ label, icon, isActive = false, ...props }) => {
  return (
    <Box sx={{ width: '100px', m: 1, mb: 4 }}>
      <Button {...props} isActive={isActive}>
        {icon({ color: isActive ? '#527EF7' : '#707070' })}
      </Button>
      {!!label && (
        <Typography fontWeight={500} variant='body2' textAlign='center'>
          {label}
        </Typography>
      )}
    </Box>
  )
}

export default PaperButton
