import { FC, useMemo } from 'react'
import { Typography, Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useLeaseContractsMediaMetersList } from 'services/contracts/hooks/useLeaseContractsMediaMetersList'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import dayjs from 'dayjs'
import { EqualizerOutlined } from '@mui/icons-material'
import InfoIndicator from 'components/InfoIndicator'
import { useIntl } from 'react-intl'
import { useLanguage } from 'services/i18n/i18n.hooks'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false
    }
  },
  scales: {
    y: {
      beginAtZero: true
    }
  }
}

const AdditionalServicesAverageUtilityConsumption: FC = () => {
  const { id } = useParams()
  const { leaseContractsMediaMetersList, isFetching, isLoading } = useLeaseContractsMediaMetersList(
    Number(id)
  )
  const { formatMessage } = useIntl()
  const { dateAdapter } = useLanguage()

  const data = useMemo(
    () => ({
      labels: leaseContractsMediaMetersList.map(({ dateMetersFrom }) =>
        dayjs(dateMetersFrom).locale(dateAdapter).format('MMMM YYYY')
      ),
      datasets: [
        {
          data: leaseContractsMediaMetersList.map(({ totalGrossCost }) => totalGrossCost),
          backgroundColor: '#527EF7',
          barThickness: 16
        }
      ]
    }),
    [leaseContractsMediaMetersList, dateAdapter]
  )

  if (!!leaseContractsMediaMetersList.length) {
    return (
      <Box mb={4}>
        <Typography fontWeight={600} mb={4}>
          {formatMessage({
            id: 'additionalServicesAverageUtilityConsumption.media',
            defaultMessage: 'Średnie zużycie mediów (ms / pln)'
          })}
        </Typography>
        <Bar options={options} data={data} />
      </Box>
    )
  }

  return (
    <>
      <InfoIndicator
        helperText={formatMessage({
          id: 'additionalServicesAverageUtilityConsumption.noMedia',
          defaultMessage: 'Brak danych o zużyciu mediów.'
        })}
        Icon={EqualizerOutlined}
        isVisible={!leaseContractsMediaMetersList.length && !isFetching && !isLoading}
      />
    </>
  )
}

export default AdditionalServicesAverageUtilityConsumption
