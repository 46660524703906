import { FC } from 'react'
import { AdditionalServicesMedia } from 'components/AdditionalServices'
import RestrictedForDesktop from 'components/RestrictedForDesktop'
import DesktopNavigation from 'components/DesktopNavigation'
import { UserProtectedRoute } from 'routes'

const AdditionalServicesPage: FC = () => {
  return (
    <UserProtectedRoute>
      <DesktopNavigation label='Usługi dodatkowe' desktopNavigateTo='/additional-services' />
      <RestrictedForDesktop>
        <AdditionalServicesMedia />
      </RestrictedForDesktop>
    </UserProtectedRoute>
  )
}

export default AdditionalServicesPage
