import { FC } from 'react'

import Registration from 'components/Registration'
import { UnauthorizedRoute } from 'routes'

const SignUpPage: FC = () => {
  return (
    <UnauthorizedRoute>
      <Registration />
    </UnauthorizedRoute>
  )
}

export default SignUpPage
