import { FC } from 'react'
import { DefectsReportFault } from 'components/Defects'
import { UserProtectedRoute } from 'routes'

const FaultsReportsPage: FC = () => {
  return (
    <UserProtectedRoute>
      <DefectsReportFault />
    </UserProtectedRoute>
  )
}

export default FaultsReportsPage
