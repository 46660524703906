import { FC, useEffect } from 'react'
import { TextField, Button, Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { LoadingButton } from '@mui/lab'
import * as Yup from 'yup'
import { useQueryClient } from '@tanstack/react-query'
import { useIntl } from 'react-intl'
import { useUserPasswordRequestReset } from 'services/register/register.hooks'

const ResetPassword: FC = () => {
  const { formatMessage } = useIntl()
  const queryClient = useQueryClient()
  const navigation = useNavigate()
  const { passwordRequestReset, isSuccess, isError } = useUserPasswordRequestReset()

  const form = useFormik({
    initialValues: {
      email: ''
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(
          formatMessage({
            id: 'validation.email',
            defaultMessage: 'Niepoprawny adres email'
          })
        )
        .required(
          formatMessage({
            id: 'validation.required',
            defaultMessage: 'Wymagane'
          })
        )
    }),
    onSubmit: async (values, formik) => {
      formik.setSubmitting(true)
      await passwordRequestReset(values.email)
      formik.setSubmitting(false)
    }
  })

  useEffect(() => {
    queryClient.invalidateQueries()
    // eslint-disable-next-line
  }, [])

  if (isSuccess || isError) {
    return (
      <Box>
        <Typography variant='h5' fontWeight={600}>
          {formatMessage({
            id: 'resetPassword.link',
            defaultMessage: 'Link został wysłany.'
          })}
        </Typography>
        <Button
          onClick={() => navigation('/auth/sign-in')}
          color='secondary'
          fullWidth
          size='large'
          variant='text'
          sx={{ mt: 1, textTransform: 'none', fontWeight: '400' }}
        >
          {formatMessage({
            id: 'resetPassword.signIn',
            defaultMessage: 'Wróć do logowania'
          })}
        </Button>
      </Box>
    )
  }

  return (
    <form onSubmit={form.handleSubmit}>
      <TextField
        name='email'
        value={form.values.email}
        onChange={form.handleChange}
        fullWidth
        label={formatMessage({
          id: 'resetPassword.form.email',
          defaultMessage: 'Email'
        })}
        placeholder={formatMessage({
          id: 'resetPassword.form.email',
          defaultMessage: 'Email'
        })}
        disabled={form.isSubmitting}
        sx={{ mb: 3 }}
        error={!!form.errors.email}
        helperText={!!form.errors.email && form.errors.email}
      />
      <LoadingButton
        fullWidth
        size='large'
        variant='contained'
        sx={{ mb: 1 }}
        type='submit'
        loading={form.isSubmitting}
      >
        {formatMessage({
          id: 'resetPassword.form.submit',
          defaultMessage: 'Resetuj hasło'
        })}
      </LoadingButton>
      <Button
        onClick={() => navigation('/auth/sign-in')}
        color='secondary'
        fullWidth
        size='large'
        variant='text'
        sx={{ mb: 1, textTransform: 'none', fontWeight: '400' }}
      >
        {formatMessage({
          id: 'resetPassword.signIn',
          defaultMessage: 'Wróć do logowania'
        })}
      </Button>
    </form>
  )
}

export default ResetPassword
