import { FC, ReactNode } from 'react'
import { Box, useMediaQuery, useTheme } from '@mui/material'

const MobileBottomFooter: FC<{ children: ReactNode }> = ({ children }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  if (isDesktop) {
    return <Box sx={{ px: 2, mt: 2 }}>{children}</Box>
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        paddingBottom: 'calc(2rem + env(safe-area-inset-bottom))',
        px: 2,
        backgroundColor: 'background.default'
      }}
    >
      {children}
    </Box>
  )
}

export default MobileBottomFooter
