import React from 'react'
import ReactDOM from 'react-dom/client'
import ThemeProvider from 'styles/ThemeProvider'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SnackbarProvider } from 'notistack'
import * as Sentry from '@sentry/capacitor'
import * as SentryReact from '@sentry/react'

import Router from 'routes/Router'
import IntlProvider from 'components/IntlProvider'
import env from 'consts/env'

import 'dayjs/locale/pl'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 3600000, // 1 hour
      cacheTime: 3600000, // 1 hour
      retry: false
    }
  }
})

if (env.IS_PRODUCTION && !!env.SENTRY_DSN) {
  Sentry.init(
    {
      dsn: env.SENTRY_DSN,
      integrations: [SentryReact.browserTracingIntegration(), SentryReact.replayIntegration()],
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', env.API_BASE_URL as string],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    },
    // Forward the init method from @sentry/react
    SentryReact.init
  )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <IntlProvider>
        <ThemeProvider>
          <SnackbarProvider
            autoHideDuration={6000}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
            <Router />
          </SnackbarProvider>
        </ThemeProvider>
      </IntlProvider>
    </QueryClientProvider>
  </React.StrictMode>
)
