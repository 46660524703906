import { FC } from 'react'
import { useIntl } from 'react-intl'
import { Box, Button, Link, Typography, useMediaQuery } from '@mui/material'
import { BackNavigationContent } from 'components/BackNavigationHeader'
import { ReactComponent as Icon } from 'static/images/SuccessImage.svg'
import theme from 'styles/theme'

const UserPersonalDataSuccess: FC = () => {
  const { formatMessage } = useIntl()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <BackNavigationContent
      height='100vh'
      display='flex'
      justifyContent='center'
      alignContent='space-between'
      flexWrap='wrap'
    >
      <Box
        width={isDesktop ? '515px' : '100%'}
        display='flex'
        height='100%'
        justifyContent='center'
        alignContent={isDesktop ? 'center' : 'space-between'}
        flexWrap='wrap'
        gap={isDesktop ? 3 : 0}
      >
        <Box
          sx={{ mt: isDesktop ? 0 : 6 }}
          display='flex'
          alignItems='center'
          justifyContent='center'
          flexDirection='column'
          flexWrap='wrap'
          gap={isDesktop ? 5 : 0}
        >
          <Icon width={277} />
          <Typography variant='h5' fontWeight={600} align='center' width='90%'>
            {formatMessage({
              id: 'userDataConfirmationForm.successMessage',
              defaultMessage: 'Dziękujemy za wypełnienie formularza!'
            })}
          </Typography>
        </Box>
        <Link href='/' width='100%'>
          <Button size='large' fullWidth variant='contained' sx={{ mb: 1, mt: 1 }}>
            {formatMessage({
              id: 'userDataConfirmationForm.form.backToApp',
              defaultMessage: 'Wróć do aplikacji'
            })}
          </Button>
        </Link>
      </Box>
    </BackNavigationContent>
  )
}

export default UserPersonalDataSuccess
