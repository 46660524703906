import { FC } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useParams, Link } from 'react-router-dom'
import { CONTRACT_DOCUMENT_TYPE } from 'services/contracts/contracts.consts'
import AdditionalServicesOnlinePaymentButton from 'components/AdditionalServices/AdditionalServicesOnlinePaymentButton'
import { useIntl } from 'react-intl'
import { useLeaseContractsDepositAccountBalance } from 'services/contracts/hooks/useLeaseContractsDepositAccountBalance'

const DepositPayPage: FC = () => {
  const { id } = useParams()
  const { formatMessage } = useIntl()
  const { leaseContractsDepositAccountBalance } = useLeaseContractsDepositAccountBalance()

  return (
    <Box p={4}>
      <Box>
        <Box>
          <Typography variant='h4'>
            {formatMessage({
              id: 'paymentScheduleDocumnetTitle.deposit',
              defaultMessage: 'Depopzyt'
            })}
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <Typography variant='h5'>
            {formatMessage({
              id: 'paymentScheduleDocumnet.receivable',
              defaultMessage: 'Należność'
            })}
            <Typography fontWeight={600} mb={2}>
              {leaseContractsDepositAccountBalance.depositBalanceValue}
            </Typography>
          </Typography>
        </Box>
      </Box>
      <Box display='flex' justifyContent='space-between' mt={4}>
        <Button component={Link} to='/'>
          Idź do panelu
        </Button>
        <AdditionalServicesOnlinePaymentButton
          documentType={CONTRACT_DOCUMENT_TYPE.deposit}
          documentId={Number(id)}
        />
      </Box>
    </Box>
  )
}

export default DepositPayPage
