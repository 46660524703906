import { FC } from 'react'
import DesktopNavigation from 'components/DesktopNavigation'
import { UserProtectedRoute } from 'routes'
import Dashboard from 'layouts/Dashboard'
import { OfferList } from 'components/Offer'
import RestrictedForDesktop from 'components/RestrictedForDesktop'

const OfferPage: FC = () => {
  return (
    <UserProtectedRoute>
      <Dashboard>
        <DesktopNavigation label='' isMainRoute />
        <RestrictedForDesktop>
          <OfferList />
        </RestrictedForDesktop>
      </Dashboard>
    </UserProtectedRoute>
  )
}

export default OfferPage
