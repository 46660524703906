import { FC, useState } from 'react'
import { Box, Button } from '@mui/material'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'
import ResponsiveFullScreenDialog from 'components/ResponsiveFullScreenDialog'
import { useIntl } from 'react-intl'
import PasswordField from 'components/PasswordField'
import { useUserPassword } from 'services/profile/profile.hooks'

const UserPassword: FC = () => {
  const [isOpen, setOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { useUserPasswordUpdate } = useUserPassword()
  const { formatMessage } = useIntl()

  const form = useFormik<{
    currentPassword: string
    newPassword: string
    retypeNewPassword: string
  }>({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      retypeNewPassword: ''
    },
    onSubmit: async ({ currentPassword, newPassword, retypeNewPassword }, { setSubmitting }) => {
      setSubmitting(true)
      try {
        await useUserPasswordUpdate.mutateAsync({
          currentPassword,
          newPassword,
          retypeNewPassword
        })
        enqueueSnackbar(
          formatMessage({
            id: 'userPassword.success',
            defaultMessage: 'Hasło zostało zmienione.'
          }),
          {
            variant: 'success'
          }
        )
        setOpen(false)
      } catch (e: any) {
        if (e.response.data) {
          form.setErrors(e.response.data)
        }
        enqueueSnackbar(
          formatMessage({
            id: 'userPassword.error',
            defaultMessage: 'Nie można zmienić hasła.'
          }),
          {
            variant: 'error'
          }
        )
      }
      setSubmitting(false)
    }
  })

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        {formatMessage({
          id: 'userPassword.changePassword',
          defaultMessage: 'Zmień hasło'
        })}
      </Button>
      <ResponsiveFullScreenDialog isOpen={isOpen} onClose={() => setOpen(false)}>
        <Box>
          <PasswordField
            name='currentPassword'
            fullWidth
            sx={{ mb: 3 }}
            label={formatMessage({
              id: 'userPassword.currentPassword',
              defaultMessage: 'Obecne hasło'
            })}
            disabled={form.isSubmitting}
            onChange={form.handleChange}
            value={form.values.currentPassword}
            error={!!form.errors.currentPassword && !!form.touched.currentPassword}
            helperText={form.errors.currentPassword}
          />
          <PasswordField
            name='newPassword'
            fullWidth
            sx={{ mb: 3 }}
            label={formatMessage({
              id: 'userPassword.newPassword',
              defaultMessage: 'Hasło'
            })}
            disabled={form.isSubmitting}
            onChange={form.handleChange}
            value={form.values.newPassword}
            error={!!form.errors.newPassword && !!form.touched.newPassword}
            helperText={form.errors.newPassword}
          />
          <PasswordField
            name='retypeNewPassword'
            fullWidth
            sx={{ mb: 3 }}
            label={formatMessage({
              id: 'userPassword.retypeNewPassword',
              defaultMessage: 'Potwierdz Hasło'
            })}
            helperText={
              form.errors.currentPassword ||
              formatMessage({
                id: 'userPassword.retypeNewPasswordHelperText',
                defaultMessage: 'Minimum 8 znaków, minimum jeden znak specjalny (@,#,$)'
              })
            }
            disabled={form.isSubmitting}
            onChange={form.handleChange}
            value={form.values.retypeNewPassword}
            error={!!form.errors.retypeNewPassword && !!form.touched.retypeNewPassword}
          />
          <LoadingButton
            onClick={form.submitForm}
            loading={form.isSubmitting}
            fullWidth
            sx={{ mt: 6 }}
            variant='contained'
          >
            {formatMessage({
              id: 'userPassword.submit',
              defaultMessage: 'Zapisz'
            })}
          </LoadingButton>
        </Box>
      </ResponsiveFullScreenDialog>
    </>
  )
}

export default UserPassword
