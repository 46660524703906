import { FC, ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAccessToken } from 'services/token/token.hooks'

interface UserProtectedRouteProps {
  children: ReactNode
}

const UserProtectedRoute: FC<UserProtectedRouteProps> = ({ children }) => {
  const { accessToken } = useAccessToken()
  const { pathname } = useLocation()

  if (!!accessToken) {
    return <>{children}</>
  }

  if (pathname !== '/') {
    return <Navigate to={`/auth/sign-in?to=${pathname}`} />
  }

  return <Navigate to='/auth/sign-in' />
}

export default UserProtectedRoute
