import { useMutation } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useAccessToken } from 'services/token/token.hooks'

export const useOfferMessagesCreate = () => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()

  const { mutateAsync, ...rest } = useMutation({
    mutationFn: ({ message, offerId }: { message: string; offerId: number }) => {
      const form = new FormData()

      form.append('message', message)

      return apiInstance({
        url: `/projects/offers/${offerId}/messages/`,
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined,
          'Content-Type': 'multipart/form-data'
        },
        data: form,
        transformRequest: (data) => {
          return data
        }
      })
    }
  })

  return { createMessage: mutateAsync, ...rest }
}
