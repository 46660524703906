import { Box, CircularProgress, Typography } from '@mui/material'
import BackNavigationHeader from 'components/BackNavigationHeader'
import DesktopNavigation from 'components/DesktopNavigation'
import Section from 'components/Section'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { useOfferListOffer } from 'services/offer/hooks/useOfferList'

const OfferPriceDetails: FC = () => {
  const { id } = useParams()
  const { formatMessage } = useIntl()
  const { data: offerDetail = [], isFetching, isLoading } = useOfferListOffer(id)
  const { offerData, flat } = offerDetail

  if (isFetching || isLoading) {
    return (
      <Section sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress />
      </Section>
    )
  }
  const isDiscount = +offerData.price < flat.offerRentPln
  const parkingsPriceTotal = offerData?.parkings.reduce(
    (acc: any, parking: { price: string; parkingType: number }) => acc + parseFloat(parking.price),
    0
  )
  const storagesPriceTotal = offerData?.storages.reduce(
    (acc: any, parking: { price: string; storageType: number }) => acc + parseFloat(parking.price),
    0
  )
  const totalPrice = isDiscount
    ? +flat?.offerRentPln + parkingsPriceTotal + storagesPriceTotal + +flat.commonAreaChargePln
    : +offerData.price + parkingsPriceTotal + storagesPriceTotal + +flat.commonAreaChargePln

  return (
    <>
      <BackNavigationHeader
        label={formatMessage({
          id: flat.investmentType === 'standard' ? 'offer.apartment' : 'offer.flat',
          defaultMessage: flat.investmentType === 'standard' ? 'Mieszkanie' : 'Lokal'
        })}
      />
      <DesktopNavigation label={offerDetail.flat.address} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          marginTop: '100px',
          paddingBottom: '24px',
          paddingLeft: '22px',
          paddingRight: '13px'
        }}>
        {isDiscount && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              sx={{ fontSize: '14px', fontWeight: 600, lineHeight: '21px', color: '#00c400' }}>
              {formatMessage({
                id:
                  flat.investmentType === 'standard'
                    ? 'offer.rentAfterDiscount'
                    : 'offer.basicFeeAfterDiscount',
                defaultMessage: flat.investmentType === 'standard' ? 'Mieszkanie' : 'Lokal'
              })}
            </Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: 500, lineHeight: '21px' }}>
              {+flat?.offerRentPln}
            </Typography>
          </Box>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '21px',
              textDecoration: isDiscount ? 'line-through' : '',
              color: isDiscount ? '#707070' : '#000'
            }}>
            {formatMessage({
              id: flat.investmentType === 'standard' ? 'offer.rent' : 'offer.basicFee',
              defaultMessage: flat.investmentType === 'standard' ? 'Mieszkanie' : 'Lokal'
            })}
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 500, lineHeight: '21px' }}>
            {+offerData.price}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ fontSize: '14px', fontWeight: 600, lineHeight: '21px' }}>
            {formatMessage({
              id: 'offer.commonAreaChargePln',
              defaultMessage: 'Utrzymanie części wspólnych'
            })}
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 500, lineHeight: '21px' }}>
            {+flat.commonAreaChargePln}
          </Typography>
        </Box>
        {offerData?.storages?.map((storage: { id: number; price: string }) => (
          <Box key={storage.id} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: '14px', fontWeight: 600, lineHeight: '21px' }}>
              {formatMessage({
                id: 'offer.storage',
                defaultMessage: 'Pomieszczenie Gospodarcze'
              })}
            </Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: 500, lineHeight: '21px' }}>
              {+storage.price}
            </Typography>
          </Box>
        ))}
        {offerData.parkings.map((parking: { id: number; price: string; parkingType: number }) => (
          <Box key={parking.id} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: '14px', fontWeight: 600, lineHeight: '21px' }}>
              {formatMessage({
                id: `${parking.parkingType === 0 || 1 || 2 ? 'offer.parkingOutdoor' : 'offer.parkingUnderground'}`,
                defaultMessage: `${parking.parkingType === 0 || 1 || 2 ? 'Parking (miejsce naziemne)' : 'Parking (miejsce podziemne)'}`
              })}
            </Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: 500, lineHeight: '21px' }}>
              {+parking.price}
            </Typography>
          </Box>
        ))}
        <Typography sx={{ fontSize: '14px', fontWeight: 600, color: '#8F9BB3' }}>
          {formatMessage({
            id: 'offer.Media',
            defaultMessage:
              '* Media ( Prąd, Woda, Ogrzewanie, śmieci), płatne dodatkowo na podstawie zużycia'
          })}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            borderTop: '1px solid #CDCDCD',
            paddingTop: '22px'
          }}>
          <Box />
          <Box sx={{ display: 'flex', gap: '10px', alignItems: 'end' }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 500, lineHeight: '25px' }}>
              {formatMessage({
                id: 'offer.sum',
                defaultMessage: 'Suma'
              })}
            </Typography>
            <Typography sx={{ fontSize: '27px', fontWeight: 600, lineHeight: '38px' }}>
              {totalPrice} zł
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default OfferPriceDetails
