import { FC } from 'react'
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import dayjs from 'dayjs'
// import { useIntl } from 'react-intl'
import { useLanguage } from 'services/i18n/i18n.hooks'

const ProtocolMetersReadings: FC<{
  readings: {
    id: number
    meterType: string
    meterPhoto: string
    meterValue: number
    readingDate: string
  }[]
}> = ({ readings }) => {
  // const { formatMessage } = useIntl()
  const { dateAdapter } = useLanguage()

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>Typ</TableCell>
            <TableCell sx={{ fontWeight: 700 }} align='right'>
              Data odczytu
            </TableCell>
            <TableCell sx={{ fontWeight: 700 }} align='right'>
              Wartość odczytu
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody></TableBody>
        {readings
          .filter(({ meterType }) => meterType !== 'Gas')
          .map((reading) => (
            <TableRow key={reading.id}>
              <TableCell component='th' scope='row'>
                {reading.meterType}
              </TableCell>
              <TableCell align='right'>
                {!!reading.readingDate
                  ? dayjs(reading.readingDate).locale(dateAdapter).format('DD.MM.YYYY')
                  : 'n/a'}
              </TableCell>
              <TableCell align='right'>
                {!!reading.meterValue ? reading.meterValue : 'n/a'}
              </TableCell>
            </TableRow>
          ))}
      </Table>
    </TableContainer>
  )
}

export default ProtocolMetersReadings
