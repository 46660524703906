import { FC } from 'react'

// import { Box, Typography, Button, Badge } from "@mui/material";
// import { ErrorOutlineOutlined, ArrowForward } from "@mui/icons-material";

// import Section from "components/Section";

const WidgetPayments: FC = () => {
  return null

  // return (
  //   <Section sx={{ flexDirection: "column", alignItems: "flex-start" }}>
  //     <Typography
  //       display="flex"
  //       alignItems="center"
  //       justifyContent="flex-start"
  //       fontWeight={500}
  //       mb={2}
  //     >
  //       <ErrorOutlineOutlined color="disabled" sx={{ mr: 1 }} />
  //       Do opłacenia
  //     </Typography>
  //     <Typography
  //       display="flex"
  //       alignItems="center"
  //       justifyContent="flex-start"
  //       fontWeight={500}
  //       variant="h5"
  //       mb={3}
  //     >
  //       1200 zł
  //       <Badge badgeContent="-20%" sx={{ ml: 4 }} />
  //     </Typography>
  //     <Button fullWidth variant="contained" size="large" sx={{ mb: 2 }}>
  //       OPŁAć
  //     </Button>
  //     <Box sx={{ textAlign: "right", width: "100%" }}>
  //       <Button endIcon={<ArrowForward />}>DANE DO PRZELEWU</Button>
  //     </Box>
  //   </Section>
  //  );
}

export default WidgetPayments
