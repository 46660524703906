import { Avatar, Box, TextField } from '@mui/material'
import { FC } from 'react'
import { useUsersProfile } from 'services/profile/profile.hooks'

import UserPassword from './UserPassword'
import { useIntl } from 'react-intl'
import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import { DeactivateAccount } from 'components/DeactivateAccount'

const UserAccount: FC = () => {
  const { userInitials, data: user } = useUsersProfile()
  const { formatMessage } = useIntl()
  return (
    <>
      <BackNavigationHeader
        label={formatMessage({
          id: 'userAccount.label',
          defaultMessage: 'Zarządzanie kontem'
        })}
      />
      <BackNavigationContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mb: 3,
            pt: 'env(safe-area-inset-top)'
          }}>
          <Avatar sx={{ width: 85, height: 85 }}>{userInitials}</Avatar>
        </Box>
        <TextField value={user?.email} disabled fullWidth sx={{ mb: 3 }} />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
          <UserPassword />
        </Box>
        <DeactivateAccount />
      </BackNavigationContent>
    </>
  )
}

export default UserAccount
