import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { apiInstance } from 'services/api'
import { useSnackbar } from 'notistack'

import { useAccessToken } from 'services/token/token.hooks'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useTermsOfUse } from 'services/terms/terms.hooks'

interface UsersProfileResponse {
  id: number
  email: string
  mobile: string
  firstName: string
  lastName: string
  pesel: string
  idCard: string
  passportId: string
  nationality: string
  dateOfBirth: string
  placeOfBirth: string
  residenceCardNumber: string
}

const useUsersProfile = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  const usersProfile = useQuery({
    queryKey: ['usersProfile', masquaradeToken],
    queryFn: () =>
      apiInstance<UsersProfileResponse>({
        url: '/users/profile/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && hasUserConsented
  })

  const usersProfilePartialUpdate = useMutation({
    mutationFn: (profile: Partial<UsersProfileResponse>) =>
      apiInstance<UsersProfileResponse>({
        url: '/users/profile/',
        method: 'PATCH',
        data: profile,
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    onSuccess: (data) => {
      queryClient.setQueryData(['usersProfile', masquaradeToken], data)
      enqueueSnackbar('Wniosek został złożony poprawnie.', {
        variant: 'success'
      })
    },
    onError: (error) => {
      enqueueSnackbar('Wystąpił błąd', {
        variant: 'error' as any,
        bugDetails: (error as any)?.response?.data
          ? JSON.stringify((error as any)?.response?.data)
          : undefined
      })
    }
  })

  const userInitials = useMemo(() => {
    if (!!usersProfile.data) {
      let initials = ''
      if (!!usersProfile.data.firstName) {
        initials += usersProfile.data.firstName[0]
      }
      if (!!usersProfile.data.lastName) {
        initials += usersProfile.data.lastName[0]
      }
      return initials
    }

    return ''
  }, [usersProfile.data])

  return {
    ...usersProfile,
    userInitials,
    usersProfilePartialUpdate
  }
}

export default useUsersProfile
