import AdditionalServicesBookingAgreement from 'components/AdditionalServices/AdditionalServicesBookingAgreement'
import DesktopNavigation from 'components/DesktopNavigation'
import { FC } from 'react'
import { UserProtectedRoute } from 'routes'

const AdditionalServicesBookingAgreementPage: FC = () => {
  return (
    <UserProtectedRoute>
      <DesktopNavigation label='Umowa rezerwacyjna' desktopNavigateTo='/additional-services' />
      <AdditionalServicesBookingAgreement />
    </UserProtectedRoute>
  )
}

export default AdditionalServicesBookingAgreementPage
