import { FC, ReactNode } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Box, useMediaQuery, useTheme } from '@mui/material'

import Dashboard from 'layouts/Dashboard'

interface LimitedMobileNavigationLayoutProps {
  restrictedPaths: string[]
  navigationComponent: ReactNode
}

const LimitedMobileNavigationLayout: FC<LimitedMobileNavigationLayoutProps> = ({
  restrictedPaths,
  navigationComponent
}) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const { pathname } = useLocation()

  if (isDesktop) {
    return (
      <Dashboard>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
          <Box
            sx={{
              width: '100%',
              minWidth: '320px',
              maxWidth: '480px',
              minHeight: 'calc(100vh - 180px)',
              maxHeight: 'calc(100vh - 180px)',
              borderRight: '1px solid #E0E0E0',
              pr: 3,
              position: 'sticky',
              top: '90px',
              overflow: 'auto'
            }}>
            {navigationComponent}
          </Box>
          <Box sx={{ width: '100%', pl: 4, maxWidth: '960px', margin: '0 auto' }}>
            <Outlet />
          </Box>
        </Box>
      </Dashboard>
    )
  }

  if (restrictedPaths.includes(pathname)) {
    return (
      <Dashboard>
        <Outlet />
      </Dashboard>
    )
  }

  return <Outlet />
}

export default LimitedMobileNavigationLayout
