import { Outlet } from 'react-router-dom'
import { Box, Paper } from '@mui/material'

import { ReactComponent as Logo } from 'static/images/Logo-R4R-Square-White.svg'
import BoxWithSafeZone from 'components/BoxWithSafeZone'

const Auth = () => {
  return (
    <BoxWithSafeZone
      sx={{
        backgroundColor: 'secondary.main',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        px: 2
      }}
    >
      <Box textAlign='center'>
        <Logo />
      </Box>
      <Paper
        sx={{
          py: 4,
          width: '100%',
          maxWidth: '620px',
          margin: '0 auto'
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '315px',
            margin: '0 auto',
            textAlign: 'center'
          }}
        >
          <Outlet />
        </Box>
      </Paper>
    </BoxWithSafeZone>
  )
}

export default Auth
