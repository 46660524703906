import { useMutation, useQuery } from '@tanstack/react-query'
import { enqueueSnackbar } from 'notistack'
import { apiInstance } from 'services/api'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useAccessToken } from 'services/token/token.hooks'

interface BankAccountResponse {
  hasForeignBankAccount: boolean
  bankAccountIban: string
  bankAccountSwift: string
  bankName: string
  bankAccountData: string
}

export const useUserBankAccount = () => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()

  const { data: userBankAccount } = useQuery({
    queryKey: ['userBankAccount', masquaradeToken],
    queryFn: () =>
      apiInstance<BankAccountResponse>({
        url: `/users/profile/bank-account/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken
  })

  const usersProfileBankAccountPartialUpdate = useMutation({
    mutationFn: (bank: Partial<BankAccountResponse>) =>
      apiInstance<BankAccountResponse>({
        url: `/users/profile/bank-account/`,
        method: 'PATCH',
        data: bank,
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    onSuccess: () => {
      enqueueSnackbar('Dane bankowe zostały zaktualizowane', {
        variant: 'success'
      })
    },
    onError: (error) => {
      enqueueSnackbar('Wystąpił błąd', {
        variant: 'error' as any,
        bugDetails: (error as any)?.response?.data
          ? JSON.stringify((error as any)?.response?.data)
          : undefined
      })
    }
  })

  return {
    userBankAccount,
    usersBankAccountUpdate: usersProfileBankAccountPartialUpdate
  }
}
