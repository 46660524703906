import { FC } from 'react'
import DesktopNavigation from 'components/DesktopNavigation'
import { UserProtectedRoute } from 'routes'
import BoardsPost from 'components/BoardsPost'

const BoardsPostPage: FC = () => {
  return (
    <UserProtectedRoute>
      <DesktopNavigation label='Podstawowe informacje' desktopNavigateTo='/' />
      <BoardsPost />
    </UserProtectedRoute>
  )
}

export default BoardsPostPage
