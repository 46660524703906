import { FC } from 'react'
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import { KeyboardArrowRight, AttachFileOutlined } from '@mui/icons-material'
import { useIntl } from 'react-intl'
import { useDocumentsList } from 'services/documents/documents.hooks'

import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import InfoIndicator from 'components/InfoIndicator'

const AdditionalServicesAttachments: FC = () => {
  const { formatMessage } = useIntl()
  const { data: attachmentsList = [], isFetching, isLoading } = useDocumentsList()

  const onClick = (href: string) => {
    window.open(href, '_blank')
  }

  return (
    <>
      <BackNavigationHeader
        label={formatMessage({
          id: 'additionalServicesAttachments.attachments',
          defaultMessage: 'Załączniki'
        })}
      />
      <BackNavigationContent>
        <List
          sx={{
            '.MuiListItem-root:first-of-type': {
              borderTop: '1px solid #CDCDCD'
            }
          }}
        >
          {attachmentsList
            .filter(({ displayInHelp }) => !displayInHelp)
            .map(({ name, id, file }) => (
              <ListItem
                key={id}
                disablePadding
                sx={{
                  borderBottom: '1px solid #CDCDCD'
                }}
                secondaryAction={<KeyboardArrowRight color='disabled' />}
                onClick={() => onClick(file)}
              >
                <ListItemButton sx={{ py: 2 }}>
                  <ListItemText primary={name} />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
        <InfoIndicator
          helperText={formatMessage({
            id: 'additionalServicesAttachments.noAttachments',
            defaultMessage: 'Brak załączników.'
          })}
          Icon={AttachFileOutlined}
          isVisible={!attachmentsList.length && !isFetching && !isLoading}
        />
      </BackNavigationContent>
    </>
  )
}

export default AdditionalServicesAttachments
