import { FC, useState } from 'react'
import { Typography, Box, Badge, IconButton, Button } from '@mui/material'
import { ExpandLess, ExpandMore, ReceiptLongOutlined } from '@mui/icons-material'
import AdditionalServicesPaymentDetailsButton from 'components/AdditionalServices/AdditionalServicesPaymentDetailsButton'
import AdditionalServicesOnlinePaymentButton from 'components/AdditionalServices/AdditionalServicesOnlinePaymentButton'

import Section from 'components/Section'
import { CONTRACT_DOCUMENT_TYPE, ContractDocumentTypes } from 'services/contracts/contracts.consts'
import { useIntl } from 'react-intl'

type itemProps = {
  id: number
  title: string
  amountGross: string
  amount: string
}

interface PaymentScheduleDocumnetProps {
  documentId: number
  amount: string
  data: any
  type: ContractDocumentTypes
  number?: string
  discount?: number
  file?: string
  items?: itemProps[]
  currency?: string
  isPaid: boolean
  paidAmount: string
}

interface PaymentScheduleDocumnetTitleProps {
  type: ContractDocumentTypes
}

const keywords = [
  'Ogrzewanie i podgrzanie wody',
  'Dostawa wody',
  'Dostawa energii elektrycznej',
  'Klimatyzacja'
]

const PaymentScheduleDocumnetTitle: FC<PaymentScheduleDocumnetTitleProps> = ({ type }) => {
  const { formatMessage } = useIntl()
  switch (type) {
    case CONTRACT_DOCUMENT_TYPE.debitNote:
      return (
        <Typography variant='body2'>
          {formatMessage({
            id: 'paymentScheduleDocumnetTitle.debitNote',
            defaultMessage: 'Nota obciążeniowa'
          })}
        </Typography>
      )
    case CONTRACT_DOCUMENT_TYPE.interestNote:
      return (
        <Typography variant='body2'>
          {formatMessage({
            id: 'paymentScheduleDocumnetTitle.interestNote',
            defaultMessage: 'Nota odsetkowa'
          })}
        </Typography>
      )
    default:
      return (
        <Typography variant='body2'>
          {formatMessage({
            id: 'paymentScheduleDocumnetTitle.invoice',
            defaultMessage: 'Faktura VAT'
          })}
        </Typography>
      )
  }
}

const PaymentScheduleDocumnet: FC<PaymentScheduleDocumnetProps> = ({
  documentId,
  data,
  amount,
  number,
  type,
  discount,
  items = [],
  file,
  currency = '',
  isPaid,
  paidAmount
}) => {
  const { formatMessage } = useIntl()
  const [isExpand, setExpand] = useState(false)
  const [isMediaExpand, setIsMediaExpand] = useState(false)

  const getRelatedInvoices = (obj: any) => {
    let relatedInvoices = []

    while (obj.parent) {
      relatedInvoices.push({ id: obj.parent.id, number: obj.parent.number, file: obj.parent.file })
      obj = obj.parent
    }

    return relatedInvoices
  }

  const removeBrTags = (str: string) => {
    return str.replace(/<br>/g, '')
  }

  const relatedInvoices = getRelatedInvoices(data)

  const calculateTotalAmountGross = (data: itemProps[]) => {
    const total = data.reduce((sum, item) => sum + parseFloat(item.amountGross), 0)
    return total.toFixed(2)
  }

  const matchesMediaKeyword = (title: string) => {
    return keywords.some((keyword) => title.includes(keyword))
  }

  const mediaItems = items.filter((item: { title: string }) => matchesMediaKeyword(item.title))
  const nonMediaItems = items.filter((item: { title: string }) => !matchesMediaKeyword(item.title))

  return (
    <Section>
      <Box flex={1}>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <Box>
            <PaymentScheduleDocumnetTitle type={type} />
            <Typography variant='caption'>
              {number ||
                formatMessage({
                  id: 'paymentScheduleDocumnet.awaiting',
                  defaultMessage: 'Oczekuje na wystawienie'
                })}
            </Typography>
          </Box>
          <Box justifyContent='flex-end' alignItems='center' display='flex'>
            {!!discount && <Badge color='primary' badgeContent={discount} sx={{ mr: 4 }} />}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                flexDirection: 'column'
              }}>
              {!!amount && (
                <>
                  <Typography variant='caption' textAlign='right'>
                    {formatMessage({
                      id: 'paymentScheduleDocumnet.receivable',
                      defaultMessage: 'Należność'
                    })}
                    :
                  </Typography>
                  <Typography fontWeight={600} textAlign='right'>
                    {amount} {currency}
                  </Typography>
                </>
              )}

              {!isPaid && !!paidAmount && (
                <>
                  <Typography variant='caption' textAlign='right'>
                    {formatMessage({
                      id: 'paymentScheduleDocumnet.paid',
                      defaultMessage: 'Opłacono'
                    })}
                    :
                  </Typography>
                  <Typography fontWeight={600} textAlign='right'>
                    {paidAmount} {currency}
                  </Typography>
                </>
              )}
            </Box>

            {!!number && (
              <IconButton sx={{ ml: 1 }} onClick={() => setExpand((state) => !state)} size='small'>
                {isExpand ? <ExpandLess fontSize='small' /> : <ExpandMore fontSize='small' />}
              </IconButton>
            )}
          </Box>
        </Box>
        {isExpand && (
          <Box mt={2}>
            {nonMediaItems.map((item) => (
              <Box
                key={`${number}_${item.id}`}
                sx={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 1
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'
                  }}>
                  <Typography variant='body2'>{removeBrTags(item.title)}</Typography>
                  <Typography variant='body2' fontWeight={600} textAlign='right'>
                    {item.amountGross || item.amount} {currency}
                  </Typography>
                </Box>
              </Box>
            ))}
            <Box>
              {mediaItems.length > 0 && (
                <Box
                  sx={{
                    borderTop: '1px solid #CDCDCD',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    paddingTop: '10px'
                  }}>
                  <Typography variant='body2' fontWeight={600}>
                    {formatMessage({
                      id: ' paymentScheduleDocumnet.media',
                      defaultMessage: 'Media:'
                    })}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant='body2' fontWeight={600} textAlign='right'>
                      {calculateTotalAmountGross(mediaItems)} {currency}
                    </Typography>
                    <IconButton
                      sx={{ ml: 1 }}
                      onClick={() => setIsMediaExpand((state) => !state)}
                      size='small'>
                      {isMediaExpand ? (
                        <ExpandLess fontSize='small' />
                      ) : (
                        <ExpandMore fontSize='small' />
                      )}
                    </IconButton>
                  </Box>
                </Box>
              )}
              {isMediaExpand && (
                <Box sx={{ paddingTop: '10px' }}>
                  {mediaItems.map(
                    (item: { id: number; title: string; amountGross: string; amount: string }) => (
                      <Box
                        key={`${number}_${item.id}`}
                        sx={{
                          flex: 1,
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          mb: 1
                        }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%'
                          }}>
                          <Typography variant='body2'>{removeBrTags(item.title)}</Typography>
                          <Typography variant='body2' fontWeight={600} textAlign='right'>
                            {item.amountGross || item.amount} {currency}
                          </Typography>
                        </Box>
                      </Box>
                    )
                  )}
                </Box>
              )}
            </Box>
            <Box mt={2}>
              {!isPaid && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  {['PLN'].includes(currency) && (
                    <AdditionalServicesOnlinePaymentButton
                      documentType={type}
                      documentId={documentId}
                    />
                  )}
                  <AdditionalServicesPaymentDetailsButton amount={amount} paidAmount={paidAmount} />
                </Box>
              )}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                  width: '100%'
                }}>
                {!!file && (
                  <Button
                    startIcon={<ReceiptLongOutlined />}
                    href={file}
                    target='_blank'
                    sx={{ textDecoration: 'none', fontSize: '0.875rem' }}>
                    {formatMessage({
                      id: 'paymentScheduleDocumnet.invoice',
                      defaultMessage: 'Faktura'
                    })}
                  </Button>
                )}
                {relatedInvoices.length > 0 && (
                  <Box sx={{ marginTop: '10px' }}>
                    <Typography>
                      {formatMessage({
                        id: 'additionalServicesPaymentSchedule.relatedInvoices',
                        defaultMessage: 'faktury powiązane:'
                      })}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                      {relatedInvoices?.map((invoice) => (
                        <Button
                          key={invoice.id}
                          startIcon={<ReceiptLongOutlined />}
                          href={invoice.file}
                          target='_blank'
                          sx={{ textDecoration: 'none', fontSize: '0.875rem' }}>
                          {invoice.number}
                        </Button>
                      ))}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Section>
  )
}

export default PaymentScheduleDocumnet
