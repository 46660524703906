import { FC, ReactNode } from 'react'
import { useMediaQuery, useTheme, Box } from '@mui/material'
import { Navigate } from 'react-router-dom'

interface RestrictedForDesktopProps {
  children: ReactNode
  replacement?: ReactNode
  redirectTo?: string
}

const RestrictedForDesktop: FC<RestrictedForDesktopProps> = ({
  children,
  redirectTo,
  replacement = null
}) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  if (isDesktop) {
    if (!!redirectTo) {
      return <Navigate to={redirectTo} replace />
    }

    return <>{replacement}</>
  }

  return <Box px={isDesktop ? 0 : 2}>{children}</Box>
}

export default RestrictedForDesktop
