import { useQuery } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useAccessToken } from 'services/token/token.hooks'

export const useInvoicePaymentDetails = ({
  id,
  leaseContractId
}: {
  leaseContractId: number
  id: number
}) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()

  const { data: invoicePaymentDetails, ...rest } = useQuery({
    queryKey: ['invoicePaymentDetails', id, leaseContractId, masquaradeToken],
    queryFn: () =>
      apiInstance<any>({
        url: `/lease/contracts/${leaseContractId}/invoices/${id}/payment-details`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken
  })

  return { invoicePaymentDetails, ...rest }
}

interface InvoiceDetails {
  id: number
  number: string
  totalAmountGross: string
  dateOfIssue: string
  discount: number
  items: {
    id: number
    title: string
    amountGross: string
  }[]
  file: string
  currency: string
  paidAmount: string
  isPaid: false
}

export const useInvoice = ({ id, leaseContractId }: { leaseContractId: number; id: number }) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()

  const { data: invoiceDetails, ...rest } = useQuery({
    queryKey: ['invoiceDetails', id, leaseContractId, masquaradeToken],
    queryFn: () =>
      apiInstance<InvoiceDetails>({
        url: `/lease/contracts/${leaseContractId}/invoices/${id}`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken
  })

  return { invoiceDetails, ...rest }
}

export const useInterestNotesPaymentDetails = ({
  id,
  leaseContractId
}: {
  leaseContractId: number
  id: number
}) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()

  const { data: interestNotesPaymentDetails, ...rest } = useQuery({
    queryKey: ['interestNotesPaymentDetails', id, masquaradeToken],
    queryFn: () =>
      apiInstance<any>({
        url: `/lease/contracts/${leaseContractId}/interest-notes/${id}/payment-details`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken
  })

  return { interestNotesPaymentDetails, ...rest }
}

interface InterestNoteDetails {
  id: number
  number: string
  amount: string
  dateOfIssue: string
  file: string
  currency: string
  paymentAmount: string
  isPaid: boolean
}

export const useInterestNote = ({
  id,
  leaseContractId
}: {
  leaseContractId: number
  id: number
}) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()

  const { data: interestNoteDetails, ...rest } = useQuery({
    queryKey: ['interestNoteDetails', id, masquaradeToken],
    queryFn: () =>
      apiInstance<InterestNoteDetails>({
        url: `/lease/contracts/${leaseContractId}/interest-notes/${id}`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken
  })

  return { interestNoteDetails, ...rest }
}

export const useDepositPaymentDetails = (id: string) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()

  const { data: depositPaymentDetails, ...rest } = useQuery({
    queryKey: ['depositPaymentDetails', id, masquaradeToken],
    queryFn: () =>
      apiInstance<any>({
        url: `/lease/contracts/${id}/deposit-payment-details`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken
  })

  return { depositPaymentDetails, ...rest }
}

export const useDebitNotesPaymentDetails = ({
  id,
  leaseContractId
}: {
  leaseContractId: number
  id: number
}) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()

  const { data: debitNotesPaymentDetails, ...rest } = useQuery({
    queryKey: ['debitNotesPaymentDetails', id, masquaradeToken],
    queryFn: () =>
      apiInstance<any>({
        url: `/lease/contracts/${leaseContractId}/debit-notes/${id}/payment-details/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken
  })

  return { debitNotesPaymentDetails, ...rest }
}

interface DebitNoteDetails {
  id: number
  number: string
  amount: string
  dateOfIssue: string
  file: string
  currency: string
  positions: {
    id: number
    amount: string
    title: string
  }[]
  paymentAmount: string
  isPaid: boolean
}

export const useDebitNote = ({ id, leaseContractId }: { leaseContractId: number; id: number }) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()

  const { data: debitNoteDetails, ...rest } = useQuery({
    queryKey: ['debitNote', id, leaseContractId, masquaradeToken],
    queryFn: () =>
      apiInstance<DebitNoteDetails>({
        url: `/lease/contracts/${leaseContractId}/debit-notes/${id}`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken
  })

  return { debitNoteDetails, ...rest }
}
