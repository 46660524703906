import { FC } from 'react'
import { TextField, Autocomplete } from '@mui/material'
// import { LoadingButton } from "@mui/lab";

import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import { useUsersProfilDeliverAddressDuring } from 'services/profile/profile.hooks'
import { useCountriesList } from 'services/metadata/metadata.hooks'
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'

interface UserDetailsAddressDuringRentalProps {
  onBack: () => void
}

const UserDetailsAddressDuringRental: FC<UserDetailsAddressDuringRentalProps> = ({ onBack }) => {
  const { formatMessage } = useIntl()
  const { usersProfilDeliverAddressDuring, usersProfilDeliverAddressDuringUpdate } =
    useUsersProfilDeliverAddressDuring()
  const { countries, getCountryById } = useCountriesList()

  const addresseForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      street: usersProfilDeliverAddressDuring.street ?? '',
      city: usersProfilDeliverAddressDuring.city ?? '',
      zipcode: usersProfilDeliverAddressDuring.zipcode ?? '',
      country: getCountryById(usersProfilDeliverAddressDuring.countryId)
    },
    onSubmit: async ({ country, ...values }, { setSubmitting }) => {
      await usersProfilDeliverAddressDuringUpdate.mutate({
        ...values,
        countryId: country.id
      })

      setSubmitting(true)
    }
  })

  return (
    <form onSubmit={addresseForm.handleSubmit}>
      <BackNavigationHeader
        label={formatMessage({
          id: 'userDetailsAddressDuringRental.label',
          defaultMessage: 'Dane kontaktowe'
        })}
        onBack={onBack}
      />
      <BackNavigationContent>
        <TextField
          fullWidth
          sx={{ mb: 3, mt: 'calc(env(safe-area-inset-top) + 1rem)' }}
          name='street'
          label={formatMessage({
            id: 'userDetailsAddressDuringRental.form.street',
            defaultMessage: 'Ulica'
          })}
          value={addresseForm.values.street}
          onChange={addresseForm.handleChange}
          disabled
        />
        <TextField
          fullWidth
          sx={{ mb: 3 }}
          label={formatMessage({
            id: 'userDetailsAddressDuringRental.form.zipcode',
            defaultMessage: 'Kod pocztowy'
          })}
          name='zipcode'
          value={addresseForm.values.zipcode}
          onChange={addresseForm.handleChange}
          disabled
        />
        <TextField
          fullWidth
          sx={{ mb: 3 }}
          label={formatMessage({
            id: 'userDetailsAddressDuringRental.form.city',
            defaultMessage: 'Miasto'
          })}
          name='city'
          value={addresseForm.values.city}
          onChange={addresseForm.handleChange}
          disabled
        />
        <Autocomplete
          disablePortal
          sx={{ mb: 3 }}
          value={addresseForm.values.country as any}
          getOptionLabel={(option) => option.name}
          onChange={(_, value) => addresseForm.setFieldValue('country', value)}
          options={countries}
          renderInput={(params) => (
            <TextField
              {...params}
              label={formatMessage({
                id: 'userDetailsAddressDuringRental.form.country',
                defaultMessage: 'Kraj'
              })}
              fullWidth
              name='country'
            />
          )}
          disabled
        />
        {/* <LoadingButton
          fullWidth
          sx={{ mt: 6 }}
          variant="contained"
          loading={addresseForm.isSubmitting}
          type="submit"
        >
          ZAWNIOSKUJ O ZMIANĘ DANYCH
        </LoadingButton> */}
      </BackNavigationContent>
    </form>
  )
}

export default UserDetailsAddressDuringRental
