import { FC } from 'react'
import { useParams } from 'react-router-dom'

import AdditionalServicesMedia from './AdditionalServicesMedia'
import AdditionalServicesLease from './AdditionalServicesLease'

const AdditionalServicesMenu: FC = () => {
  const { id } = useParams()

  if (id) {
    return <AdditionalServicesMedia />
  }

  return <AdditionalServicesLease />
}

export default AdditionalServicesMenu
