import { LocalLaundryServiceOutlined } from '@mui/icons-material'

export enum FAULT_TOPICS {
  FLAT = 'Mieszkanie',
  SETTLEMENTS = 'Rozliczenia',
  URGENT = 'Pilne',
  BUILDING = 'Budynek'
}

export enum FAULT_MAIN_CATEGORIES {
  FLAT = 8,
  SETTLEMENTS = 38,
  URGENT = 42,
  BUILDING = 48
}

export const FAULT_CATEGORIES = [
  {
    id: 1,
    defaultMessage: FAULT_TOPICS.FLAT,
    Icon: LocalLaundryServiceOutlined,
    parent: null,
    i18n: 'faultCategories.1'
  },
  {
    id: 2,
    defaultMessage: FAULT_TOPICS.SETTLEMENTS,
    Icon: LocalLaundryServiceOutlined,
    parent: null,
    i18n: 'faultCategories.2'
  },
  {
    id: 3,
    defaultMessage: FAULT_TOPICS.URGENT,
    Icon: LocalLaundryServiceOutlined,
    parent: null,
    i18n: 'faultCategories.3'
  },
  {
    id: 4,
    defaultMessage: FAULT_TOPICS.BUILDING,
    Icon: LocalLaundryServiceOutlined,
    parent: null,
    i18n: 'faultCategories.4'
  }
]

export const topicsWithoutAvailabilityDate = [
  FAULT_MAIN_CATEGORIES.SETTLEMENTS,
  FAULT_MAIN_CATEGORIES.BUILDING
]
