import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useAccessToken } from 'services/token/token.hooks'
import type { LeaseContractResponse } from './useLeaseContracts'
import type { LeaseContractsProtocolResponse } from './useLeaseContractsProtocols'
import type { LeaseContractsProtocolFlatSpaceResponse } from './useLeaseContractsProtocolsFlatSpaces'
import { useTermsOfUse } from 'services/terms/terms.hooks'

export interface LeaseContractsProtocolsSpacesCommentResponse {
  id: number
  comment: string
}

export const useLeaseContractsProtocolsSpacesCommentsList = (
  contractId: LeaseContractResponse['id'],
  protocolId: LeaseContractsProtocolResponse['id'],
  spaceId: LeaseContractsProtocolFlatSpaceResponse['id']
) => {
  const { accessToken } = useAccessToken()
  const queryClient = useQueryClient()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  const { data: leaseContractsProtocolsSpacesCommentsList = [] } = useQuery({
    queryKey: [
      'leaseContractsProtocolsSpacesCommentsList',
      contractId,
      protocolId,
      spaceId,
      masquaradeToken
    ],
    queryFn: () =>
      apiInstance<LeaseContractsProtocolsSpacesCommentResponse[]>({
        url: `/lease/contracts/${contractId}/protocols/${protocolId}/spaces/${spaceId}/comments/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && hasUserConsented
  })

  const { mutateAsync: leaseContractsProtocolsSpacesCommentCreate } = useMutation({
    mutationFn: (comment: string) =>
      apiInstance<LeaseContractsProtocolsSpacesCommentResponse>({
        url: `/lease/contracts/${contractId}/protocols/${protocolId}/spaces/${spaceId}/comments/`,
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        },
        data: {
          comment
        }
      }).then(({ data }) => data),
    onSuccess: (data) => {
      queryClient.setQueryData(
        [
          'leaseContractsProtocolsSpacesCommentsList',
          contractId,
          protocolId,
          spaceId,
          masquaradeToken
        ],
        [...leaseContractsProtocolsSpacesCommentsList, data]
      )
    }
  })

  return {
    leaseContractsProtocolsSpacesCommentsList,
    leaseContractsProtocolsSpacesCommentCreate
  }
}

export const useLeaseContractsProtocolsSpacesComment = (
  contractId: LeaseContractResponse['id'],
  protocolId: LeaseContractsProtocolResponse['id'],
  spaceId: LeaseContractsProtocolFlatSpaceResponse['id'],
  commentId: LeaseContractsProtocolsSpacesCommentResponse['id']
) => {
  const { leaseContractsProtocolsSpacesCommentsList } =
    useLeaseContractsProtocolsSpacesCommentsList(contractId, protocolId, spaceId)
  const { accessToken } = useAccessToken()
  const queryClient = useQueryClient()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  const { data: leaseContractsProtocolsSpacesComment } = useQuery({
    queryKey: [
      'leaseContractsProtocolsSpacesComment',
      contractId,
      protocolId,
      spaceId,
      commentId,
      masquaradeToken
    ],
    queryFn: () =>
      apiInstance<LeaseContractsProtocolsSpacesCommentResponse[]>({
        url: `/lease/contracts/${contractId}/protocols/${protocolId}/flat/spaces/${spaceId}/comments/${commentId}/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && hasUserConsented
  })

  const leaseContractsProtocolsSpacesCommentUpdate = useMutation({
    mutationFn: () =>
      apiInstance<LeaseContractsProtocolsSpacesCommentResponse>({
        url: `/lease/contracts/${contractId}/protocols/${protocolId}/flat/spaces/${spaceId}/comments/${commentId}/`,
        method: 'PATCH',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    onSuccess: (data) => {
      queryClient.setQueryData(
        ['leaseContractsProtocolsSpacesCommentsList', contractId, protocolId, masquaradeToken],
        leaseContractsProtocolsSpacesCommentsList
      )
    }
  })

  const leaseContractsProtocolsSpacesCommentRemove = useMutation({
    mutationFn: (comment: string) =>
      apiInstance<LeaseContractsProtocolsSpacesCommentResponse>({
        url: `/lease/contracts/${contractId}/protocols/${protocolId}/flat/spaces/${spaceId}/comments/${commentId}/`,
        method: 'DELETE',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        },
        data: {
          comment
        }
      }).then(({ data }) => data),
    onSuccess: (data) => {
      queryClient.setQueryData(
        ['leaseContractsProtocolsSpacesCommentsList', contractId, protocolId, masquaradeToken],
        leaseContractsProtocolsSpacesCommentsList
      )
    }
  })

  return {
    leaseContractsProtocolsSpacesComment,
    leaseContractsProtocolsSpacesCommentUpdate,
    leaseContractsProtocolsSpacesCommentRemove
  }
}
