import { FC, createElement } from 'react'

import * as IconMUI from '@mui/icons-material'

interface IconProps {
  name: string
  sx?: any
  color?: string
}

const Icon: FC<IconProps> = ({ name, sx, color }) => {
  try {
    return createElement((IconMUI as any)[name], { sx, color })
  } catch (error) {
    return null
  }
}

export default Icon
