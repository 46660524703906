import { FC } from 'react'
import { Button } from '@mui/material'
import { ContentCopy } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'

interface ClipboardButtonProps {
  data?: string
}

const ClipboardButton: FC<ClipboardButtonProps> = ({ data = '' }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { formatMessage } = useIntl()

  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand('copy', true, text)
    }
  }

  const handleCopyClick = () =>
    copyTextToClipboard(data)
      .then(() => {
        enqueueSnackbar(
          formatMessage({
            id: 'clipboardButton.success',
            defaultMessage: 'Dane poprawnie skopiowane do schowka.'
          }),
          {
            variant: 'success'
          }
        )
      })
      .catch((err) => {
        enqueueSnackbar(
          formatMessage({
            id: 'clipboardButton.error',
            defaultMessage: 'Błąd. Nie można skopiować danych do schowka.'
          }),
          {
            variant: 'error'
          }
        )
      })

  return (
    <Button onClick={handleCopyClick} startIcon={<ContentCopy />}>
      {formatMessage({
        id: 'clipboardButton.copy',
        defaultMessage: 'KOPIUJ'
      })}
    </Button>
  )
}

export default ClipboardButton
