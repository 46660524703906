/* eslint-disable no-useless-escape */
import { FC, useEffect, useMemo } from 'react'
import { LoadingButton } from '@mui/lab'
import {
  TextField,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  FormHelperText
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import PasswordField from 'components/PasswordField'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'

import { useUserRegister } from 'services/register/register.hooks'

const REGISTRATION_STEPS = {
  userDetails: 'userDetails',
  userConsents: 'userConsents'
}

const Registration: FC = () => {
  const { formatMessage } = useIntl()
  const navigation = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const userRegister = useUserRegister({
    onError: () => {
      enqueueSnackbar(
        formatMessage({
          id: 'validation.register',
          defaultMessage: 'Błąd rejestracji. Spróbuj później.'
        }),
        {
          variant: 'error'
        }
      )
    }
  })

  const form = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      appChannelConsent: false,
      emailChannelConsent: false,
      marketingChannelConsent: false,
      step: REGISTRATION_STEPS.userDetails
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(
          formatMessage({
            id: 'validation.email',
            defaultMessage: 'Niepoprawny adres email'
          })
        )
        .required(
          formatMessage({
            id: 'validation.required',
            defaultMessage: 'Wymagane'
          })
        ),
      password: Yup.string()
        .required(
          formatMessage({
            id: 'validation.required',
            defaultMessage: 'Wymagane'
          })
        )
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          formatMessage({
            id: 'validation.password',
            defaultMessage:
              'Minimum 8 znaków, minimum jeden znak specjalny (@,#,$), jedną dużą literę, jedną małą literę oraz jedną cyfrę'
          })
        ),
      firstName: Yup.string().required(
        formatMessage({
          id: 'validation.required',
          defaultMessage: 'Wymagane'
        })
      ),
      lastName: Yup.string().required(
        formatMessage({
          id: 'validation.required',
          defaultMessage: 'Wymagane'
        })
      ),
      phone: Yup.string()
        .required(
          formatMessage({
            id: 'validation.required',
            defaultMessage: 'Wymagane'
          })
        )
        .matches(
          /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,
          formatMessage({
            id: 'validation.phone',
            defaultMessage: 'Niepoprawny numer telefonu'
          })
        ),
      appChannelConsent: Yup.bool().oneOf(
        [true],
        formatMessage({
          id: 'validation.consent',
          defaultMessage: 'Wymagane zgoda'
        })
      ),
      emailChannelConsent: Yup.bool().oneOf(
        [true],
        formatMessage({
          id: 'validation.consent',
          defaultMessage: 'Wymagane zgoda'
        })
      ),
      marketingChannelConsent: Yup.bool().oneOf(
        [true],
        formatMessage({
          id: 'validation.consent',
          defaultMessage: 'Wymagane zgoda'
        })
      )
    }),
    onSubmit: async (values) => {
      await userRegister.mutate(values)
    }
  })

  const hasUserDetailsErrors = useMemo(
    () =>
      (!!form.errors.email ||
        !!form.errors.password ||
        !!form.errors.firstName ||
        !!form.errors.lastName ||
        !!form.errors.phone) &&
      !!form.touched.email &&
      !!form.touched.password &&
      !!form.touched.firstName &&
      !!form.touched.lastName &&
      !!form.touched.phone,
    [form.errors, form.touched]
  )

  useEffect(() => {
    if (hasUserDetailsErrors) {
      form.setFieldValue('step', REGISTRATION_STEPS.userDetails)
    }
    // eslint-disable-next-line
  }, [form.errors, form.touched])

  if (form.values.step === REGISTRATION_STEPS.userConsents) {
    return (
      <>
        <FormGroup
          sx={{
            '.MuiTypography-root': {
              textAlign: 'left',
              fontSize: '0.875rem'
            }
          }}
        >
          <Typography sx={{ pl: 4 }}>
            {formatMessage({
              id: 'registration.form.consent',
              defaultMessage:
                'Wyrażam zgodę na przesyłanie informacji handlowej o aktualnej ofercie i promocjach usług R4R Leasing Sp. z o.o. z siedzibą w Warszawie za pomocą środków komunikacji elektronicznej za pośrednictwem:'
            })}
          </Typography>
          <FormControlLabel
            sx={
              !!form.errors.appChannelConsent && !!form.touched.appChannelConsent
                ? {
                    color: 'error.main',
                    '.MuiCheckbox-root': {
                      color: 'error.main'
                    }
                  }
                : undefined
            }
            control={
              <Checkbox
                name='appChannelConsent'
                checked={form.values.appChannelConsent}
                onChange={form.handleChange}
              />
            }
            label={formatMessage({
              id: 'registration.form.app',
              defaultMessage: 'aplikacji'
            })}
          />
          {!!form.errors.appChannelConsent && !!form.touched.appChannelConsent && (
            <FormHelperText
              error={!!form.errors.appChannelConsent && !!form.touched.appChannelConsent}
            >
              {form.errors.appChannelConsent}
            </FormHelperText>
          )}
          <FormControlLabel
            sx={
              !!form.errors.emailChannelConsent && !!form.touched.emailChannelConsent
                ? {
                    color: 'error.main',
                    '.MuiCheckbox-root': {
                      color: 'error.main'
                    }
                  }
                : undefined
            }
            control={
              <Checkbox
                name='emailChannelConsent'
                checked={form.values.emailChannelConsent}
                onChange={form.handleChange}
              />
            }
            label={formatMessage({
              id: 'registration.form.email',
              defaultMessage: 'email'
            })}
          />
          {!!form.errors.emailChannelConsent && !!form.touched.emailChannelConsent && (
            <FormHelperText
              error={!!form.errors.emailChannelConsent && !!form.touched.emailChannelConsent}
            >
              {form.errors.emailChannelConsent}
            </FormHelperText>
          )}
          <FormControlLabel
            control={
              <Checkbox
                name='marketingChannelConsent'
                checked={form.values.marketingChannelConsent}
                onChange={form.handleChange}
              />
            }
            sx={
              !!form.errors.marketingChannelConsent && !!form.touched.marketingChannelConsent
                ? {
                    alignItems: 'flex-start',
                    '.MuiTypography-root': {
                      paddingTop: '9px'
                    },
                    color: 'error.main',
                    '.MuiCheckbox-root': {
                      color: 'error.main'
                    }
                  }
                : {
                    alignItems: 'flex-start',
                    '.MuiTypography-root': {
                      paddingTop: '9px'
                    }
                  }
            }
            label={formatMessage({
              id: 'registration.form.marketing',
              defaultMessage:
                'Wyrażam zgodę na otrzymywanie od R4R Leasing Sp. z o.o. drogą elektroniczną poprzez aplikację informacji handlowych w celach marketingowych podmiotów współpracujących z R4R Leasing Sp. z o.o.'
            })}
          />
          {!!form.errors.marketingChannelConsent && !!form.touched.marketingChannelConsent && (
            <FormHelperText
              error={
                !!form.errors.marketingChannelConsent && !!form.touched.marketingChannelConsent
              }
            >
              {form.errors.marketingChannelConsent}
            </FormHelperText>
          )}
        </FormGroup>
        <LoadingButton
          onClick={form.submitForm}
          size='large'
          fullWidth
          variant='contained'
          sx={{ mt: 6 }}
          loading={userRegister.isLoading}
        >
          {formatMessage({
            id: 'registration.form.submit',
            defaultMessage: 'Zarejestruj się'
          })}
        </LoadingButton>
        <Button
          onClick={() => form.setFieldValue('step', REGISTRATION_STEPS.userDetails)}
          size='large'
          fullWidth
          variant='outlined'
          sx={{ mt: 2 }}
        >
          {formatMessage({
            id: 'registration.form.back',
            defaultMessage: 'Wróć'
          })}
        </Button>
      </>
    )
  }

  return (
    <>
      <TextField
        name='firstName'
        value={form.values.firstName}
        onChange={form.handleChange}
        fullWidth
        sx={{ mb: 3 }}
        label={formatMessage({
          id: 'registration.form.firstName',
          defaultMessage: 'Imię'
        })}
        error={!!form.errors.firstName && !!form.touched.firstName}
        helperText={!!form.errors.firstName && !!form.touched.firstName && form.errors.firstName}
      />
      <TextField
        name='lastName'
        value={form.values.lastName}
        onChange={form.handleChange}
        fullWidth
        sx={{ mb: 3 }}
        label={formatMessage({
          id: 'registration.form.lastName',
          defaultMessage: 'Nazwisko'
        })}
        error={!!form.errors.lastName && !!form.touched.lastName}
        helperText={!!form.errors.lastName && !!form.touched.lastName && form.errors.lastName}
      />
      <TextField
        name='phone'
        value={form.values.phone}
        onChange={form.handleChange}
        fullWidth
        sx={{ mb: 3 }}
        label={formatMessage({
          id: 'registration.form.phone',
          defaultMessage: 'Telefon'
        })}
        error={!!form.errors.phone && !!form.touched.phone}
        helperText={!!form.errors.phone && !!form.touched.phone && form.errors.phone}
      />
      <TextField
        name='email'
        value={form.values.email}
        onChange={form.handleChange}
        fullWidth
        sx={{ mb: 3 }}
        label={formatMessage({
          id: 'registration.form.email',
          defaultMessage: 'Email'
        })}
        error={!!form.errors.email && !!form.touched.email}
        helperText={!!form.errors.email && !!form.touched.email && form.errors.email}
      />
      <PasswordField
        name='password'
        value={form.values.password}
        onChange={form.handleChange}
        fullWidth
        sx={{ mb: 3 }}
        label={formatMessage({
          id: 'registration.form.password',
          defaultMessage: 'Hasło'
        })}
        error={!!form.errors.password && !!form.touched.password}
        helperText={
          !!form.errors.password && !!form.touched.password
            ? form.errors.password
            : formatMessage({
                id: 'validation.password',
                defaultMessage:
                  'Minimum 8 znaków, minimum jeden znak specjalny (@,#,$), jedną dużą literę, jedną małą literę oraz jedną cyfrę'
              })
        }
      />

      <Button
        onClick={() => form.setFieldValue('step', REGISTRATION_STEPS.userConsents)}
        size='large'
        fullWidth
        variant='outlined'
        sx={{ mb: 1, mt: 6 }}
        disabled={hasUserDetailsErrors}
      >
        {formatMessage({
          id: 'registration.form.nextStep',
          defaultMessage: 'Kolejny krok: zGODY'
        })}
      </Button>
      <Button
        onClick={() => navigation('/auth/sign-in')}
        color='secondary'
        fullWidth
        size='large'
        variant='text'
        sx={{ mb: 1, textTransform: 'none', fontWeight: '400' }}
      >
        {formatMessage({
          id: 'registration.form.signIn',
          defaultMessage: 'Masz konto? Zaloguj się'
        })}
      </Button>
    </>
  )
}

export default Registration
