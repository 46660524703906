import { useQuery, useMutation } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useAccessToken } from 'services/token/token.hooks'
import { useTermsOfUse } from 'services/terms/terms.hooks'

export interface LeaseContractsProtocolsResponse {
  id: number
  person: number
  status: string
  protocolType: string
  r4rRepresentative: number
  protocolWithTenant: boolean
  oneSidedProtocolReason: string
  generalComment: string
  clientSignature: string
  r4rRepSignature: string
  file: string
  signedAt: string
  acceptedAt: string
  metersReadings: {
    id: number
    meterType: string
    meterPhoto: string
    meterValue: number
    readingDate: string
  }[]
  questionsAnswers: {
    id: number
    room: {
      id: number
      roomInstance: {
        id: number
        name: string
        nameLocal: string
        flat: {
          id: number
          area: number
          floor: number
          flatIdNumber: string
          numberOfRooms: number
          flatType: number
          flatTypeExtra: number
        }
        area: number
      }
    }
    question: {
      id: number
      roomType: number
      question: string
    }
    scale: string
    comment: string
    image: string
    debitNotePosition: {
      id: number
      title: string
      productCode: number
    }
    coveringCost: string
  }[]
  furniture: {
    id: number
    furnitureType: {
      id: number
      name: string
    }
    quantity: number
  }[]
  keys: {
    id: number
    keyType: string
    quantity: number
  }[]
  items: {
    id: number
    itemType: {
      id: number
      name: string
    }
    quantity: number
  }[]
  personComments: {
    id: number
    room: number
    comment: string
    image: string
  }[]
  rooms: {
    id: number
    roomInstance: {
      id: number
      name: string
      nameLocal: string
      flat: {
        id: number
        area: number
        floor: number
        flatIdNumber: string
        numberOfRooms: number
        flatType: number
        flatTypeExtra: number
      }
      area: number
    }
    video: string
    description: string
    images: {
      id: number
      room: number
      image: string
    }[]
  }[]
}

export const useLeaseContractProtocols = (contractId: number) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  const { data: protocolsList = [], ...rest } = useQuery({
    queryKey: ['leaseContractsProtocolsListV2', contractId, masquaradeToken],
    queryFn: () =>
      apiInstance<LeaseContractsProtocolsResponse[]>({
        url: `/lease/contracts/${contractId}/delivery-acceptance-protocol/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) =>
        data.filter(({ status }) =>
          ['Handed over', 'Accepted', 'Accepted with comments'].includes(status)
        )
      ),
    enabled: !!accessToken && hasUserConsented
  })

  const getActiveContract = (id: string | number) =>
    protocolsList.find((contract) => contract.id === Number(id))

  const acceptProtocol = useMutation({
    mutationFn: (protocolId?: number) =>
      apiInstance<LeaseContractsProtocolsResponse>({
        url: `/lease/contracts/${contractId}/delivery-acceptance-protocol/${protocolId}/accept/`,
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    onSuccess: () => {
      rest.refetch()
    }
  })

  const addComment = useMutation({
    mutationFn: ({
      room,
      comment,
      protocolId
    }: {
      room: number
      comment: string
      protocolId: number
    }) =>
      apiInstance<LeaseContractsProtocolsResponse>({
        url: `/lease/contracts/${contractId}/delivery-acceptance-protocol/${protocolId}/add-comment/`,
        method: 'POST',
        data: { room, comment },
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    onSuccess: () => {
      rest.refetch()
    }
  })

  return { protocolsList, getActiveContract, acceptProtocol, addComment, ...rest }
}
