import { FC, useMemo } from 'react'
import { useParams, Navigate } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'
import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import { useDocumentsList } from 'services/documents/documents.hooks'
import { Worker, Viewer } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import { toolbarPlugin } from '@react-pdf-viewer/toolbar'
import '@react-pdf-viewer/toolbar/lib/styles/index.css'

const Help: FC = () => {
  const { id } = useParams()
  const { data: attachmentsList = [], isFetching, isLoading } = useDocumentsList()

  const help = useMemo(
    () => attachmentsList.find((help) => help.id === Number(id)),
    [id, attachmentsList]
  )

  const parseMediaUrl = (url: string) => {
    if (url.includes('youtube.com')) {
      const params = new URL(url).searchParams
      const videoId = params.get('v')

      return `https://www.youtube.com/embed/${videoId}`
    }

    return url
  }

  if (isFetching || isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  const PDFViewer = () => {
    const toolbarPluginInstance = toolbarPlugin()
    const { Toolbar } = toolbarPluginInstance

    return (
      <div style={{ height: '750px' }}>
        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
          <div style={{ height: '100%' }}>
            <Toolbar />
            {help ? <Viewer fileUrl={help.file} plugins={[toolbarPluginInstance]} /> : null}
          </div>
        </Worker>
      </div>
    )
  }

  if (help) {
    return (
      <>
        <BackNavigationHeader label={help.name} />
        <BackNavigationContent sx={{ position: 'relative' }}>
          <Box
            sx={{
              iframe: {
                height: 'calc(100vh - 200px)',
                border: 'none',
                width: 'calc(100vw - 2rem)'
              }
            }}>
            {help.file && <PDFViewer />}
            {help.movieUrl && (
              <iframe
                width='100%'
                height='auto'
                src={parseMediaUrl(help.movieUrl)}
                title={help.name}
                frameBorder='0'
                allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen></iframe>
            )}
          </Box>
        </BackNavigationContent>
      </>
    )
  }

  if (id) {
    return <Navigate to='/' />
  }

  return null
}

export default Help
