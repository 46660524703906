import { useQuery } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useAccessToken } from 'services/token/token.hooks'
import type { LeaseContractResponse } from './useLeaseContracts'
import { useTermsOfUse } from 'services/terms/terms.hooks'

interface LeaseContractFlatResponse {
  id: number
  area: number
  floor: number
  flatIdNumber: string
  numberOfRooms: number
  flatType: number
  flatTypeExtra: number
}

interface LeaseContractFlatAccessCodesResponse {
  gateCode: null | string
  id: number
  investmentContactNumber: null | string
  investmentWifiLogin: null | string
  investmentWifiPassword: null | string
  wicketCode: null | string
  wifiLogin: null | string
  wifiPassword: null | string
  flatIdNumber: string
}

export const useLeaseContractFlats = (contractId?: LeaseContractResponse['id']) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  const { data: leaseContractsFlats = [] } = useQuery({
    queryKey: ['leaseContractsFlatsList', contractId, masquaradeToken],
    queryFn: () =>
      apiInstance<LeaseContractFlatResponse[]>({
        url: `/lease/contracts/${contractId}/flats/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && !!contractId && hasUserConsented
  })

  return { leaseContractsFlats }
}

export const useLeaseContractFlat = (
  contractId: LeaseContractResponse['id'],
  flatId: LeaseContractFlatResponse['id']
) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  const { data: leaseContractsFlat } = useQuery({
    queryKey: ['leaseContractsFlatsList', contractId, flatId, masquaradeToken],
    queryFn: () =>
      apiInstance<LeaseContractFlatResponse>({
        url: `/lease/contracts/${contractId}/flats/${flatId}/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && hasUserConsented
  })

  return { leaseContractsFlat }
}

export const useLeaseContractFlatSpaces = (
  contractId: LeaseContractResponse['id'],
  flatId: LeaseContractFlatResponse['id']
) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  const { data: leaseContractsFlatSpaces } = useQuery({
    queryKey: ['leaseContractsFlatSpaces', contractId, flatId, masquaradeToken],
    queryFn: () =>
      apiInstance<LeaseContractFlatResponse>({
        url: `/lease/contracts/${contractId}/flats/${flatId}/spaces/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && hasUserConsented
  })

  return { leaseContractsFlatSpaces }
}

export const useLeaseContractFlatsAndSpaces = (contractId?: LeaseContractResponse['id']) => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  const { data: leaseContractsFlats = [] } = useQuery({
    queryKey: ['leaseContractsFlatsList', contractId, masquaradeToken],
    queryFn: () =>
      apiInstance<LeaseContractFlatResponse[]>({
        url: `/lease/contracts/${contractId}/flats/`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && !!contractId && hasUserConsented
  })

  const { data: leaseContractsFlatSpaces = [] } = useQuery({
    queryKey: [
      'leaseContractsFlatSpaces',
      contractId,
      leaseContractsFlats.map(({ id }) => id),
      masquaradeToken
    ],
    queryFn: async () => {
      const spaces = []
      for (const leaseContractsFlat of leaseContractsFlats) {
        const { data } = await apiInstance<LeaseContractFlatResponse>({
          url: `/lease/contracts/${contractId}/flats/${leaseContractsFlat.id}/spaces/`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
            'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
          }
        })

        spaces.push(data)
      }

      return spaces
    },

    enabled: !!accessToken && !!leaseContractsFlats.length
  })

  const { data: leaseContractsFlatAccessCodes = [] } = useQuery({
    queryKey: [
      'leaseContractsFlatAccessCodes',
      contractId,
      leaseContractsFlats.map(({ id }) => id),
      masquaradeToken
    ],
    queryFn: async () => {
      const accessCodes = [] as LeaseContractFlatAccessCodesResponse[]

      for (const leaseContractsFlat of leaseContractsFlats) {
        const { data } = await apiInstance<LeaseContractFlatAccessCodesResponse>({
          url: `/lease/contracts/${contractId}/flats/${leaseContractsFlat.id}/access-codes/`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${accessToken}`,
            'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
          }
        })

        accessCodes.push({
          ...data,
          flatIdNumber: leaseContractsFlat.flatIdNumber
        })
      }

      return accessCodes
    },

    enabled: !!accessToken && !!leaseContractsFlats.length
  })

  return {
    leaseContractsFlats,
    leaseContractsFlatSpaces,
    leaseContractsFlatAccessCodes
  }
}
