import { FC, useState, useMemo } from 'react'
import { Box, List, ListItemButton, ListItemText, ListItem, Tabs, Tab } from '@mui/material'
import { Circle } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useNotificationsList } from 'services/notifications/notifications.hook'
import { NOTIFICATION_STATUS_TYPE } from 'services/notifications/notifications.consts'
import InfoIndicator from 'components/InfoIndicator'
import { NotificationsOffOutlined } from '@mui/icons-material'
import { useIntl } from 'react-intl'

const NotificationsList: FC = () => {
  const [hasUnread, setUnread] = useState(false)
  const { formatMessage } = useIntl()
  const { notificationsList, isFetching, isLoading } = useNotificationsList()
  const navigate = useNavigate()

  const notifications = useMemo(() => {
    if (hasUnread) {
      return notificationsList.filter(({ status }) => status === NOTIFICATION_STATUS_TYPE.unread)
    }
    return notificationsList
  }, [hasUnread, notificationsList])

  return (
    <Box>
      <InfoIndicator
        helperText={formatMessage({
          id: 'notificationsList.noNotifications',
          defaultMessage: 'Brak powiadomień.'
        })}
        Icon={NotificationsOffOutlined}
        isVisible={!notificationsList.length && !isLoading && !isFetching}
      />
      <Box>
        <Tabs
          value={hasUnread}
          onChange={(_, newValue) => setUnread(newValue)}
          centered
          sx={{ mb: 3 }}
        >
          <Tab
            label={formatMessage({
              id: 'notificationsList.all',
              defaultMessage: 'Wszystkie'
            })}
            value={false}
          />
          <Tab
            label={formatMessage({
              id: 'notificationsList.unread',
              defaultMessage: 'Nieprzeczytane'
            })}
            value={true}
          />
        </Tabs>
      </Box>
      <List
        sx={{
          '.MuiListItem-root:first-of-type': {
            borderTop: '1px solid #CDCDCD'
          }
        }}
      >
        {notifications.map(({ id, title, status }) => (
          <ListItem
            key={id}
            onClick={() => navigate(`/notifications/${id}`)}
            disablePadding
            secondaryAction={
              status === NOTIFICATION_STATUS_TYPE.unread ? (
                <Circle color='secondary' fontSize='small' />
              ) : null
            }
            sx={{
              borderBottom: '1px solid #CDCDCD'
            }}
          >
            <ListItemButton
              sx={{
                py: 2
              }}
            >
              <ListItemText primary={title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default NotificationsList
