import { FC, useState, useMemo } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { Box, Typography } from '@mui/material'
import { Circle } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay'
import { useBoardsList, useBoardsListPosts } from 'services/boards/boards.hooks'
import InfoIndicator from 'components/InfoIndicator'
import { SignpostOutlined } from '@mui/icons-material'
import { useIntl } from 'react-intl'

const Day: FC<PickersDayProps<Dayjs> & { events?: any[] }> = ({
  sx,
  events = [],
  day,
  ...props
}) => {
  const hasEvent = !!events.filter(({ eventDate }) => day.isSame(eventDate, 'day')).length

  return (
    <PickersDay
      {...props}
      day={day}
      sx={{
        ...sx,
        ':after': {
          content: "''",
          width: '5px',
          height: '5px',
          backgroundColor: '#527EF7',
          borderRadius: '50%',
          bottom: '5px',
          position: 'absolute',
          margin: '0 auto',
          display: hasEvent ? 'block' : 'none'
        }
      }}
    />
  )
}

const WallCalendar: FC = () => {
  const { formatMessage } = useIntl()
  const [selectadDate, setSelectedDate] = useState<null | Dayjs>(null)
  const { data = [], isFetching, isLoading } = useBoardsList()
  const { boardsListPosts } = useBoardsListPosts(data.map(({ id }) => id))
  const navigate = useNavigate()

  const events = useMemo(
    () =>
      boardsListPosts.filter(({ eventDate }) => {
        if (!!selectadDate) {
          return selectadDate.isSame(eventDate, 'day')
        }

        return dayjs().isSame(eventDate, 'day')
      }),
    [selectadDate, boardsListPosts]
  )

  return (
    <Box
      sx={{
        '.MuiYearCalendar-root, .MuiDateCalendar-root': {
          width: '100%',
          maxWidth: 'unset'
        },
        '.MuiPickersCalendarHeader-root': {
          padding: 0
        },
        '.MuiDayCalendar-header, .MuiDayCalendar-weekContainer': {
          justifyContent: 'space-between'
        }
      }}
    >
      <DateCalendar
        value={selectadDate}
        onChange={(value) => setSelectedDate(value)}
        views={['day']}
        slots={{ day: Day }}
        slotProps={{
          day: {
            events: boardsListPosts
          } as any
        }}
      />
      <InfoIndicator
        helperText={formatMessage({
          id: 'wallCalendar.helperText',
          defaultMessage: 'Brak ogłoszeń na wybrany dzień.'
        })}
        Icon={SignpostOutlined}
        isVisible={!events.length && !isLoading && !isFetching}
      />
      {events.map(({ id, eventDate, title, board }) => (
        <Box
          sx={{ cursor: 'pointer' }}
          key={id}
          mb={2}
          onClick={() => navigate(`/board/${board.id}/${id}`)}
        >
          <Typography variant='body2'>
            <Circle color='primary' sx={{ fontSize: '12px', mr: 1 }} />
            {dayjs(eventDate).format('HH:mm')}
          </Typography>
          <Typography>{title}</Typography>
        </Box>
      ))}
    </Box>
  )
}

export default WallCalendar
