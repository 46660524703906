import { LoadingButton } from '@mui/lab'
import { Box, CircularProgress, TextField } from '@mui/material'
import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import DesktopNavigation from 'components/DesktopNavigation'
import MobileBottomFooter from 'components/MobileBottomFooter'
import Section from 'components/Section'
import { useFormik } from 'formik'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { useOfferListOffer } from 'services/offer/hooks/useOfferList'
import { useOfferMessagesCreate } from 'services/offer/hooks/useOfferMessagesCreate'
import { useOfferMessagesList } from 'services/offer/hooks/useOfferMessagesList'

const OfferMessage: FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { createMessage } = useOfferMessagesCreate()
  const { data: offerDetail = [], isFetching, isLoading } = useOfferListOffer(id)
  const { refetchMessagesList } = useOfferMessagesList(offerDetail.offerId)

  const formik = useFormik<{
    message: string
  }>({
    initialValues: {
      message: ''
    },
    onSubmit: async ({ message }, { setSubmitting }) => {
      setSubmitting(true)

      await createMessage({ message, offerId: offerDetail.offerId })
      await refetchMessagesList()
      navigate(`/offer/${id}/messages`)
    }
  })

  if (isFetching || isLoading) {
    return (
      <Section
        sx={{
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <CircularProgress />
      </Section>
    )
  }

  return (
    <>
      <DesktopNavigation
        label={formatMessage({
          id: 'offerMessage.addMessage',
          defaultMessage: 'Dodaj wiadomość'
        })}
      />
      <BackNavigationHeader
        label={formatMessage({
          id: 'offerMessage.addMessage',
          defaultMessage: 'Dodaj wiadomość'
        })}
      />
      <BackNavigationContent>
        <Box>
          <TextField
            name='message'
            onChange={formik.handleChange}
            value={formik.values.message}
            label={formatMessage({
              id: 'defectMessage.form.message',
              defaultMessage: 'Wiadomość'
            })}
            sx={{ mt: 4 }}
            fullWidth
            rows={5}
            multiline
            disabled={formik.isSubmitting}
          />
        </Box>
        <MobileBottomFooter>
          <LoadingButton
            fullWidth
            sx={{ mt: 2 }}
            variant='contained'
            loading={formik.isSubmitting}
            onClick={formik.submitForm}>
            {formatMessage({
              id: 'offerMessage.send',
              defaultMessage: 'Wyślij'
            })}
          </LoadingButton>
        </MobileBottomFooter>
      </BackNavigationContent>
    </>
  )
}

export default OfferMessage
