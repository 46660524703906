import { TextField, Autocomplete } from '@mui/material'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { useFormik } from 'formik'
import { FC } from 'react'
//import { LoadingButton } from "@mui/lab";
import { removeEmptyProperties } from 'utils'

import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import dayjs from 'dayjs'
import { useUsersProfile } from 'services/profile/profile.hooks'
import countries from 'consts/countries'
import { useIntl } from 'react-intl'

const MAX_DATE = dayjs().subtract(18, 'year')
const MIN_DATE = dayjs().subtract(100, 'year')

const UserPersonalDetails: FC = () => {
  const { formatMessage } = useIntl()
  const {
    usersProfilePartialUpdate,
    data = {
      firstName: '',
      lastName: '',
      pesel: '',
      idCard: '',
      passportId: '',
      nationality: '',
      dateOfBirth: '',
      placeOfBirth: ''
    }
  } = useUsersProfile()

  const userDetailsForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      pesel: data.pesel || '',
      idCard: data.idCard || '',
      passportId: data.passportId || '',
      nationality:
        countries.find((country) => country.alpha2.toUpperCase() === data.nationality) || {},
      dateOfBirth: data.dateOfBirth || '',
      placeOfBirth: data.placeOfBirth || ''
    },
    onSubmit: async (values, { setSubmitting }) => {
      await usersProfilePartialUpdate.mutate(
        removeEmptyProperties({
          ...values,
          dateOfBirth: dayjs(values.dateOfBirth).format('YYYY-MM-DD'),
          nationality: (values.nationality as any).alpha2.toUpperCase()
        })
      )

      setSubmitting(false)
    }
  })

  return (
    <form onSubmit={userDetailsForm.handleSubmit}>
      <BackNavigationHeader
        label={formatMessage({
          id: 'userPersonalDetails.label',
          defaultMessage: 'Dane osobowe'
        })}
      />
      <BackNavigationContent>
        <TextField
          name='firstName'
          value={userDetailsForm.values.firstName}
          onChange={userDetailsForm.handleChange}
          fullWidth
          sx={{ mb: 3, mt: 'calc(env(safe-area-inset-top) + 1rem)' }}
          label={formatMessage({
            id: 'userPersonalDetails.form.firstName',
            defaultMessage: 'Imie'
          })}
          autoFocus
          disabled
        />
        <TextField
          name='lastName'
          value={userDetailsForm.values.lastName}
          onChange={userDetailsForm.handleChange}
          fullWidth
          sx={{ mb: 3 }}
          label={formatMessage({
            id: 'userPersonalDetails.form.lastName',
            defaultMessage: 'Nazwisko'
          })}
          disabled
        />
        <TextField
          name='pesel'
          value={userDetailsForm.values.pesel}
          onChange={userDetailsForm.handleChange}
          fullWidth
          sx={{ mb: 3 }}
          label={formatMessage({
            id: 'userPersonalDetails.form.pesel',
            defaultMessage: 'PESEL'
          })}
          disabled
        />
        <TextField
          name='idCard'
          value={userDetailsForm.values.idCard}
          onChange={userDetailsForm.handleChange}
          fullWidth
          sx={{ mb: 3 }}
          label={formatMessage({
            id: 'userPersonalDetails.form.idCard',
            defaultMessage: 'Numer dowodu'
          })}
          disabled
        />
        <TextField
          name='passportId'
          value={userDetailsForm.values.passportId}
          onChange={userDetailsForm.handleChange}
          fullWidth
          sx={{ mb: 3 }}
          label={formatMessage({
            id: 'userPersonalDetails.form.passportId',
            defaultMessage: 'Numer paszportu'
          })}
          disabled
        />
        <Autocomplete
          id='nationality'
          disablePortal
          sx={{ mb: 3 }}
          value={userDetailsForm.values.nationality as any}
          getOptionLabel={(option) => option.pl}
          onChange={userDetailsForm.handleChange}
          options={countries}
          renderInput={(params) => (
            <TextField
              {...params}
              label={formatMessage({
                id: 'userPersonalDetails.form.nationality',
                defaultMessage: 'Narodowość'
              })}
              fullWidth
            />
          )}
          disabled
        />
        <MobileDatePicker
          sx={{ mb: 3 }}
          disableFuture
          disableHighlightToday
          maxDate={MAX_DATE as any}
          minDate={MIN_DATE as any}
          value={dayjs(userDetailsForm.values.dateOfBirth)}
          onChange={(value) => {
            userDetailsForm.setFieldValue('dateOfBirth', value)
          }}
          slotProps={{
            textField: {
              fullWidth: true
            }
          }}
          format='YYYY-MM-DD'
          label={formatMessage({
            id: 'userPersonalDetails.form.dateOfBirth',
            defaultMessage: 'Data urodzenia'
          })}
          onOpen={() => {
            if (!userDetailsForm.values.dateOfBirth) {
              userDetailsForm.setFieldValue('dateOfBirth', MAX_DATE)
            }
          }}
          disabled
        />
        <TextField
          name='placeOfBirth'
          value={userDetailsForm.values.placeOfBirth}
          onChange={userDetailsForm.handleChange}
          fullWidth
          sx={{ mb: 3 }}
          label={formatMessage({
            id: 'userPersonalDetails.form.placeOfBirth',
            defaultMessage: 'Miejsce urodzenia'
          })}
          disabled
        />
        {/* <LoadingButton
          loading={userDetailsForm.isSubmitting}
          fullWidth
          sx={{ mt: 6 }}
          variant="contained"
          type="submit"
        >
          ZAWNIOSKUJ O ZMIANĘ DANYCH
        </LoadingButton> */}
      </BackNavigationContent>
    </form>
  )
}

export default UserPersonalDetails
