import { useQuery } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useTermsOfUse } from 'services/terms/terms.hooks'
import { useAccessToken } from 'services/token/token.hooks'

export interface OfferRejectionReasons {
  id: number
  name: string
  nameLocal: string
}

export const useOfferRejectionReasons = () => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  return useQuery({
    queryKey: ['rejectionReasons', masquaradeToken],
    cacheTime: 3600000,
    queryFn: () =>
      apiInstance<OfferRejectionReasons[]>({
        url: '/projects/rejection-reasons/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && hasUserConsented
  })
}
