import { FC } from 'react'
import { AdditionalServicesTenants } from 'components/AdditionalServices'
import DesktopNavigation from 'components/DesktopNavigation'
import { UserProtectedRoute } from 'routes'

const AdditionalServicesTenantsPage: FC = () => {
  return (
    <UserProtectedRoute>
      <DesktopNavigation label='Lokatorzy do podpisu' desktopNavigateTo='/additional-services' />
      <AdditionalServicesTenants />
    </UserProtectedRoute>
  )
}

export default AdditionalServicesTenantsPage
