import { useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useTermsOfUse } from 'services/terms/terms.hooks'
import { useAccessToken } from 'services/token/token.hooks'

type CountriesListResponse = {
  id: number
  name: string
}[]

export const useCountriesList = () => {
  const { accessToken } = useAccessToken()
  const { hasUserConsented } = useTermsOfUse()

  const { data: countries = [], ...rest } = useQuery({
    queryKey: ['countriesList'],
    queryFn: () =>
      apiInstance<CountriesListResponse>({
        url: '/meta/countries/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && hasUserConsented
  })

  const getCountryById = useCallback(
    (countryId: number) => {
      const country = countries.find(({ id }) => id === countryId)

      return (
        country ?? {
          id: -1,
          name: ''
        }
      )
    },
    [countries]
  )

  return {
    countries,
    getCountryById,
    ...rest
  }
}

type LanguagesListResponse = {
  code: string
  name: string
}[]

export const useLanguagesList = () => {
  const { accessToken } = useAccessToken()
  const { hasUserConsented } = useTermsOfUse()

  return useQuery({
    queryKey: ['languagesList'],
    queryFn: () =>
      apiInstance<LanguagesListResponse>({
        url: '/meta/languages/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && hasUserConsented
  })
}

type AddressesTypesListResponse = {
  type: string
  item: {
    [key: string]: string
  }
  subtitle: {
    [key: string]: string
  }
}[]

export const useAddressesTypesList = () => {
  const { accessToken } = useAccessToken()
  const { hasUserConsented } = useTermsOfUse()

  return useQuery({
    queryKey: ['addressesTypesList'],
    queryFn: () =>
      apiInstance<AddressesTypesListResponse>({
        url: '/meta/addresses/types/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`
        }
      }).then(({ data }) => data),
    enabled: !!accessToken && hasUserConsented
  })
}
