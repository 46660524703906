import { FC } from 'react'

import SetNewPassword from 'components/SetNewPassword'
import { UnauthorizedRoute } from 'routes'

const SetNewPasswordPage: FC = () => {
  return (
    <UnauthorizedRoute>
      <SetNewPassword />
    </UnauthorizedRoute>
  )
}

export default SetNewPasswordPage
