import { IntlFormatters } from 'react-intl'
import * as Yup from 'yup'

export const personalDetailsConfirmationSchemaStepOne = (
  formatMessage: IntlFormatters['formatMessage']
) =>
  Yup.object().shape(
    {
      firstName: Yup.string().required(
        formatMessage({
          id: 'validation.required',
          defaultMessage: 'Wymagane'
        })
      ),
      lastName: Yup.string().required(
        formatMessage({
          id: 'validation.required',
          defaultMessage: 'Wymagane'
        })
      ),
      mobile: Yup.string().required(
        formatMessage({
          id: 'validation.required',
          defaultMessage: 'Wymagane'
        })
      ),
      email: Yup.string()
        .email(
          formatMessage({
            id: 'validation.email',
            defaultMessage: 'Niepoprawny adres email'
          })
        )
        .required(
          formatMessage({
            id: 'validation.required',
            defaultMessage: 'Wymagane'
          })
        ),
      pesel: Yup.string()
        .matches(
          /^\d{11}(:\d{11})*$/,
          formatMessage({
            id: 'validation.pesel',
            defaultMessage: 'PESEL powinien składać się z 11 cyfr'
          })
        )
        .required(
          formatMessage({
            id: 'validation.required',
            defaultMessage: 'Wymagane'
          })
        ),
      idCard: Yup.string().when(
        ['passportId', 'residenceCardNumber'],
        ([passportId, residenceCardNumber], schema) => {
          if (!(passportId?.length || residenceCardNumber?.length)) {
            return Yup.string().required(
              formatMessage({
                id: 'validation.requiredAtLeastOneDoc',
                defaultMessage: 'Wymagany przynajmniej jeden dokument'
              })
            )
          } else {
            return Yup.string().notRequired()
          }
        }
      ),
      passportId: Yup.string().when(
        ['idCard', 'residenceCardNumber'],
        ([idCard, residenceCardNumber], schema) => {
          if (!(idCard?.length || residenceCardNumber?.length)) {
            return Yup.string().required(
              formatMessage({
                id: 'validation.requiredAtLeastOneDoc',
                defaultMessage: 'Wymagany przynajmniej jeden dokument'
              })
            )
          } else {
            return Yup.string().notRequired()
          }
        }
      ),
      residenceCardNumber: Yup.string().when(
        ['idCard', 'passportId'],
        ([idCard, passportId], schema) => {
          if (idCard?.length || passportId?.length) {
            return Yup.string().notRequired()
          } else {
            return Yup.string().required(
              formatMessage({
                id: 'validation.requiredAtLeastOneDoc',
                defaultMessage: 'Wymagany przynajmniej jeden dokument'
              })
            )
          }
        }
      ),
      dateOfBirth: Yup.string().required(
        formatMessage({
          id: 'validation.required',
          defaultMessage: 'Wymagane'
        })
      )
    },
    [
      ['passportId', 'idCard'],
      ['idCard', 'passportId'],
      ['passportId', 'residenceCardNumber'],
      ['residenceCardNumber', 'passportId'],
      ['idCard', 'residenceCardNumber'],
      ['residenceCardNumber', 'idCard']
    ]
  )

export const personalDetailsConfirmationSchemaStepTwo = (
  formatMessage: IntlFormatters['formatMessage']
) =>
  Yup.object().shape({
    accountNumber: Yup.string().required(
      formatMessage({
        id: 'validation.required',
        defaultMessage: 'Wymagane'
      })
    ),
    bankName: Yup.string().required(
      formatMessage({
        id: 'validation.required',
        defaultMessage: 'Wymagane'
      })
    ),
    billingAddress: Yup.object().shape({
      street: Yup.string().required(
        formatMessage({
          id: 'validation.required',
          defaultMessage: 'Wymagane'
        })
      ),
      city: Yup.string().required(
        formatMessage({
          id: 'validation.required',
          defaultMessage: 'Wymagane'
        })
      ),
      zipcode: Yup.string().required(
        formatMessage({
          id: 'validation.required',
          defaultMessage: 'Wymagane'
        })
      ),
      country: Yup.object()
        .shape({
          id: Yup.number(),
          name: Yup.string()
        })
        .required(
          formatMessage({
            id: 'validation.required',
            defaultMessage: 'Wymagane'
          })
        )
    }),
    address: Yup.object().shape({
      street: Yup.string().required(
        formatMessage({
          id: 'validation.required',
          defaultMessage: 'Wymagane'
        })
      ),
      city: Yup.string().required(
        formatMessage({
          id: 'validation.required',
          defaultMessage: 'Wymagane'
        })
      ),
      zipcode: Yup.string().required(
        formatMessage({
          id: 'validation.required',
          defaultMessage: 'Wymagane'
        })
      ),
      country: Yup.object()
        .shape({
          id: Yup.number(),
          name: Yup.string()
        })
        .required(
          formatMessage({
            id: 'validation.required',
            defaultMessage: 'Wymagane'
          })
        )
    }),
    deliveryAddressDuringContract: Yup.object().shape({
      street: Yup.string().required(
        formatMessage({
          id: 'validation.required',
          defaultMessage: 'Wymagane'
        })
      ),
      city: Yup.string().required(
        formatMessage({
          id: 'validation.required',
          defaultMessage: 'Wymagane'
        })
      ),
      zipcode: Yup.string().required(
        formatMessage({
          id: 'validation.required',
          defaultMessage: 'Wymagane'
        })
      ),
      country: Yup.object()
        .shape({
          id: Yup.number(),
          name: Yup.string()
        })
        .required(
          formatMessage({
            id: 'validation.required',
            defaultMessage: 'Wymagane'
          })
        )
    }),
    deliveryAddressAfterContract: Yup.object().shape({
      street: Yup.string().required(
        formatMessage({
          id: 'validation.required',
          defaultMessage: 'Wymagane'
        })
      ),
      city: Yup.string().required(
        formatMessage({
          id: 'validation.required',
          defaultMessage: 'Wymagane'
        })
      ),
      zipcode: Yup.string().required(
        formatMessage({
          id: 'validation.required',
          defaultMessage: 'Wymagane'
        })
      ),
      country: Yup.object()
        .shape({
          id: Yup.number(),
          name: Yup.string()
        })
        .required(
          formatMessage({
            id: 'validation.required',
            defaultMessage: 'Wymagane'
          })
        )
    })
  })
