import { useState } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useSnackbar } from 'notistack'
import { useTermsOfUse } from 'services/terms/terms.hooks'
import { useAccessToken } from 'services/token/token.hooks'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'

interface UseUsersIceContactResponse {
  firstName: string
  lastName: string
  phone: string
}

const useUsersIceContactInitial = {
  firstName: '',
  lastName: '',
  phone: ''
}

export const useUsersIceContact = () => {
  const [hasIceContact, setIceContact] = useState(true)
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()
  const { hasUserConsented } = useTermsOfUse()

  const { data: usersIceContact = useUsersIceContactInitial } = useQuery({
    queryKey: ['usersIceContact', masquaradeToken],
    queryFn: () =>
      apiInstance<UseUsersIceContactResponse>({
        url: '/users/ice/contact/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      })
        .then(({ data }) => data)
        .catch(() => setIceContact(false)),
    enabled: !!accessToken && hasUserConsented,
    retry: false,
    refetchOnWindowFocus: false
  })

  const usersIceContactUpdate = useMutation({
    mutationFn: (address: Partial<UseUsersIceContactResponse>) =>
      apiInstance<UseUsersIceContactResponse>({
        url: '/users/ice/contact/',
        method: 'PATCH',
        data: address,
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    onSuccess: (data) => {
      queryClient.setQueryData(['usersIceContact', masquaradeToken], data)
      enqueueSnackbar('Wniosek został złożony poprawnie.', {
        variant: 'success'
      })
    },
    onError: () => {
      enqueueSnackbar('Błąd. Spróbuj później.', {
        variant: 'error'
      })
    }
  })

  const usersIceContactCreate = useMutation({
    mutationFn: (address: Partial<UseUsersIceContactResponse>) =>
      apiInstance<UseUsersIceContactResponse>({
        url: '/users/ice/contact/',
        method: 'POST',
        data: address,
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        }
      }).then(({ data }) => data),
    onSuccess: (data) => {
      queryClient.setQueryData(['usersIceContact', masquaradeToken], data)
      enqueueSnackbar('Wniosek został złożony poprawnie.', {
        variant: 'success'
      })
    },
    onError: () => {
      enqueueSnackbar('Błąd. Spróbuj później.', {
        variant: 'error'
      })
    }
  })

  return {
    usersIceContact,
    usersIceContactUpdate,
    usersIceContactCreate,
    hasIceContact
  }
}
