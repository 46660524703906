import { FC } from 'react'
import { Notification } from 'components/Notifications'
import { UserProtectedRoute } from 'routes'

const NotificationPage: FC = () => {
  return (
    <UserProtectedRoute>
      <Notification />
    </UserProtectedRoute>
  )
}

export default NotificationPage
