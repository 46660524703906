import { FC } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Box, useMediaQuery, useTheme } from '@mui/material'

import { UserProfile } from 'components/User'
import Dashboard from 'layouts/Dashboard'

const pathsWithExcludedDesktopView = ['personal-data-confirmation-form']

const UserLayout: FC = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const { pathname } = useLocation()

  if (isDesktop && !pathsWithExcludedDesktopView.find((item) => pathname.includes(item))) {
    return (
      <Dashboard>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
          <Box
            sx={{
              width: '100%',
              minWidth: '320px',
              maxWidth: '480px',
              minHeight: 'calc(100vh - 180px)',
              borderRight: '1px solid #E0E0E0',
              position: 'relative',
              pr: 3
            }}
          >
            <UserProfile />
          </Box>
          <Box
            sx={{
              width: '100%',
              pl: 4,
              maxWidth: '960px',
              margin: '0 auto',
              pt: 14
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Dashboard>
    )
  }

  return <Outlet />
}

export default UserLayout
