import { FC } from 'react'
import { Box, useTheme, Typography, IconButton } from '@mui/material'
import { ArrowForward } from '@mui/icons-material'

import type { BoxProps } from '@mui/material/Box'

const Section: FC<BoxProps> = ({ sx, ...props }) => {
  return (
    <Box
      sx={{
        p: 2,
        border: '1px solid #E0E0E0',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2,
        ...sx
      }}
      {...props}
    />
  )
}

export const SectionCover: FC<BoxProps & { alt: string; src: string }> = ({
  alt,
  src,
  sx,
  ...props
}) => {
  const theme = useTheme()
  return (
    <Box
      component='img'
      alt={alt}
      src={src}
      sx={{
        width: '100%',
        height: '135px',
        objectFit: 'cover',
        borderRadius: '4px',
        [theme.breakpoints.up('md')]: {
          height: '335px'
        },
        ...sx
      }}
      {...props}
    />
  )
}

export const SectionButton: FC<BoxProps> = ({ children, sx, onClick, ...props }) => {
  return (
    <Box
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        color: '#979797',
        width: '100%',
        pl: 2,
        pr: 1,
        mt: 2,
        ...sx
      }}
      {...props}>
      <Typography width='100%' display='flex' variant='body2' fontWeight={500} mr={2}>
        {children}
      </Typography>
      <IconButton size='small' onClick={onClick as any}>
        <ArrowForward color='primary' />
      </IconButton>
    </Box>
  )
}

export default Section
