import { FC } from 'react'
import { HelpList, Help } from 'components/Help'
import RestrictedForDesktop from 'components/RestrictedForDesktop'
import DesktopNavigation from 'components/DesktopNavigation'
import { UserProtectedRoute } from 'routes'
import { useIntl } from 'react-intl'

const HelpPage: FC = () => {
  const { formatMessage } = useIntl()
  return (
    <UserProtectedRoute>
      <DesktopNavigation
        label={formatMessage({
          id: 'widgetHelp.help',
          defaultMessage: 'Strefa Pomocy'
        })}
        isMainRoute
      />
      <RestrictedForDesktop>
        <HelpList />
      </RestrictedForDesktop>
      <Help />
    </UserProtectedRoute>
  )
}

export default HelpPage
