import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

export const CustomBottomNavigation = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    bottom: 0,
    paddingBottom: 'env(safe-area-inset-bottom)',
    width: '100%',
    borderTop: '1px solid #CDCDCD',
    backgroundColor: '#fff',
    height: 'calc(57px + env(safe-area-inset-bottom))'
  },
  [theme.breakpoints.up('md')]: {
    top: 0,
    width: '90px',
    height: '100%',
    borderRight: '1px solid #CDCDCD',
    '.MuiBottomNavigation-root': {
      height: '100%',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      '.MuiButtonBase-root': {
        maxHeight: '80px'
      }
    }
  },
  position: 'fixed',
  left: 0,
  zIndex: 101
}))

export const CustomHeaderNavigation = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
    left: 0
  },
  [theme.breakpoints.up('md')]: {
    display: 'none'
  },
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 'calc(90px + env(safe-area-inset-top))',
  position: 'fixed',
  top: 0,
  paddingTop: 'env(safe-area-inset-top)',
  backgroundColor: theme.palette.background.default,
  zIndex: 100,
  width: '100%'
}))

export const DashboardContent = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    paddingBottom: '56px'
  },
  [theme.breakpoints.up('md')]: {
    paddingLeft: '120px',
    paddingRight: '40px'
  },
  paddingTop: '90px'
}))
