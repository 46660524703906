import { FC } from 'react'
import DesktopNavigation from 'components/DesktopNavigation'
import { UserProtectedRoute } from 'routes'
import AdditionalServicesUtilitiesCalculation from 'components/AdditionalServices/AdditionalServicesUtilitiesCalculation'

const AdditionalServicesMediaPage: FC = () => {
  return (
    <UserProtectedRoute>
      <DesktopNavigation label='Media' desktopNavigateTo='/additional-services' />
      <AdditionalServicesUtilitiesCalculation />
    </UserProtectedRoute>
  )
}

export default AdditionalServicesMediaPage
