import { createTheme } from '@mui/material/styles'

const defaultTheme = createTheme()

const theme = createTheme({
  palette: {
    primary: {
      main: '#527EF7'
    },
    secondary: {
      main: '#1d226e'
    },
    text: {
      primary: '#181818',
      secondary: '#5D5D5D'
    }
  },
  shape: {
    borderRadius: 10
  },
  spacing: 8,
  typography: {
    fontFamily: '"Montserrat", sans-serif'
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 4
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: '#F2F6FF',
          borderRadius: '9px',
          alignItems: 'center',
          padding: '4px',
          [defaultTheme.breakpoints.up('md')]: {
            display: 'inline-flex'
          },
          '.MuiTab-root': {
            padding: '4px 16px',
            flex: 1,
            textTransform: 'none',
            borderRadius: '9px',
            color: '#1D226E',
            maxWidth: 'unset',
            whiteSpace: 'nowrap',
            minHeight: '42px',
            '&.Mui-selected': {
              backgroundColor: '#527EF7',
              color: '#fff'
            }
          }
        },
        indicator: {
          display: 'none'
        }
      }
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontWeight: '700'
        },
        colorPrimary: {
          backgroundColor: '#F2F6FF',
          color: '#527EF7'
        }
      }
    }
  }
})

export default theme
